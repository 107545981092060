import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const RO_LIST_URL = `${API_URL}/ro_list`;
export const RO_SAVE_URL = `${API_URL}/ro_save`;
export const RO_UPDATE_URL = `${API_URL}/ro_update`;
export const RO_DELETE_URL = `${API_URL}/ro_delete`;
export const RO_IMPORT_URL = `${API_URL}/import_ro`;

//rolls
export function getRo() {
  return axios.get(RO_LIST_URL).then((res) => res.data);
}

export function saveRo(data) {
  return axios.post(RO_SAVE_URL, data).then((res) => res.data);
}

export function updateRo(id, data) {
  return axios.put(RO_UPDATE_URL + "/" + id, data).then((res) => res.data);
}

export function deleteRo(id) {
  return axios.put(RO_DELETE_URL, id).then((res) => res.data);
}

export function importRo(data) {
  return axios.post(RO_IMPORT_URL, data).then((res) => res.data);
}
