import react, { useState, useEffect } from "react";
import { KTIcon, toAbsoluteUrl } from "../../../_metronic/helpers";
import {
  deleteAreaOffice,
  getOmc,
  getAreaOffice,
  saveAreaOffice,
  updateAreaOffice,
  getStateOffice,
  getDivisionOffice,
} from "./core/_request";
import { toast } from "react-toastify";
import { PageTitle } from "../../../_metronic/layout/core/PageData";
import { Paginator } from "primereact/paginator";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  getAreas,
  getDivisions,
  getStates,
} from "../BussinesSettings/core/_requests";

const AreaOffice = () => {
  const [areaOffice, setareaOffice] = useState<any[]>([]);
  const [pageData, setPageData] = useState<any[]>([]);
  const [omc, setOmc] = useState<any[]>([]);
  const [states, setStates] = useState<any[]>([]);
  const [divisions, setDivisions] = useState<any[]>([]);
  const [areas, setAreas] = useState<any[]>([]);
  const [areaOfficeForSearch, setareaOfficeForSearch] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [editId, setEditId] = useState<any>({});
  const [search, setSearch] = useState("");
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  // const [omcDetail, setOmcDetail] = useState<any>({});
  // const queryParams = new URLSearchParams(window.location.search);
  // const omc = queryParams.get('omc_id');

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const areaOfficeList = async () => {
    setLoading(true);
    const response = await getAreaOffice();
    setareaOffice(response.output);
    setareaOfficeForSearch(response.output);
    setLoading(false);
  };

  const stateOfficeList = async () => {
    const response = await getStateOffice();
    setStates(response.output);
  };

  const divisionOfficeList = async () => {
    const response = await getDivisionOffice();
    setDivisions(response.output);
  };

  const OmcList = async () => {
    const response = await getOmc();
    setOmc(response.output);
  };

  const initialValues = {
    omc_id: "",
    address1: "",
    address2: "",
    state: "",
    city: "",
    division: "",
    area: "",
    type: "",
  };

  const omcSchema = Yup.object().shape({
    state: Yup.string().required("State is required"),
    division: Yup.string().required("Division is required"),
    area: Yup.string().required("Area is required"),
    type: Yup.string().required("Type is required"),
    omc_id: Yup.string().required("OMC is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: omcSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true);
      try {
        let body = {
          omc_id: values.omc_id,
          address1: values.address1,
          address2: values.address2,
          state: values.state,
          division: values.division,
          city: values.area,
          type: values.type,
        };

        let response;
        if (editId && editId.id != undefined) {
          response = await updateAreaOffice(editId.id, body);
        } else {
          response = await saveAreaOffice(body);
        }

        if (response.status == 200) {
          setLoading(false);
          resetForm();
          areaOfficeList();
          document.getElementById("atom_areaOffice_add_modal_close")?.click();
          toast.success(response.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } catch (error) {
        console.error(error);
        toast.error("Somethig went wrong, Please try after sometimes!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setStatus("Server Error");
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  const handleDelete = async () => {
    const response = await deleteAreaOffice(deleteId);
    if (response.status == 200) {
      setDeleteId("");
      document.getElementById("atom_areaOffice_delete_modal_close")?.click();
      areaOfficeList();
      toast.success(response.message, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(response.message, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  useEffect(() => {
    if (search.length > 0) {
      const areaOffice = areaOfficeForSearch?.filter(
        (p) =>
          p.omc_name?.toLowerCase().includes(search) ||
          p.shortcode?.toLowerCase().includes(search) ||
          p.state_office_name?.toLowerCase().includes(search) ||
          p.division_office_name?.toLowerCase().includes(search) ||
          p.city?.toLowerCase().includes(search) ||
          p.type?.toLowerCase().includes(search)
      );
      setareaOffice(areaOffice);
    } else {
      setareaOffice(areaOfficeForSearch);
    }
  }, [search]);

  useEffect(() => {
    areaOfficeList();
    OmcList();
    divisionOfficeList();
    stateOfficeList();
  }, []);

  useEffect(() => {
    let page = areaOffice?.slice(first, first + rows);
    setPageData(page);
  }, [first, rows, areaOffice]);

  useEffect(() => {
    if (editId && editId.id != undefined) {
      formik.setFieldValue("address1", editId.address1);
      formik.setFieldValue("type", editId.type);
      formik.setFieldValue("state", editId.state);
      formik.setFieldValue("division", editId.division);
      formik.setFieldValue("area", editId.city);
      formik.setFieldValue("omc_id", editId.omc_id);
    }
  }, [editId]);

  return (
    <>
      <PageTitle>Manage OMC</PageTitle>
      <div
        className="modal fade"
        id="atom_areaOffice_add_modal"
        aria-hidden="true"
      >
        <div className="modal-dialog mw-550px">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0 justify-content-between align-items-center">
              <div className="text-center">
                <h3 className="mb-3">
                  {editId && editId.id != undefined ? "Update" : "Add"} Area
                  Office
                </h3>
              </div>
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
                id="atom_areaOffice_add_modal_close"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
            </div>
            <div className="modal-body scroll-y pb-10 pt-5">
              <form noValidate onSubmit={formik.handleSubmit}>
                {/* <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Address Line 1</label>
                    <div className='col-lg-8 fv-row'>
                        <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Enter Address Line 1..'
                        {...formik.getFieldProps('address1')}
                        />
                    </div>
                </div>
                <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Address Line 2</label>
                    <div className='col-lg-8 fv-row'>
                        <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Enter Address Line 2..'
                        {...formik.getFieldProps('address2')}
                        />
                    </div>
                </div> */}
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-bold fs-6">
                    OMC
                  </label>
                  <div className="col-lg-8 fv-row">
                    <select
                      className="form-select form-select-lg form-select-solid"
                      {...formik.getFieldProps("omc_id")}
                    >
                      <option value="">select OMC</option>
                      {omc.map((sta, i) => {
                        return (
                          <option value={sta.id} key={i}>
                            {sta.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {formik.touched.omc_id && formik.errors.omc_id && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert" className="text-danger">
                          {formik.errors.omc_id}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-bold fs-6">
                    State Office
                  </label>
                  <div className="col-lg-8 fv-row">
                    <select
                      className="form-select form-select-lg form-select-solid"
                      {...formik.getFieldProps("state")}
                    >
                      <option value="">select State Office</option>
                      {states.map((sta, i) => {
                        if (formik.values.omc_id == sta.omc_id) {
                          return (
                            <option value={sta.id} key={i}>
                              {sta.state}
                            </option>
                          );
                        }
                      })}
                    </select>
                  </div>
                  {formik.touched.state && formik.errors.state && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert" className="text-danger">
                          {formik.errors.state}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-bold fs-6">
                    Retail Office
                  </label>
                  <div className="col-lg-8 fv-row">
                    <select
                      className="form-select form-select-lg form-select-solid"
                      {...formik.getFieldProps("division")}
                    >
                      <option value="">select Retail Office</option>
                      {divisions.map((div, i) => {
                        if (formik.values.state == div.state) {
                          return (
                            <option value={div.id} key={i}>
                              {div.division}
                            </option>
                          );
                        }
                      })}
                    </select>
                  </div>
                  {formik.touched.division && formik.errors.division && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert" className="text-danger">
                          {formik.errors.division}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                {/* <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>{omcDetail.area_translation || "Level 3"}</label>
                    <div className='col-lg-8 fv-row'>
                        <select className='form-select form-select-lg form-select-solid' {...formik.getFieldProps('area')} >
                            <option value="" >select {omcDetail.area_translation || "Level 3"}</option>
                            {areas.map((div, i) => {
                                if(formik.values.division == div.division_id) {
                                return(
                                    <option value={div.id} key={i} >{div.name}</option>
                                )}
                            })}
                        </select>
                    </div>
                </div> */}
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-bold fs-6">
                    Area Office Type
                  </label>
                  <div className="col-lg-8 fv-row">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Enter Area Office Name.."
                      {...formik.getFieldProps("type")}
                    />
                  </div>
                  {formik.touched.type && formik.errors.type && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert" className="text-danger">
                          {formik.errors.type}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-bold fs-6">
                    Area Office Name
                  </label>
                  <div className="col-lg-8 fv-row">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Enter Area Office Name.."
                      {...formik.getFieldProps("area")}
                    />
                  </div>
                  {formik.touched.area && formik.errors.area && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert" className="text-danger">
                          {formik.errors.area}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <span className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-sm btn-secondary me-3"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      formik.resetForm();
                    }}
                  >
                    Discard
                  </button>
                  <button type="submit" className="btn btn-sm btn-primary">
                    {editId && editId.id != undefined ? "Update" : "Save"}
                  </button>
                </span>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="atom_areaOffice_delete_modal"
        aria-hidden="true"
      >
        <div className="modal-dialog mw-450px">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0 justify-content-between align-items-center">
              <div className="text-center">
                <h3 className="mb-3">Confirmation</h3>
              </div>
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
                id="atom_areaOffice_delete_modal_close"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
            </div>
            <div className="modal-body scroll-y pb-10">
              <p>Are you sure want to delete?</p>
              <span className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-sm btn-secondary me-3"
                  data-bs-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="submit"
                  className="btn btn-sm btn-primary"
                  onClick={() => handleDelete()}
                >
                  Yes
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="atom_areaOffice_overview_modal"
        aria-hidden="true"
      >
        <div className="modal-dialog mw-550px">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0 justify-content-between align-items-center">
              <div className="text-center">
                <h3 className="mb-3">
                  <div className="d-flex align-items-center">
                    <div className="prof_img_pre_list border">
                      {editId.logo ? (
                        <img
                          src={
                            process.env.REACT_APP_API_URL +
                            "/uploads/company/logo/" +
                            editId.id +
                            "/" +
                            editId.logo
                          }
                          className="profile_image w-100"
                        />
                      ) : (
                        <p className="h-100 d-flex justify-content-center align-items-center bg-gray-300">
                          LOGO
                        </p>
                      )}
                    </div>
                    <div className="d-flex flex-column ms-4">
                      <span>{editId.omc_name}</span>
                      <small className="text-gray-400">
                        # {editId.shortcode}
                      </small>
                    </div>
                  </div>
                </h3>
              </div>
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
                id="atom_areaOffice_overview_modal_close"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
            </div>
            <div className="modal-body py-0 scroll-y">
              <div className="">
                <div className="p-9">
                  <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">
                      {editId.state_translation}
                    </label>
                    <div className="col-lg-8">
                      <span className="fw-bolder fs-6 text-dark">
                        {editId.state_name}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">
                      {editId.division_translation}
                    </label>
                    <div className="col-lg-8">
                      <span className="fw-bolder fs-6 text-dark">
                        {editId.division_name}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">
                      {editId.area_translation}
                    </label>
                    <div className="col-lg-8">
                      <span className="fw-bolder fs-6 text-dark">
                        {editId.area_name}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">
                      Address
                    </label>
                    <div className="col-lg-8 fv-row d-flex flex-column">
                      <span className="fw-bold fs-6">{editId.address1},</span>
                      <span className="fw-bold fs-6">{editId.address2},</span>
                      <span className="fw-bold fs-6">{editId.pincode}.</span>
                    </div>
                  </div>
                  <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">
                      RO Code
                    </label>
                    <div className="col-lg-8 d-flex align-items-center">
                      <span className="fw-bolder fs-6 me-2">
                        {editId.ro_code}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">
                      RO Name
                    </label>
                    <div className="col-lg-8 d-flex align-items-center">
                      <span className="fw-bolder fs-6 me-2">
                        {editId.ro_name}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="w-100 h-100">
          <div className="d-flex justify-content-center flex-column align-items-center h-100">
            <div className="spinner-border taskloader" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="card">
          <div className="card-header d-flex align-items-center mobile-padding">
            {/* <div className='d-flex align-items-center'>
                <div className='prof_img_pre_list border'>
                {omcDetail.logo ? 
                    <img src={process.env.REACT_APP_API_URL+'/uploads/company/logo/'+omcDetail.id+'/'+omcDetail.logo} className='profile_image w-100'/> : 
                    <p className='h-100 d-flex justify-content-center align-items-center bg-gray-300'>LOGO</p>}
                </div>
                <div className='d-flex flex-column ms-3'>
                    <h3 className='mb-1'>{omcDetail.name}</h3>
                    <small># {omcDetail.shortcode}</small>
                </div>
            </div> */}
            <h3 className="mb-sm-0 mb-3">Area Office</h3>
            <span className="d-flex">
              <span className="position-relative me-3">
                <input
                  onChange={(e) => setSearch(e.target.value?.toLowerCase())}
                  className="form-control form-control-sm form-control-solid mw-200px"
                  placeholder="Search.."
                />
                <i className="bi bi-search fs-3 me-3 position-absolute top-25 end-0"></i>
              </span>
              <button
                className="btn btn-sm btn-primary me-2 text-nowrap"
                data-bs-toggle="modal"
                data-bs-target="#atom_areaOffice_add_modal"
                onClick={() => {
                  formik.resetForm();
                  setDeleteId("");
                  setSearch("");
                  setEditId("");
                }}
              >
                <i className="bi bi-plus-lg"></i> Add Area Office
              </button>
            </span>
          </div>
          <div className="card-body pt-0">
            <div className="overflow-x-auto">
              <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                <thead>
                  <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                    <th>Sl.No</th>
                    <th>OMC</th>
                    <th>State Office</th>
                    <th>Division Office</th>
                    <th>Area Office</th>
                    <th>Type</th>
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody className="text-gray-600 fw-bold">
                  {areaOffice.length > 0 ? (
                    pageData.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1 + first}</td>
                          <td className="py-1">
                            <div className="d-flex align-items-center">
                              <div className="prof_img_pre_list border">
                                {item.logo ? (
                                  <img
                                    src={
                                      process.env.REACT_APP_API_URL +
                                      "/uploads/company/logo/" +
                                      item.omc_id +
                                      "/" +
                                      item.logo
                                    }
                                    className="profile_image w-100"
                                  />
                                ) : (
                                  <p className="h-100 d-flex justify-content-center align-items-center bg-gray-300">
                                    LOGO
                                  </p>
                                )}
                              </div>
                              <div className="d-flex flex-column ms-3">
                                <span>{item.omc_name}</span>
                                <small className="text-nowrap">
                                  # {item.shortcode}
                                </small>
                              </div>
                            </div>
                          </td>
                          <td>{item.state_office_name}</td>
                          <td>{item.division_office_name}</td>
                          <td>{item.city}</td>
                          <td>{item.type}</td>
                          <td className="text-center text-nowrap">
                            <button
                              className="btn btn-sm btn-secondary btn-active-color-primary btn-icon me-2"
                              data-bs-toggle="modal"
                              data-bs-target="#atom_areaOffice_add_modal"
                              onClick={() => {
                                setEditId(item);
                              }}
                            >
                              <i className="bi bi-pencil-fill"></i>
                            </button>
                            <button
                              className="btn btn-sm btn-secondary btn-active-color-danger btn-icon me-2"
                              data-bs-toggle="modal"
                              data-bs-target="#atom_areaOffice_delete_modal"
                              onClick={() => setDeleteId(item.id)}
                            >
                              <i className="bi bi-trash"></i>
                            </button>
                            {/* <button className='btn btn-sm btn-secondary btn-active-color-warning btn-icon' data-bs-toggle='modal' data-bs-target='#atom_areaOffice_overview_modal' onClick={async() => setEditId(item)}>
                                <i className="bi bi-file-earmark-medical"></i>
                            </button> */}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={7}>
                        <div className="d-flex text-center w-100 align-content-center justify-content-center">
                          No matching records found
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="table_paginator mt-3 d-flex justify-content-end">
              <Paginator
                first={first}
                rows={rows}
                totalRecords={areaOffice.length}
                rowsPerPageOptions={[10, 50, 100, 500, 1000]}
                onPageChange={onPageChange}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AreaOffice;
