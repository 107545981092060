import axios, { AxiosRequestConfig } from "axios";

const API_URL = process.env.REACT_APP_API_URL;
console.log(API_URL);
export const CAMPAIGN_LIST_URL = `${API_URL}/campaign_list`;
export const CAMPAIGN_SAVE_URL = `${API_URL}/campaign_save`;
export const CAMPAIGN_UPDATE_URL = `${API_URL}/campaign_update`;
export const CAMPAIGN_DELETE_URL = `${API_URL}/campaign_delete`;
export const CAMPAIGN_DROPDOWN_URL = `${API_URL}/campaign_omc_dropdown`;
export const CAMPAIGN_REGISTRATION_URL = `${API_URL}/campaign_registration`;
export const CAMPAIGN_REGISTRATION_LIST_URL = `${API_URL}/campaign_registration_list`;
export const CAMPAIGN_BY_ID_URL = `${API_URL}/campaign_edit`;
export const CAMPAIGN_POSTER_URL = `${API_URL}/campaign_ro_list`;
export const RO_LIST_CAMPAIGN_URL = `${API_URL}/ro_list_by_cam_id`;
export const RO_DROPDOWN_URL = `${API_URL}/ro_dropdown`;
export const CAMPAIGN_RESULT_URL = `${API_URL}/campaign_result_list`;
export const CUSTOM_RESULT_URL = `${API_URL}/customized_results`;
export const CAMPAIGN_PRIZE_URL = `${API_URL}/campaign_prizes_list`;
export const CAMPAIGN_RO_GET_BY_ID_URL = `${API_URL}/campaign_edit`;
export const CUSTOM_WINNERS_LIST_URL = `${API_URL}/custom_winners_list`;
export const CUSTOM_WINNERS_DELETE_URL = `${API_URL}/custom_winners_delete`;

//rolls
export function getCampaign() {
  return axios.get(CAMPAIGN_LIST_URL).then((res) => res.data);
}

export function saveCampaign(data: FormData) {
  return axios.post(CAMPAIGN_SAVE_URL, data).then((res) => res.data);
}

export function updateCampaign(id: string, data: FormData) {
  return axios
    .put(CAMPAIGN_UPDATE_URL + "/" + id, data)
    .then((res) => res.data);
}

export function deleteCampaign(id: string) {
  return axios.put(CAMPAIGN_DELETE_URL + "/" + id).then((res) => res.data);
}

export function campaignGetById(id: string) {
  return axios
    .get(CAMPAIGN_RO_GET_BY_ID_URL + "/" + id)
    .then((res) => res.data);
}

export function getCampaignDropdown(id: string) {
  return axios.get(CAMPAIGN_DROPDOWN_URL + "/" + id).then((res) => res.data);
}

export function registerCampaign(data: FormData) {
  return axios.post(CAMPAIGN_REGISTRATION_URL, data).then((res) => res.data);
}

export function getCampaignRegList(
  id: string | null,
  stateId: any,
  roId: string,
  divId: string,
  areaId: string,
  vTId: string,
  fTId: string
) {
  return axios
    .get(
      CAMPAIGN_REGISTRATION_LIST_URL +
        "/" +
        id +
        "?area=" +
        areaId +
        "&state=" +
        stateId +
        "&division=" +
        divId +
        "&fuel_type=" +
        fTId +
        "&vehicle_type=" +
        vTId +
        "&ro=" +
        roId
    )
    .then((res) => res.data);
}

export function getCampaignById(id: string | null) {
  return axios.get(CAMPAIGN_BY_ID_URL + "/" + id).then((res) => res.data);
}

export function getRoByCampaign(
  id: string | null,
  stateId: any,
  roId: string,
  divId: string,
  areaId: string
) {
  return axios
    .get(
      RO_LIST_CAMPAIGN_URL +
        "/" +
        id +
        "?area=" +
        areaId +
        "&state=" +
        stateId +
        "&division=" +
        divId
    )
    .then((res) => res.data);
}

export function getCampaignPoster(cid: string | null, rid: string | null) {
  return axios
    .get(CAMPAIGN_POSTER_URL + "?campaign_id=" + cid + "&ro_id=" + rid)
    .then((res) => res.data);
}

export function getRoDropdown() {
  return axios.get(RO_DROPDOWN_URL).then((res) => res.data);
}

export function getCampaignResults(
  cid: string | null,
  rid: string | null,
  aid: string | null
) {
  return axios
    .get(
      CAMPAIGN_RESULT_URL + "?campaign=" + cid + "&ro=" + rid + "&area=" + aid
    )
    .then((res) => res.data);
}

export function getCampaignResultsRefresh(
  cid: string | null,
  rid: string | null,
  aid: string | null,
  body: any
) {
  return axios
    .get(
      CAMPAIGN_RESULT_URL +
        "?campaign=" +
        cid +
        "&ro=" +
        rid +
        "&area=" +
        aid +
        "&wise=" +
        body
    )
    .then((res) => res.data);
}

export function getCustomResults(
  campaignId,
  areaId,
  roId,
  customPrize,
  fromDate,
  toDate,
  wise
) {
  return axios
    .get(
      CUSTOM_RESULT_URL +
        "/" +
        customPrize +
        `?wise=${wise}&ro=${roId}&area=${areaId}&start_date=${fromDate}&end_date=${toDate}&campaign=${campaignId}`
    )
    .then((res) => res.data);
}

export function getCustomResultsRefresh(
  campaignId,
  areaId,
  roId,
  customPrize,
  fromDate,
  toDate,
  wise,
  body
) {
  return axios
    .get(
      CUSTOM_RESULT_URL +
        "/" +
        customPrize +
        `?wise=${wise}&ro=${roId}&area=${areaId}&start_date=${fromDate}&end_date=${toDate}&campaign=${campaignId}&wise=${body}`
    )
    .then((res) => res.data);
}

export function getCampaignPrizeDetails(cid: string) {
  return axios
    .get(CAMPAIGN_PRIZE_URL + "?campaign=" + cid)
    .then((res) => res.data);
}

export function getCustomWinnerList(
  cid: string,
  cpid: any,
  roid: any,
  areaid: any
) {
  return axios
    .get(
      CUSTOM_WINNERS_LIST_URL +
        `?campaign=${cid}&custom_id=${cpid}&ro_id=${roid}&area=${areaid}`
    )
    .then((res) => res.data);
}

export function deleteCustomWinner(cid: string, cpid: any, roid: any) {
  return axios
    .put(
      CUSTOM_WINNERS_DELETE_URL +
        `?campaign=${cid}&custom_id=${cpid}&id=${roid}`
    )
    .then((res) => res.data);
}
