import react, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { KTIcon, toAbsoluteUrl } from "../../../_metronic/helpers";
import { toast } from "react-toastify";
import { PageTitle } from "../../../_metronic/layout/core/PageData";
import { Paginator } from "primereact/paginator";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {
  campaignGetById,
  getRoDropdown,
  saveCampaign,
  updateCampaign,
} from "./core/_requests";
import { getMaster, getPrize } from "../BussinesSettings/core/_requests";
import {
  getAreaOffice,
  getDivisionOffice,
  getOmc,
  getStateOffice,
} from "../OMC/core/_request";

const CampaignForm = () => {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const campaignId: any = queryParams?.get("cid");
  const [omc, setOmc] = useState<any[]>([]);
  const [divisions, setDivisions] = useState<any[]>([]);
  const [divisionDD, setDivisionDD] = useState<any[]>([]);
  const [stateDD, setStateDD] = useState<any[]>([]);
  const [areaDD, setAreaDD] = useState<any[]>([]);
  const [selectedArea, setSelectedArea] = useState<any[]>([]);
  const [ro, setRo] = useState<any[]>([]);
  const [selectedState, setSelectedState] = useState<any[]>([]);
  const [selectedDivision, setSelectedDivision] = useState<any[]>([]);
  const [selectedRo, setSelectedRo] = useState<any[]>([]);
  const [areas, setAreas] = useState<any[]>([]);
  const [states, setStates] = useState<any[]>([]);
  const [roDropList, setRoDropList] = useState<any[]>([]);
  const [CampaignForSearch, setCampaignForSearch] = useState<any[]>([]);
  const [vehicleType, setVehicleType] = useState<any[]>([]);
  const [fuelType, setFuelType] = useState<any[]>([]);
  const [fuelDropList, setFuelDropList] = useState<any[]>([]);
  const [selectedFuel, setSelectedFuel] = useState<any[]>([]);
  const [selectedVehicle, setSelectedVehicle] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [winnerList, setWinnerList] = useState<any>({});
  const [editId, setEditId] = useState<any>({});
  const [selectedOmc, setSelectedOmc] = useState<any>({});
  const [smsActive, setSmsActive] = useState(false);
  const [selectedPDFFile, setSelectedPDFFile] = useState(null);
  const [customPrize, setCustomPrize] = useState<any[]>([]);
  const [wiseWinner, setWiseWinner] = useState<any[]>(["campaign_wise"]);
  // const [prices, setPrices] = useState<any[]>([]);
  const [campaignPrices, setCampaignPrices] = useState<any[]>([
    {
      campaign_id: "",
      campaign_name: "",
      prize: [],
    },
  ]);
  const [areaPrices, setAreaPrices] = useState<any[]>([]);
  const [roPrices, setRoPrices] = useState<any[]>([]);
  const [prizesList, setprizeList] = useState<any[]>([]);
  const [imagePreview, setImagePreview] = useState<any>({
    banner: null,
    image1: null,
    image2: null,
    image3: null,
  });
  const [winnerType, setWinnerType] = useState<any>({
    campaign_winner: "static",
    area_winner: "static",
    ro_winner: "static",
  });
  const [fieldVal, setFieldVal] = useState<any>({
    sloganField: false,
    emailField: false,
    vnumberField: false,
    ftypeField: false,
    vtypeField: false,
    invoiceField: false,
    mobileField: false,
    nameField: false,
    bill_amount: false,
    bill_image: false,
    selfi_image: false,
    customer_attendant_name: false,
    customer_attendant_code: false,
    field_officer_name: false,
    field_officer_code: false,
    sloganFieldRequired: false,
    emailFieldRequired: false,
    vnumberFieldRequired: false,
    ftypeFieldRequired: false,
    vtypeFieldRequired: false,
    invoiceFieldRequired: false,
    mobileFieldRequired: false,
    nameFieldRequired: false,
    bill_amountRequired: false,
    bill_imageRequired: false,
    selfi_imageRequired: false,
    customer_attendant_nameRequired: false,
    customer_attendant_codeRequired: false,
    field_officer_nameRequired: false,
    field_officer_codeRequired: false,
  });
  const [imageFile, setImageFile] = useState<any>({
    banner: null,
    image1: null,
    image2: null,
    image3: null,
  });

  const prizeList = async () => {
    const response = await getPrize();
    setprizeList(response.output);
  };

  const OmcList = async () => {
    const response = await getOmc();
    setOmc(response.output);
  };

  const statesList = async () => {
    const response = await getStateOffice();
    setStates(response.output);
  };

  const divisionList = async () => {
    const response = await getDivisionOffice();
    setDivisions(response.output);
  };

  const areaList = async () => {
    const response = await getAreaOffice();
    setAreas(response.output);
  };

  const RoList = async () => {
    const response = await getRoDropdown();
    setRo(response.output);
  };

  const vehicleTypeList = async () => {
    const response = await getMaster("vehicle_type");
    setVehicleType(response.output);
  };

  const fuelTypeList = async () => {
    const response = await getMaster("fuel_type");
    setFuelType(response.output);
  };

  console.log("erhiuergetgeiourt", customPrize);

  const initialValues = {
    name: "",
    omc_id: "",
    ro_id: "",
    state: "",
    division: "",
    area: "",
    start_date: "",
    end_date: "",
    slogon: "",
    vehicle_type: "",
    fuel_type: "",
    terms_conditions: "",
    value: "",
    preview: "",
    banner: "",
    image1: "",
    image2: "",
    image3: "",
    price_amoung: "",
    winner_type: "",

    sloganField: false,
    emailField: false,
    vnumberField: false,
    ftypeField: false,
    vtypeField: false,
    invoiceField: false,
    mobileField: false,
    nameField: false,
    bill_amount: false,
    bill_image: false,
    selfi_image: false,
    customer_attendant_name: false,
    customer_attendant_code: false,
    field_officer_name: false,
    field_officer_code: false,
    sloganFieldRequired: false,
    emailFieldRequired: false,
    vnumberFieldRequired: false,
    ftypeFieldRequired: false,
    vtypeFieldRequired: false,
    invoiceFieldRequired: false,
    mobileFieldRequired: false,
    nameFieldRequired: false,
    bill_amountRequired: false,
    bill_imageRequired: false,
    selfi_imageRequired: false,
    customer_attendant_nameRequired: false,
    customer_attendant_codeRequired: false,
    field_officer_nameRequired: false,
    field_officer_codeRequired: false,
  };

  const campaignSchema = Yup.object().shape({
    name: Yup.string().required("Campaign Name is required"),
    omc_id: Yup.string().required("OMC is required"),
    start_date: Yup.string().required("Start date is required"),
    end_date: Yup.string().required("End date is required"),
    ro_id: Yup.string().required("RO is required"),
    state: Yup.string().required("State is required"),
    division: Yup.string().required("Division is required"),
    area: Yup.string().required("Area is required"),
    fuel_type: Yup.string().required("Fuel type is required"),
    vehicle_type: Yup.string().required("Vehicle type is required"),
  });

  const isValidFileUploaded = (file: any) => {
    const validExtensions = ["png", "jpeg", "jpg"];
    const fileExtension = file.type.split("/")[1];
    return validExtensions.includes(fileExtension);
  };

  const handleImagePreview = (e: any) => {
    if (e.target.files[0].size > 10485760) {
      toast.error("Image size should be below 10MB !", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      (document.getElementById(e.target.name) as HTMLInputElement).value = "";
      return;
    } else {
      const file = e.target.files[0];
      if (isValidFileUploaded(file)) {
        let image_as_base64: any = URL.createObjectURL(e.target.files[0]);
        let image_as_files: any = e.target.files[0];
        setImageFile({ ...imageFile, [e.target.name]: image_as_files });
        setImagePreview({ ...imagePreview, [e.target.name]: image_as_base64 });
      } else {
        (document.getElementById(e.target.name) as HTMLInputElement).value = "";
        toast.error("Image should be .jpg, .jpeg and .png only!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  const imgRemove = () => {
    setTimeout(() => {
      setImageFile({
        banner: null,
        image1: null,
        image2: null,
        image3: null,
      });
      setImagePreview({
        banner: null,
        image1: null,
        image2: null,
        image3: null,
      });
    }, 500);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: campaignSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true);
      try {
        const formData = new FormData();

        let prices = {
          campaign: campaignPrices,
          area: areaPrices,
          ro: roPrices,
        };

        let formBuilder = {
          sloganField: fieldVal.sloganField,
          emailField: fieldVal.emailField,
          vnumberField: fieldVal.vnumberField,
          ftypeField: fieldVal.ftypeField,
          vtypeField: fieldVal.vtypeField,
          invoiceField: fieldVal.invoiceField,
          mobileField: fieldVal.mobileField,
          nameField: fieldVal.nameField,
          bill_amount: fieldVal.bill_amount,
          bill_image: fieldVal.bill_image,
          selfi_image: fieldVal.selfi_image,
          customer_attendant_name: fieldVal.customer_attendant_name,
          customer_attendant_code: fieldVal.customer_attendant_code,
          field_officer_name: fieldVal.field_officer_name,
          field_officer_code: fieldVal.field_officer_code,
          sloganFieldRequired: fieldVal.sloganFieldRequired,
          emailFieldRequired: fieldVal.emailFieldRequired,
          vnumberFieldRequired: fieldVal.vnumberFieldRequired,
          ftypeFieldRequired: fieldVal.ftypeFieldRequired,
          vtypeFieldRequired: fieldVal.vtypeFieldRequired,
          invoiceFieldRequired: fieldVal.invoiceFieldRequired,
          mobileFieldRequired: fieldVal.mobileFieldRequired,
          nameFieldRequired: fieldVal.nameFieldRequired,
          bill_amountRequired: fieldVal.bill_amountRequired,
          bill_imageRequired: fieldVal.bill_imageRequired,
          selfi_imageRequired: fieldVal.selfi_imageRequired,
          customer_attendant_nameRequired:
            fieldVal.customer_attendant_nameRequired,
          customer_attendant_codeRequired:
            fieldVal.customer_attendant_codeRequired,
          field_officer_nameRequired: fieldVal.field_officer_nameRequired,
          field_officer_codeRequired: fieldVal.field_officer_codeRequired,
        };

        formData.append("sms_active", smsActive ? "1" : "0");
        formData.append("name", values.name);
        formData.append("omc_id", values.omc_id);
        formData.append("ro_id", values.ro_id);
        formData.append("state", values.state);
        formData.append("division", values.division);
        formData.append("area", values.area);
        formData.append("start_date", values.start_date);
        formData.append("end_date", values.end_date);
        formData.append("slogon", values.slogon);
        selectedPDFFile &&
          formData.append("terms_conditions", selectedPDFFile!);
        formData.append("value", values.value);
        formData.append("preview", values.preview);
        formData.append("vehicle_type", values.vehicle_type);
        formData.append("fuel_type", values.fuel_type);
        formData.append("winner_type", JSON.stringify(winnerType));
        formData.append("prize_wise", wiseWinner?.join(","));
        formData.append("prizes", JSON.stringify(prices));
        formData.append("forms", JSON.stringify(formBuilder));
        customPrize.length > 0
          ? formData.append(
              "custom_prize",
              JSON.stringify(
                customPrize.filter(
                  (item) => item.id == undefined && item.status != 0
                )
              )
            )
          : formData.append("custom_prize", JSON.stringify([]));
        imageFile?.banner && formData.append("banner", imageFile?.banner);
        imageFile?.image1 && formData.append("image1", imageFile?.image1);
        imageFile?.image2 && formData.append("image2", imageFile?.image2);
        imageFile?.image3 && formData.append("image3", imageFile?.image3);

        let response;
        if (editId && editId.id != undefined) {
          response = await updateCampaign(editId.id, formData);
        } else {
          response = await saveCampaign(formData);
        }

        if (response.status == 200) {
          setLoading(false);
          resetForm();
          setSelectedState([]);
          setSelectedDivision([]);
          setSelectedArea([]);
          setSelectedRo([]);
          setImageFile({
            banner: null,
            image1: null,
            image2: null,
            image3: null,
          });
          setImagePreview({
            banner: null,
            image1: null,
            image2: null,
            image3: null,
          });
          setSmsActive(false);
          setFieldVal({
            sloganField: false,
            emailField: false,
            vnumberField: false,
            ftypeField: false,
            vtypeField: false,
            invoiceField: false,
            mobileField: false,
            nameField: false,
            bill_amount: false,
            selfi_image: false,
            customer_attendant_name: false,
            customer_attendant_code: false,
            field_officer_name: false,
            field_officer_code: false,
            sloganFieldRequired: false,
            emailFieldRequired: false,
            vnumberFieldRequired: false,
            ftypeFieldRequired: false,
            vtypeFieldRequired: false,
            invoiceFieldRequired: false,
            mobileFieldRequired: false,
            nameFieldRequired: false,
            bill_amountRequired: false,
            bill_imageRequired: false,
            selfi_imageRequired: false,
            customer_attendant_nameRequired: false,
            customer_attendant_codeRequired: false,
            field_officer_nameRequired: false,
            field_officer_codeRequired: false,
          });
          // CampaignList();
          document.getElementById("atom_Campaign_add_modal_close")?.click();
          navigate("/campaign");
          toast.success(response.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } catch (error) {
        console.error(error);
        toast.error("Somethig went wrong, Please try after sometimes!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setStatus("Server Error");
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  const editCampaignValues = async () => {
    const response = await campaignGetById(campaignId);
    setEditId(response.output[0]);
    let campaignData = response.output[0];

    // console.log("skrtjerheirtjhe", customPrizeList);
    document.getElementById("ewrtyweriugw7r543274t2736g32ty3v4h")?.click();
    let fuel = fuelType?.filter((it) =>
      it.omc_id?.toString().includes(campaignData?.omc_id?.toString())
    );
    setFuelDropList(fuel);
    setSmsActive(campaignData?.sms_active == 1 ? true : false);
    setWinnerType(JSON.parse(campaignData?.winner_type));

    formik.setFieldValue(
      "start_date",
      moment(campaignData?.start_date).format("YYYY-MM-DD")
    );
    formik.setFieldValue(
      "end_date",
      moment(campaignData?.end_date).format("YYYY-MM-DD")
    );
    formik.setFieldValue("slogon", campaignData?.slogon);
    formik.setFieldValue("terms_conditions", campaignData?.terms_conditions);
    formik.setFieldValue("value", campaignData?.value);
    formik.setFieldValue("name", campaignData?.name);
    formik.setFieldValue("omc_id", campaignData?.omc_id);
    formik.setFieldValue("ro_id", campaignData?.ro_id);
    formik.setFieldValue("state", campaignData?.state);
    formik.setFieldValue("division", campaignData?.division);
    formik.setFieldValue("area", campaignData?.area);
    formik.setFieldValue("fuel_type", campaignData?.fuel_type);
    formik.setFieldValue("vehicle_type", campaignData?.vehicle_type);

    if (campaignData?.forms) {
      const forms = JSON.parse(campaignData?.forms);
      setFieldVal({
        sloganField: forms.sloganField,
        emailField: forms.emailField,
        vnumberField: forms.vnumberField,
        ftypeField: forms.ftypeField,
        vtypeField: forms.vtypeField,
        invoiceField: forms.invoiceField,
        mobileField: forms.mobileField,
        nameField: forms.nameField,
        bill_amount: forms.bill_amount,
        bill_image: forms.bill_image,
        selfi_image: forms.selfi_image,
        customer_attendant_name: forms.customer_attendant_name,
        customer_attendant_code: forms.customer_attendant_code,
        field_officer_name: forms.field_officer_name,
        field_officer_code: forms.field_officer_code,
        sloganFieldRequired: forms.sloganFieldRequired,
        emailFieldRequired: forms.emailFieldRequired,
        vnumberFieldRequired: forms.vnumberFieldRequired,
        ftypeFieldRequired: forms.ftypeFieldRequired,
        vtypeFieldRequired: forms.vtypeFieldRequired,
        invoiceFieldRequired: forms.invoiceFieldRequired,
        mobileFieldRequired: forms.mobileFieldRequired,
        nameFieldRequired: forms.nameFieldRequired,
        bill_amountRequired: forms.bill_amountRequired,
        bill_imageRequired: forms.bill_imageRequired,
        selfi_imageRequired: forms.selfi_imageRequired,
        customer_attendant_nameRequired: forms.customer_attendant_nameRequired,
        customer_attendant_codeRequired: forms.customer_attendant_codeRequired,
        field_officer_nameRequired: forms.field_officer_nameRequired,
        field_officer_codeRequired: forms.field_officer_codeRequired,
      });
    }
    setImagePreview({
      banner: campaignData?.banner
        ? process.env.REACT_APP_API_URL +
          "/uploads/campaign/banner/" +
          campaignData?.id +
          "/" +
          campaignData?.banner
        : null,
      image1: campaignData?.image1
        ? process.env.REACT_APP_API_URL +
          "/uploads/campaign/image1/" +
          campaignData?.id +
          "/" +
          campaignData?.image1
        : null,
      image2: campaignData?.image2
        ? process.env.REACT_APP_API_URL +
          "/uploads/campaign/image2/" +
          campaignData?.id +
          "/" +
          campaignData?.image2
        : null,
      image3: campaignData?.image3
        ? process.env.REACT_APP_API_URL +
          "/uploads/campaign/image3/" +
          campaignData?.id +
          "/" +
          campaignData?.image3
        : null,
    });

    setStateDD(
      states.filter(
        (item) => campaignData?.omc_id?.toString() === item.omc_id?.toString()
      )
    );
    setDivisionDD(
      divisions?.filter(
        (item) => campaignData?.omc_id?.toString() === item.omc_id?.toString()
      )
    );
    setAreaDD(
      areas?.filter(
        (item) => campaignData?.omc_id?.toString() === item.omc_id?.toString()
      )
    );
    setRoDropList(
      ro?.filter(
        (item) => campaignData?.omc_id?.toString() === item.omc_id?.toString()
      )
    );

    setTimeout(() => {
      setSelectedState(
        states?.filter(
          (val: any) =>
            campaignData?.state?.split(",")?.indexOf(val.id?.toString()) !== -1
        )
      );
      setSelectedDivision(
        divisions?.filter(
          (val: any) =>
            campaignData?.division?.split(",")?.indexOf(val.id?.toString()) !==
            -1
        )
      );
      setSelectedArea(
        areas?.filter(
          (val: any) =>
            campaignData?.area?.split(",")?.indexOf(val.id?.toString()) !== -1
        )
      );
      setSelectedRo(
        ro?.filter(
          (val: any) =>
            campaignData?.ro_id?.split(",")?.indexOf(val.id?.toString()) !== -1
        )
      );
      setSelectedFuel(
        fuelType?.filter(
          (val: any) =>
            campaignData?.fuel_type?.split(",")?.indexOf(val.id?.toString()) !==
            -1
        )
      );
      setSelectedVehicle(
        vehicleType?.filter(
          (val: any) =>
            campaignData?.vehicle_type
              ?.split(",")
              ?.indexOf(val.id?.toString()) !== -1
        )
      );
      // setCustomPrize(customPrizeList);
    }, 500);
    setPageLoading(true);

    // setTimeout(() => {
    //     setWiseWinner(campaignData?.prize_wise?.split(','));
    //     let kfjgwergh = JSON.parse(campaignData?.prizes)
    //     if(campaignData?.prize_wise?.split(',').find((item) => item == "campaign_wise")) {
    //         let prize:any = {
    //             'campaign_id': campaignData?.id || '',
    //             'campaign_name': campaignData?.name || formik.values.name,
    //             'prize': ''
    //         }
    //         setCampaignPrices(kfjgwergh?.campaign);
    //     }
    //     if(campaignData?.prize_wise?.split(',').find((item) => item == "area_wise")) {
    //         let areaPrizes: any = [];
    //         (areas?.filter((val:any) => campaignData?.area?.split(',')?.indexOf(val.id?.toString()) !== -1))?.map((area:any) => areaPrizes.push({
    //             'area_id': area.id,
    //             'area_name': area.city,
    //             'prize': ''
    //         }));
    //         setAreaPrices(kfjgwergh?.area);
    //     }
    //     if(campaignData?.prize_wise?.split(',').find((item) => item == "ro_wise")) {
    //         let roPrizes: any = [];
    //         (ro?.filter((val:any) => campaignData?.ro_id?.split(',')?.indexOf(val.id?.toString()) !== -1))?.map((area:any) => roPrizes.push({
    //             'ro_id': area.id,
    //             'ro_name': area.ro_name,
    //             'prize': ''
    //         }));
    //         setRoPrices(kfjgwergh?.ro);
    //     }
    // }, 3000);

    // setTimeout(() => {
    //     let customPrizeList:any = [];
    //     for(let i in customPrizeData) {
    //         let list = {
    //             ...customPrizeData[i],
    //             prize_wise: customPrizeData[i].prize_wise?.split(','),
    //             prizes: JSON.parse(customPrizeData[i].prizes),
    //             winner_type: JSON.parse(customPrizeData[i].winner_type),
    //             start_date: moment(customPrizeData[i].start_date).format('YYYY-MM-DD'),
    //             end_date: moment(customPrizeData[i].end_date).format('YYYY-MM-DD'),
    //         }
    //         customPrizeList.push(list);
    //     }
    //     setCustomPrize(customPrizeList);
    // }, 10000);
  };

  const loadCustomPrize = () => {
    let customPrizeData = JSON.parse(editId.json_data);
    let customPrizeList: any = [];
    for (let i in customPrizeData) {
      let list = {
        ...customPrizeData[i],
        prize_wise: customPrizeData[i].prize_wise?.split(","),
        prizes: JSON.parse(customPrizeData[i].prizes),
        winner_type: JSON.parse(customPrizeData[i].winner_type),
        start_date: moment(customPrizeData[i].start_date).format("YYYY-MM-DD"),
        end_date: moment(customPrizeData[i].end_date).format("YYYY-MM-DD"),
      };
      customPrizeList.push(list);
    }
    setCustomPrize(customPrizeList);
  };

  const loadPrize = () => {
    if (editId && editId.id != undefined) {
      setWiseWinner(editId?.prize_wise?.split(","));
      let kfjgwergh = JSON.parse(editId?.prizes);
      if (
        editId?.prize_wise?.split(",").find((item) => item == "campaign_wise")
      ) {
        let prize: any = {
          campaign_id: editId?.id || "",
          campaign_name: editId?.name || formik.values.name,
          prize: "",
        };
        setCampaignPrices(kfjgwergh?.campaign);
      }
      if (editId?.prize_wise?.split(",").find((item) => item == "area_wise")) {
        let areaPrizes: any = [];
        areas
          ?.filter(
            (val: any) =>
              editId?.area?.split(",")?.indexOf(val.id?.toString()) !== -1
          )
          ?.map((area: any) =>
            areaPrizes.push({
              area_id: area.id,
              area_name: area.city,
              prize: "",
            })
          );
        setAreaPrices(kfjgwergh?.area);
      }
      if (editId?.prize_wise?.split(",").find((item) => item == "ro_wise")) {
        let roPrizes: any = [];
        ro?.filter(
          (val: any) =>
            editId?.ro_id?.split(",")?.indexOf(val.id?.toString()) !== -1
        )?.map((area: any) =>
          roPrizes.push({
            ro_id: area.id,
            ro_name: area.ro_name,
            prize: "",
          })
        );
        setRoPrices(kfjgwergh?.ro);
      }
    }
  };

  useEffect(() => {
    if (
      campaignId &&
      fuelType.length > 0 &&
      ro.length > 0 &&
      areas.length > 0 &&
      divisions.length > 0 &&
      states.length > 0
    ) {
      editCampaignValues();
    }
  }, [campaignId, fuelType, ro, areas, divisions, states]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "application/pdf") {
      setSelectedPDFFile(file);
    } else {
      alert("Please select a PDF file.");
    }
  };

  useEffect(() => {
    OmcList();
    statesList();
    divisionList();
    areaList();
    RoList();
    vehicleTypeList();
    fuelTypeList();
    prizeList();
  }, []);

  return (
    <>
      <PageTitle>{campaignId ? "Update Campaign" : "Add Campaign"}</PageTitle>
      {campaignId && !pageLoading ? (
        <div className="w-100 h-100">
          <div className="d-flex justify-content-center flex-column align-items-center h-100">
            <div className="spinner-border taskloader" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="card">
          <form noValidate onSubmit={formik.handleSubmit}>
            <div className="card-body pt-0 pt-md-0 p-3 p-md-8">
              <div className="tab_container bg_white br_10 bs_1">
                <div className="row mt-4">
                  <div className="col-12">
                    <ul
                      className="nav nav-pills border-bottom d-flex flex-nowrap overflow-auto"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item text-nowrap" role="presentation">
                        <button
                          className="nav-link active pb-2"
                          id="overview-tab"
                          data-bs-toggle="pill"
                          data-bs-target={"#overview"}
                          type="button"
                          role="tab"
                          aria-controls="overview"
                          aria-selected="true"
                        >
                          Campaign Details
                        </button>
                      </li>
                      <li className="nav-item text-nowrap" role="presentation">
                        <button
                          className="nav-link pb-2"
                          id="additional-tab"
                          data-bs-toggle="pill"
                          data-bs-target={"#additional"}
                          type="button"
                          role="tab"
                          aria-controls="additional"
                          aria-selected="true"
                        >
                          Registration Form Fields
                        </button>
                      </li>
                      <li className="nav-item text-nowrap" role="presentation">
                        <button
                          className="nav-link pb-2"
                          id="contactAddress-tab"
                          data-bs-toggle="pill"
                          data-bs-target={"#contactAddress"}
                          type="button"
                          role="tab"
                          aria-controls="contactAddress"
                          aria-selected="true"
                          onClick={() => loadPrize()}
                        >
                          Prize Details
                        </button>
                      </li>
                      <li className="nav-item text-nowrap" role="presentation">
                        <button
                          className="nav-link pb-2"
                          id="notes-tab"
                          data-bs-toggle="pill"
                          data-bs-target={"#notes"}
                          type="button"
                          role="tab"
                          aria-controls={"notes"}
                          aria-selected="false"
                          onClick={() => loadCustomPrize()}
                        >
                          Custom Prize Details
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content pt-5" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id={"overview"}
                        role="tabpanel"
                        aria-labelledby="overview-tab"
                      >
                        <div className="row mb-4">
                          <div className="col-md-6 col-12 mb-3">
                            <label
                              htmlFor="basic-url"
                              className="form-label required"
                            >
                              Campaign Name
                            </label>
                            <div className="input-group first mb-3 input_prepend">
                              <input
                                type="text"
                                {...formik.getFieldProps("name")}
                                className="form-control"
                                placeholder="Enter your Name.."
                              />
                            </div>
                            {formik.touched.name && formik.errors.name && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  <span role="alert" className="text-danger">
                                    {formik.errors.name}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="col-md-6 col-12 mb-3">
                            <label
                              htmlFor="basic-url"
                              className="form-label required"
                            >
                              OMC
                            </label>
                            <div className="input-group first mb-3 input_prepend">
                              <select
                                {...formik.getFieldProps("omc_id")}
                                className="form-control"
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "omc_id",
                                    e.target.value
                                  );
                                  setSelectedOmc(
                                    omc.find((com) => e.target.value == com.id)
                                  );
                                  let fuel = fuelType.filter((it) =>
                                    it.omc_id
                                      ?.toString()
                                      .includes(e.target.value?.toString())
                                  );
                                  setFuelDropList(fuel);
                                  setStateDD(
                                    states.filter(
                                      (com) => e.target.value == com.omc_id
                                    )
                                  );
                                  setRoDropList(
                                    ro.filter(
                                      (com) =>
                                        e.target.value == com.omc_id &&
                                        stateDD?.some(
                                          (item2) => item2.id === com.state
                                        ) &&
                                        divisionDD?.some(
                                          (item2) => item2.id === com.division
                                        ) &&
                                        areaDD?.some(
                                          (item2) => item2.id === com.area
                                        )
                                    )
                                  );
                                  setSelectedState([]);
                                  setSelectedRo([]);
                                  setSelectedDivision([]);
                                  setSelectedArea([]);
                                  setSelectedVehicle([]);
                                  setSelectedFuel([]);
                                }}
                              >
                                <option value="">select OMC</option>
                                {omc.map((omc, i) => {
                                  return (
                                    <option value={omc.id} key={i}>
                                      {omc.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            {formik.touched.omc_id && formik.errors.omc_id && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  <span role="alert" className="text-danger">
                                    {formik.errors.omc_id}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="col-md-6 col-12 mb-3">
                            <div className="d-flex justify-content-between">
                              <label
                                htmlFor="basic-url"
                                className="form-label required"
                              >
                                State
                              </label>
                              <div className="form-check form-check-custom form-check-solid form-check-sm">
                                <label
                                  className="form-check-label me-2"
                                  htmlFor="stateRadioLg"
                                >
                                  <small>select all</small>
                                </label>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="stateRadioLg"
                                  checked={
                                    stateDD?.length != 0 &&
                                    stateDD?.length == selectedState?.length
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setSelectedState(stateDD);
                                      formik.setFieldValue(
                                        "state",
                                        stateDD
                                          .map((data) => data.id)
                                          ?.join(",")
                                      );
                                      setDivisionDD(
                                        divisions?.filter((item) =>
                                          stateDD?.some(
                                            (item2) => item2.id === item.state
                                          )
                                        )
                                      );
                                      setAreaDD(
                                        areas?.filter(
                                          (item) =>
                                            stateDD?.some(
                                              (item2) => item2.id === item.state
                                            ) &&
                                            divisionDD?.some(
                                              (item2) =>
                                                item2.id === item.division
                                            )
                                        )
                                      );
                                      setRoDropList(
                                        ro.filter(
                                          (item) =>
                                            stateDD?.some(
                                              (item2) => item2.id === item.state
                                            ) &&
                                            divisionDD?.some(
                                              (item2) =>
                                                item2.id === item.division
                                            ) &&
                                            areaDD?.some(
                                              (item2) => item2.id === item.area
                                            )
                                        )
                                      );
                                      setSelectedDivision(
                                        divisions
                                          ?.filter((item) =>
                                            stateDD?.some(
                                              (item2) => item2.id === item.state
                                            )
                                          )
                                          ?.filter(
                                            (item: any) =>
                                              selectedDivision?.indexOf(
                                                item
                                              ) !== -1
                                          )
                                      );
                                      setSelectedArea(
                                        areas
                                          ?.filter((item) =>
                                            stateDD?.some(
                                              (item2) => item2.id === item.state
                                            )
                                          )
                                          ?.filter(
                                            (item: any) =>
                                              selectedArea?.indexOf(item) !== -1
                                          )
                                      );
                                    } else {
                                      setSelectedState([]);
                                      formik.setFieldValue("state", "");
                                      setDivisionDD([]);
                                      setAreaDD([]);
                                      setRoDropList([]);
                                      setSelectedDivision([]);
                                      setSelectedArea([]);
                                      setSelectedRo([]);
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div className="input-group first mb-3 input_prepend">
                              <Select
                                isMulti
                                options={stateDD}
                                closeMenuOnSelect={false}
                                components={makeAnimated()}
                                getOptionLabel={(option: any) => option.state}
                                getOptionValue={(option: any) => option.id}
                                value={stateDD?.filter(
                                  (item: any) =>
                                    selectedState?.indexOf(item) !== -1
                                )}
                                classNamePrefix=""
                                className={"w-100 "}
                                onChange={(val: any) => {
                                  setSelectedState(val);
                                  formik.setFieldValue(
                                    "state",
                                    val.map((data) => data.id)?.join(",")
                                  );
                                  setDivisionDD(
                                    divisions?.filter((item) =>
                                      val?.some(
                                        (item2) => item2.id === item.state
                                      )
                                    )
                                  );
                                  setAreaDD(
                                    areas?.filter(
                                      (item) =>
                                        val?.some(
                                          (item2) => item2.id === item.state
                                        ) &&
                                        divisionDD?.some(
                                          (item2) => item2.id === item.division
                                        )
                                    )
                                  );
                                  setRoDropList(
                                    ro.filter(
                                      (item) =>
                                        val?.some(
                                          (item2) => item2.id === item.state
                                        ) &&
                                        divisionDD?.some(
                                          (item2) => item2.id === item.division
                                        ) &&
                                        areaDD?.some(
                                          (item2) => item2.id === item.area
                                        )
                                    )
                                  );
                                  setSelectedDivision(
                                    divisions
                                      ?.filter((item) =>
                                        val?.some(
                                          (item2) => item2.id === item.state
                                        )
                                      )
                                      ?.filter(
                                        (item: any) =>
                                          selectedDivision?.indexOf(item) !== -1
                                      )
                                  );
                                  setSelectedArea(
                                    areas
                                      ?.filter((item) =>
                                        val?.some(
                                          (item2) => item2.id === item.state
                                        )
                                      )
                                      ?.filter(
                                        (item: any) =>
                                          selectedArea?.indexOf(item) !== -1
                                      )
                                  );
                                }}
                                placeholder={"select.."}
                              />
                            </div>
                            {formik.touched.state && formik.errors.state && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  <span role="alert" className="text-danger">
                                    {formik.errors.state}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="col-md-6 col-12 mb-3">
                            <div className="d-flex justify-content-between">
                              <label
                                htmlFor="basic-url"
                                className="form-label required"
                              >
                                {divisionDD[0]?.type || "Retail"}
                              </label>
                              <div className="form-check form-check-custom form-check-solid form-check-sm">
                                <label
                                  className="form-check-label me-2"
                                  htmlFor="divRadioLg"
                                >
                                  <small>select all</small>
                                </label>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="divRadioLg"
                                  checked={
                                    divisionDD?.length != 0 &&
                                    divisionDD?.length ==
                                      selectedDivision?.length
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setSelectedDivision(divisionDD);
                                      formik.setFieldValue(
                                        "division",
                                        divisionDD
                                          .map((data) => data.id)
                                          ?.join(",")
                                      );
                                      setAreaDD(
                                        areas?.filter((item) =>
                                          divisionDD?.some(
                                            (item2) =>
                                              item2.id === item.division
                                          )
                                        )
                                      );
                                      setRoDropList(
                                        ro?.filter(
                                          (item) =>
                                            divisionDD?.some(
                                              (item2) =>
                                                item2.id === item.division
                                            ) &&
                                            areaDD?.some(
                                              (item2) => item2.id === item.area
                                            )
                                        )
                                      );
                                      setSelectedArea(
                                        areas
                                          ?.filter((item) =>
                                            divisionDD?.some(
                                              (item2) =>
                                                item2.id === item.division
                                            )
                                          )
                                          ?.filter(
                                            (item: any) =>
                                              selectedArea?.indexOf(item) !== -1
                                          )
                                      );
                                    } else {
                                      setSelectedDivision([]);
                                      formik.setFieldValue("division", "");
                                      setAreaDD([]);
                                      setSelectedArea([]);
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div className="input-group first mb-3 input_prepend">
                              <Select
                                isMulti
                                options={divisionDD}
                                closeMenuOnSelect={false}
                                components={makeAnimated()}
                                getOptionLabel={(option: any) =>
                                  option.division
                                }
                                getOptionValue={(option: any) => option.id}
                                value={divisionDD?.filter(
                                  (item: any) =>
                                    selectedDivision?.indexOf(item) !== -1
                                )}
                                classNamePrefix=""
                                className={"w-100 "}
                                onChange={(val: any) => {
                                  setSelectedDivision(val);
                                  formik.setFieldValue(
                                    "division",
                                    val.map((data) => data.id)?.join(",")
                                  );
                                  setAreaDD(
                                    areas?.filter((item) =>
                                      val?.some(
                                        (item2) => item2.id === item.division
                                      )
                                    )
                                  );
                                  setRoDropList(
                                    ro?.filter(
                                      (item) =>
                                        val?.some(
                                          (item2) => item2.id === item.division
                                        ) &&
                                        areaDD?.some(
                                          (item2) => item2.id === item.area
                                        )
                                    )
                                  );
                                  setSelectedArea(
                                    areas
                                      ?.filter((item) =>
                                        val?.some(
                                          (item2) => item2.id === item.division
                                        )
                                      )
                                      ?.filter(
                                        (item: any) =>
                                          selectedArea?.indexOf(item) !== -1
                                      )
                                  );
                                }}
                                placeholder={"select.."}
                              />
                            </div>
                            {formik.touched.division && formik.errors.division && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  <span role="alert" className="text-danger">
                                    {formik.errors.division}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="col-12 mb-3">
                            <div className="d-flex justify-content-between">
                              <label
                                htmlFor="basic-url"
                                className="form-label required"
                              >
                                {areaDD[0]?.type || "Area"}
                              </label>
                              <div className="form-check form-check-custom form-check-solid form-check-sm">
                                <label
                                  className="form-check-label me-2"
                                  htmlFor="areaRadioLg"
                                >
                                  <small>select all</small>
                                </label>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="areaRadioLg"
                                  checked={
                                    areaDD?.length != 0 &&
                                    areaDD?.length == selectedArea?.length
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setSelectedArea(areaDD);
                                      formik.setFieldValue(
                                        "area",
                                        areaDD.map((data) => data.id)?.join(",")
                                      );
                                      setRoDropList(
                                        ro?.filter((item) =>
                                          areaDD?.some(
                                            (item2) =>
                                              item2.id?.toString() ===
                                              item.area?.toString()
                                          )
                                        )
                                      );
                                    } else {
                                      setSelectedArea([]);
                                      setRoDropList([]);
                                      formik.setFieldValue("area", "");
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div className="input-group first mb-3 input_prepend">
                              <Select
                                isMulti
                                options={areaDD}
                                closeMenuOnSelect={false}
                                components={makeAnimated()}
                                getOptionLabel={(option: any) => option.city}
                                getOptionValue={(option: any) => option.id}
                                value={areaDD?.filter(
                                  (item: any) =>
                                    selectedArea?.indexOf(item) !== -1
                                )}
                                classNamePrefix=""
                                className={"w-100 "}
                                onChange={(val: any) => {
                                  setSelectedArea(val);
                                  formik.setFieldValue(
                                    "area",
                                    val.map((data) => data.id)?.join(",")
                                  );
                                  setRoDropList(
                                    ro?.filter((item) =>
                                      val?.some(
                                        (item2) => item2.id === item.area
                                      )
                                    )
                                  );
                                }}
                                placeholder={"select.."}
                              />
                            </div>
                            {formik.touched.area && formik.errors.area && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  <span role="alert" className="text-danger">
                                    {formik.errors.area}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="col-12 mb-3">
                            <div className="d-flex justify-content-between">
                              <label
                                htmlFor="basic-url"
                                className="form-label required"
                              >
                                {roDropList[0]?.ro_type || "RO"}
                              </label>
                              <div className="form-check form-check-custom form-check-solid form-check-sm">
                                <label
                                  className="form-check-label me-2"
                                  htmlFor="roRadioLg"
                                >
                                  <small>select all</small>
                                </label>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="roRadioLg"
                                  checked={
                                    roDropList?.length != 0 &&
                                    roDropList?.length == selectedRo?.length
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setSelectedRo(roDropList);
                                      formik.setFieldValue(
                                        "ro_id",
                                        roDropList
                                          .map((data) => data.id)
                                          ?.join(",")
                                      );
                                    } else {
                                      setSelectedRo([]);
                                      formik.setFieldValue("ro_id", "");
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div className="input-group first mb-3 input_prepend">
                              <Select
                                isMulti
                                options={roDropList}
                                closeMenuOnSelect={false}
                                components={makeAnimated()}
                                getOptionLabel={(option: any) => option.ro_name}
                                getOptionValue={(option: any) => option.id}
                                value={roDropList?.filter(
                                  (item: any) =>
                                    selectedRo?.indexOf(item) !== -1
                                )}
                                classNamePrefix=""
                                className={"w-100 "}
                                onChange={(val: any) => {
                                  setSelectedRo(val);
                                  formik.setFieldValue(
                                    "ro_id",
                                    val.map((data) => data.id)?.join(",")
                                  );
                                }}
                                placeholder={"select.."}
                              />
                            </div>
                            {formik.touched.ro_id && formik.errors.ro_id && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  <span role="alert" className="text-danger">
                                    {formik.errors.ro_id}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="col-md-6 col-12 mb-3">
                            <div className="d-flex justify-content-between">
                              <label
                                htmlFor="basic-url"
                                className="form-label required"
                              >
                                Fuel Type
                              </label>
                              <div className="form-check form-check-custom form-check-solid form-check-sm">
                                <label
                                  className="form-check-label me-2"
                                  htmlFor="fuelRadioLg"
                                >
                                  <small>select all</small>
                                </label>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="fuelRadioLg"
                                  checked={
                                    fuelDropList?.length != 0 &&
                                    fuelDropList?.length == selectedFuel?.length
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setSelectedFuel(fuelDropList);
                                      formik.setFieldValue(
                                        "fuel_type",
                                        fuelDropList
                                          .map((data) => data.id)
                                          ?.join(",")
                                      );
                                    } else {
                                      setSelectedFuel([]);
                                      formik.setFieldValue("fuel_type", "");
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div className="input-group first mb-3 input_prepend">
                              <Select
                                isMulti
                                options={fuelDropList}
                                closeMenuOnSelect={false}
                                components={makeAnimated()}
                                getOptionLabel={(option: any) =>
                                  option.option_value
                                }
                                getOptionValue={(option: any) => option.id}
                                value={fuelDropList?.filter(
                                  (item: any) =>
                                    selectedFuel?.indexOf(item) !== -1
                                )}
                                classNamePrefix=""
                                className={"w-100 "}
                                onChange={(val: any) => {
                                  setSelectedFuel(val);
                                  formik.setFieldValue(
                                    "fuel_type",
                                    val.map((data) => data.id)?.join(",")
                                  );
                                }}
                                placeholder={"select.."}
                              />
                            </div>
                            {formik.touched.fuel_type &&
                              formik.errors.fuel_type && (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    <span role="alert" className="text-danger">
                                      {formik.errors.fuel_type}
                                    </span>
                                  </div>
                                </div>
                              )}
                          </div>
                          <div className="col-md-6 col-12 mb-3">
                            <div className="d-flex justify-content-between">
                              <label
                                htmlFor="basic-url"
                                className="form-label required"
                              >
                                Vehicle Type
                              </label>
                              <div className="form-check form-check-custom form-check-solid form-check-sm">
                                <label
                                  className="form-check-label me-2"
                                  htmlFor="vehicleRadioLg"
                                >
                                  <small>select all</small>
                                </label>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="vehicleRadioLg"
                                  checked={
                                    vehicleType?.length != 0 &&
                                    vehicleType?.length ==
                                      selectedVehicle?.length
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setSelectedVehicle(vehicleType);
                                      formik.setFieldValue(
                                        "vehicle_type",
                                        vehicleType
                                          .map((data) => data.id)
                                          ?.join(",")
                                      );
                                    } else {
                                      setSelectedVehicle([]);
                                      formik.setFieldValue("vehicle_type", "");
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div className="input-group first mb-3 input_prepend">
                              <Select
                                isMulti
                                options={vehicleType}
                                closeMenuOnSelect={false}
                                components={makeAnimated()}
                                getOptionLabel={(option: any) =>
                                  option.option_value
                                }
                                getOptionValue={(option: any) => option.id}
                                value={vehicleType?.filter(
                                  (item: any) =>
                                    selectedVehicle?.indexOf(item) !== -1
                                )}
                                classNamePrefix=""
                                className={"w-100 "}
                                onChange={(val: any) => {
                                  setSelectedVehicle(val);
                                  formik.setFieldValue(
                                    "vehicle_type",
                                    val.map((data) => data.id)?.join(",")
                                  );
                                }}
                                placeholder={"select.."}
                              />
                            </div>
                            {formik.touched.vehicle_type &&
                              formik.errors.vehicle_type && (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    <span role="alert" className="text-danger">
                                      {formik.errors.vehicle_type}
                                    </span>
                                  </div>
                                </div>
                              )}
                          </div>
                          <div className="col-md-6 col-12 mb-3">
                            <label
                              htmlFor="basic-url"
                              className="form-label required"
                            >
                              Start Date
                            </label>
                            <div className="input-group mb-3">
                              <input
                                type="date"
                                {...formik.getFieldProps("start_date")}
                                className="form-control"
                              />
                            </div>
                            {formik.touched.start_date &&
                              formik.errors.start_date && (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    <span role="alert" className="text-danger">
                                      {formik.errors.start_date}
                                    </span>
                                  </div>
                                </div>
                              )}
                          </div>
                          <div className="col-md-6 col-12 mb-3">
                            <label
                              htmlFor="basic-url"
                              className="form-label required"
                            >
                              End Date
                            </label>
                            <div className="input-group mb-3">
                              <input
                                type="date"
                                min={formik.values.start_date}
                                {...formik.getFieldProps("end_date")}
                                className="form-control"
                              />
                            </div>
                            {formik.touched.end_date && formik.errors.end_date && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  <span role="alert" className="text-danger">
                                    {formik.errors.end_date}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="col-12 mb-3">
                            <div className="row">
                              <div className="col-md-8">
                                <label
                                  htmlFor="basic-url"
                                  className="form-label"
                                >
                                  Banner
                                </label>
                                <div className="input-group mb-3">
                                  <input
                                    type="file"
                                    className="form-control"
                                    name="banner"
                                    id="banner"
                                    onChange={(e) => handleImagePreview(e)}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4 d-flex justify-content-center">
                                <div className="campaign_form_pre">
                                  <img
                                    src={imagePreview.banner}
                                    className="w-100"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 mb-3">
                            <div className="row">
                              <div className="col-md-8">
                                <label
                                  htmlFor="basic-url"
                                  className="form-label"
                                >
                                  Image 1
                                </label>
                                <div className="input-group mb-3">
                                  <input
                                    type="file"
                                    className="form-control"
                                    name="image1"
                                    id="image1"
                                    onChange={(e) => handleImagePreview(e)}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4 d-flex justify-content-center">
                                <div className="campaign_form_pre">
                                  <img
                                    src={imagePreview.image1}
                                    className="w-100"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 mb-3">
                            <div className="row">
                              <div className="col-md-8">
                                <label
                                  htmlFor="basic-url"
                                  className="form-label"
                                >
                                  Image 2
                                </label>
                                <div className="input-group mb-3">
                                  <input
                                    type="file"
                                    className="form-control"
                                    name="image2"
                                    id="image2"
                                    onChange={(e) => handleImagePreview(e)}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4 d-flex justify-content-center">
                                <div className="campaign_form_pre">
                                  <img
                                    src={imagePreview.image2}
                                    className="w-100"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 mb-3">
                            <div className="row">
                              <div className="col-md-8">
                                <label
                                  htmlFor="basic-url"
                                  className="form-label"
                                >
                                  Image 3
                                </label>
                                <div className="input-group mb-3">
                                  <input
                                    type="file"
                                    className="form-control"
                                    name="image3"
                                    id="image3"
                                    onChange={(e) => handleImagePreview(e)}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4 d-flex justify-content-center">
                                <div className="campaign_form_pre">
                                  <img
                                    src={imagePreview.image3}
                                    className="w-100"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">
                              Terms & Conditions
                            </label>
                            <div className="input-group">
                              <input
                                type="file"
                                className="form-control"
                                accept=".pdf"
                                onChange={handleFileChange}
                              />
                            </div>
                            <small className="text-danger mb-3">
                              upload only pdf!
                            </small>
                          </div>
                        </div>
                        <span className="d-flex justify-content-end">
                          <button
                            type="button"
                            className="btn btn-sm btn-primary pe-2"
                            onClick={() =>
                              document.getElementById("additional-tab")?.click()
                            }
                          >
                            Next <i className="bi bi-chevron-right"></i>
                          </button>
                        </span>
                      </div>
                      <div
                        className="tab-pane fade"
                        id={"additional"}
                        role="tabpanel"
                        aria-labelledby="additional-tab"
                      >
                        <div className="row">
                          <div className="col-md-6 px-9 my-4">
                            <div className="d-flex justify-content-between">
                              <label className="" htmlFor="nameSwitchDefault">
                                Name
                              </label>
                              <div className="d-flex">
                                {fieldVal.nameField && (
                                  <div className="form-check form-check-custom form-check-solid form-check-sm me-3">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="nameRadioLg"
                                      checked={fieldVal.nameFieldRequired}
                                      onChange={(e) =>
                                        setFieldVal({
                                          ...fieldVal,
                                          nameFieldRequired: e.target.checked,
                                        })
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="nameRadioLg"
                                    >
                                      <small>is Required?</small>
                                    </label>
                                  </div>
                                )}
                                <div className="form-check form-switch form-check-custom form-check-solid text-end">
                                  <input
                                    className="form-check-input col-6"
                                    type="checkbox"
                                    id="nameSwitchDefault"
                                    checked={fieldVal.nameField}
                                    onChange={(e) =>
                                      setFieldVal({
                                        ...fieldVal,
                                        nameField: e.target.checked,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 px-9 my-4">
                            <div className="d-flex justify-content-between">
                              <label className="" htmlFor="mobileSwitchDefault">
                                Mobile
                              </label>
                              <div className="d-flex">
                                {fieldVal.mobileField && (
                                  <div className="form-check form-check-custom form-check-solid form-check-sm me-3">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="mobileRadioLg"
                                      checked={fieldVal.mobileFieldRequired}
                                      onChange={(e) =>
                                        setFieldVal({
                                          ...fieldVal,
                                          mobileFieldRequired: e.target.checked,
                                        })
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="mobileRadioLg"
                                    >
                                      <small>is Required?</small>
                                    </label>
                                  </div>
                                )}
                                <div className="form-check form-switch form-check-custom form-check-solid text-end">
                                  <input
                                    className="form-check-input col-6"
                                    type="checkbox"
                                    id="mobileSwitchDefault"
                                    checked={fieldVal.mobileField}
                                    onChange={(e) =>
                                      setFieldVal({
                                        ...fieldVal,
                                        mobileField: e.target.checked,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 px-9 my-4">
                            <div className="d-flex justify-content-between">
                              <label
                                className=""
                                htmlFor="invoiceSwitchDefault"
                              >
                                Bill Number
                              </label>
                              <div className="d-flex">
                                {fieldVal.invoiceField && (
                                  <div className="form-check form-check-custom form-check-solid form-check-sm me-3">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="invoiceRadioLg"
                                      checked={fieldVal.invoiceFieldRequired}
                                      onChange={(e) =>
                                        setFieldVal({
                                          ...fieldVal,
                                          invoiceFieldRequired:
                                            e.target.checked,
                                        })
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="invoiceRadioLg"
                                    >
                                      <small>is Required?</small>
                                    </label>
                                  </div>
                                )}
                                <div className="form-check form-switch form-check-custom form-check-solid text-end">
                                  <input
                                    className="form-check-input col-6"
                                    type="checkbox"
                                    id="invoiceSwitchDefault"
                                    checked={fieldVal.invoiceField}
                                    onChange={(e) =>
                                      setFieldVal({
                                        ...fieldVal,
                                        invoiceField: e.target.checked,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 px-9 my-4">
                            <div className="d-flex justify-content-between">
                              <label className="" htmlFor="vtypeSwitchDefault">
                                Vehicle Type
                              </label>
                              <div className="d-flex">
                                {fieldVal.vtypeField && (
                                  <div className="form-check form-check-custom form-check-solid form-check-sm me-3">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="vtypeRadioLg"
                                      checked={fieldVal.vtypeFieldRequired}
                                      onChange={(e) =>
                                        setFieldVal({
                                          ...fieldVal,
                                          vtypeFieldRequired: e.target.checked,
                                        })
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="vtypeRadioLg"
                                    >
                                      <small>is Required?</small>
                                    </label>
                                  </div>
                                )}
                                <div className="form-check form-switch form-check-custom form-check-solid text-end">
                                  <input
                                    className="form-check-input col-6"
                                    type="checkbox"
                                    id="vtypeSwitchDefault"
                                    checked={fieldVal.vtypeField}
                                    onChange={(e) =>
                                      setFieldVal({
                                        ...fieldVal,
                                        vtypeField: e.target.checked,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 px-9 my-4">
                            <div className="d-flex justify-content-between">
                              <label className="" htmlFor="ftypeSwitchDefault">
                                Fuel Type
                              </label>
                              <div className="d-flex">
                                {fieldVal.ftypeField && (
                                  <div className="form-check form-check-custom form-check-solid form-check-sm me-3">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="ftypeRadioLg"
                                      checked={fieldVal.ftypeFieldRequired}
                                      onChange={(e) =>
                                        setFieldVal({
                                          ...fieldVal,
                                          ftypeFieldRequired: e.target.checked,
                                        })
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="ftypeRadioLg"
                                    >
                                      <small>is Required?</small>
                                    </label>
                                  </div>
                                )}
                                <div className="form-check form-switch form-check-custom form-check-solid text-end">
                                  <input
                                    className="form-check-input col-6"
                                    type="checkbox"
                                    id="ftypeSwitchDefault"
                                    checked={fieldVal.ftypeField}
                                    onChange={(e) =>
                                      setFieldVal({
                                        ...fieldVal,
                                        ftypeField: e.target.checked,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 px-9 my-4">
                            <div className="d-flex justify-content-between">
                              <label
                                className=""
                                htmlFor="vnumberSwitchDefault"
                              >
                                Vehicle Number
                              </label>
                              <div className="d-flex">
                                {fieldVal.vnumberField && (
                                  <div className="form-check form-check-custom form-check-solid form-check-sm me-3">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="vnumberRadioLg"
                                      checked={fieldVal.vnumberFieldRequired}
                                      onChange={(e) =>
                                        setFieldVal({
                                          ...fieldVal,
                                          vnumberFieldRequired:
                                            e.target.checked,
                                        })
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="vnumberRadioLg"
                                    >
                                      <small>is Required?</small>
                                    </label>
                                  </div>
                                )}
                                <div className="form-check form-switch form-check-custom form-check-solid text-end">
                                  <input
                                    className="form-check-input col-6"
                                    type="checkbox"
                                    id="vnumberSwitchDefault"
                                    checked={fieldVal.vnumberField}
                                    onChange={(e) =>
                                      setFieldVal({
                                        ...fieldVal,
                                        vnumberField: e.target.checked,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 px-9 my-4">
                            <div className="d-flex justify-content-between">
                              <label className="" htmlFor="emailSwitchDefault">
                                Email
                              </label>
                              <div className="d-flex">
                                {fieldVal.emailField && (
                                  <div className="form-check form-check-custom form-check-solid form-check-sm me-3">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="emailRadioLg"
                                      checked={fieldVal.emailFieldRequired}
                                      onChange={(e) =>
                                        setFieldVal({
                                          ...fieldVal,
                                          emailFieldRequired: e.target.checked,
                                        })
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="emailRadioLg"
                                    >
                                      <small>is Required?</small>
                                    </label>
                                  </div>
                                )}
                                <div className="form-check form-switch form-check-custom form-check-solid text-end">
                                  <input
                                    className="form-check-input col-6"
                                    type="checkbox"
                                    id="emailSwitchDefault"
                                    checked={fieldVal.emailField}
                                    onChange={(e) =>
                                      setFieldVal({
                                        ...fieldVal,
                                        emailField: e.target.checked,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 px-9 my-4">
                            <div className="d-flex justify-content-between">
                              <label className="" htmlFor="sloganSwitchDefault">
                                Slogan
                              </label>
                              <div className="d-flex">
                                {fieldVal.sloganField && (
                                  <div className="form-check form-check-custom form-check-solid form-check-sm me-3">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="sloganRadioLg"
                                      checked={fieldVal.sloganFieldRequired}
                                      onChange={(e) =>
                                        setFieldVal({
                                          ...fieldVal,
                                          sloganFieldRequired: e.target.checked,
                                        })
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="sloganRadioLg"
                                    >
                                      <small>is Required?</small>
                                    </label>
                                  </div>
                                )}
                                <div className="form-check form-switch form-check-custom form-check-solid text-end">
                                  <input
                                    className="form-check-input col-6"
                                    type="checkbox"
                                    id="sloganSwitchDefault"
                                    checked={fieldVal.sloganField}
                                    onChange={(e) =>
                                      setFieldVal({
                                        ...fieldVal,
                                        sloganField: e.target.checked,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 px-9 my-4">
                            <div className="d-flex justify-content-between">
                              <label
                                className=""
                                htmlFor="billAmountSwitchDefault"
                              >
                                Bill Amount
                              </label>
                              <div className="d-flex">
                                {fieldVal.bill_amount && (
                                  <div className="form-check form-check-custom form-check-solid form-check-sm me-3">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="billAmountRadioLg"
                                      checked={fieldVal.bill_amountRequired}
                                      onChange={(e) =>
                                        setFieldVal({
                                          ...fieldVal,
                                          bill_amountRequired: e.target.checked,
                                        })
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="billAmountRadioLg"
                                    >
                                      <small>is Required?</small>
                                    </label>
                                  </div>
                                )}
                                <div className="form-check form-switch form-check-custom form-check-solid text-end">
                                  <input
                                    className="form-check-input col-6"
                                    type="checkbox"
                                    id="billAmountSwitchDefault"
                                    checked={fieldVal.bill_amount}
                                    onChange={(e) =>
                                      setFieldVal({
                                        ...fieldVal,
                                        bill_amount: e.target.checked,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 px-9 my-4">
                            <div className="d-flex justify-content-between">
                              <label
                                className=""
                                htmlFor="billImageSwitchDefault"
                              >
                                Bill Image
                              </label>
                              <div className="d-flex">
                                {fieldVal.bill_image && (
                                  <div className="form-check form-check-custom form-check-solid form-check-sm me-3">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="billImageRadioLg"
                                      checked={fieldVal.bill_imageRequired}
                                      onChange={(e) =>
                                        setFieldVal({
                                          ...fieldVal,
                                          bill_imageRequired: e.target.checked,
                                        })
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="billImageRadioLg"
                                    >
                                      <small>is Required?</small>
                                    </label>
                                  </div>
                                )}
                                <div className="form-check form-switch form-check-custom form-check-solid text-end">
                                  <input
                                    className="form-check-input col-6"
                                    type="checkbox"
                                    id="billImageSwitchDefault"
                                    checked={fieldVal.bill_image}
                                    onChange={(e) =>
                                      setFieldVal({
                                        ...fieldVal,
                                        bill_image: e.target.checked,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 px-9 my-4">
                            <div className="d-flex justify-content-between">
                              <label
                                className=""
                                htmlFor="selfiImageSwitchDefault"
                              >
                                Selfi Image
                              </label>
                              <div className="d-flex">
                                {fieldVal.selfi_image && (
                                  <div className="form-check form-check-custom form-check-solid form-check-sm me-3">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="selfiImageRadioLg"
                                      checked={fieldVal.selfi_imageRequired}
                                      onChange={(e) =>
                                        setFieldVal({
                                          ...fieldVal,
                                          selfi_imageRequired: e.target.checked,
                                        })
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="selfiImageRadioLg"
                                    >
                                      <small>is Required?</small>
                                    </label>
                                  </div>
                                )}
                                <div className="form-check form-switch form-check-custom form-check-solid text-end">
                                  <input
                                    className="form-check-input col-6"
                                    type="checkbox"
                                    id="selfiImageSwitchDefault"
                                    checked={fieldVal.selfi_image}
                                    onChange={(e) =>
                                      setFieldVal({
                                        ...fieldVal,
                                        selfi_image: e.target.checked,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 px-9 my-4">
                            <div className="d-flex justify-content-between">
                              <label
                                className=""
                                htmlFor="attendantSwitchDefault"
                              >
                                Customer Attendant Name
                              </label>
                              <div className="d-flex">
                                {fieldVal.customer_attendant_name && (
                                  <div className="form-check form-check-custom form-check-solid form-check-sm me-3">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="attendantRadioLg"
                                      checked={
                                        fieldVal.customer_attendant_nameRequired
                                      }
                                      onChange={(e) =>
                                        setFieldVal({
                                          ...fieldVal,
                                          customer_attendant_nameRequired:
                                            e.target.checked,
                                        })
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="attendantRadioLg"
                                    >
                                      <small>is Required?</small>
                                    </label>
                                  </div>
                                )}
                                <div className="form-check form-switch form-check-custom form-check-solid text-end">
                                  <input
                                    className="form-check-input col-6"
                                    type="checkbox"
                                    id="attendantSwitchDefault"
                                    checked={fieldVal.customer_attendant_name}
                                    onChange={(e) =>
                                      setFieldVal({
                                        ...fieldVal,
                                        customer_attendant_name:
                                          e.target.checked,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 px-9 my-4">
                            <div className="d-flex justify-content-between">
                              <label
                                className=""
                                htmlFor="attendantCodeSwitchDefault"
                              >
                                Customer Attendant Code
                              </label>
                              <div className="d-flex">
                                {fieldVal.customer_attendant_code && (
                                  <div className="form-check form-check-custom form-check-solid form-check-sm me-3">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="attendantCodeRadioLg"
                                      checked={
                                        fieldVal.customer_attendant_codeRequired
                                      }
                                      onChange={(e) =>
                                        setFieldVal({
                                          ...fieldVal,
                                          customer_attendant_codeRequired:
                                            e.target.checked,
                                        })
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="attendantCodeRadioLg"
                                    >
                                      <small>is Required?</small>
                                    </label>
                                  </div>
                                )}
                                <div className="form-check form-switch form-check-custom form-check-solid text-end">
                                  <input
                                    className="form-check-input col-6"
                                    type="checkbox"
                                    id="attendantCodeSwitchDefault"
                                    checked={fieldVal.customer_attendant_code}
                                    onChange={(e) =>
                                      setFieldVal({
                                        ...fieldVal,
                                        customer_attendant_code:
                                          e.target.checked,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 px-9 my-4">
                            <div className="d-flex justify-content-between">
                              <label
                                className=""
                                htmlFor="officerSwitchDefault"
                              >
                                Field Officer Name
                              </label>
                              <div className="d-flex">
                                {fieldVal.field_officer_name && (
                                  <div className="form-check form-check-custom form-check-solid form-check-sm me-3">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="officerRadioLg"
                                      checked={
                                        fieldVal.field_officer_nameRequired
                                      }
                                      onChange={(e) =>
                                        setFieldVal({
                                          ...fieldVal,
                                          field_officer_nameRequired:
                                            e.target.checked,
                                        })
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="officerRadioLg"
                                    >
                                      <small>is Required?</small>
                                    </label>
                                  </div>
                                )}
                                <div className="form-check form-switch form-check-custom form-check-solid text-end">
                                  <input
                                    className="form-check-input col-6"
                                    type="checkbox"
                                    id="officerSwitchDefault"
                                    checked={fieldVal.field_officer_name}
                                    onChange={(e) =>
                                      setFieldVal({
                                        ...fieldVal,
                                        field_officer_name: e.target.checked,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 px-9 my-4">
                            <div className="d-flex justify-content-between">
                              <label
                                className=""
                                htmlFor="officerCodeSwitchDefault"
                              >
                                Field Officer Code
                              </label>
                              <div className="d-flex">
                                {fieldVal.field_officer_code && (
                                  <div className="form-check form-check-custom form-check-solid form-check-sm me-3">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="officerCodeRadioLg"
                                      checked={
                                        fieldVal.field_officer_codeRequired
                                      }
                                      onChange={(e) =>
                                        setFieldVal({
                                          ...fieldVal,
                                          field_officer_codeRequired:
                                            e.target.checked,
                                        })
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="officerCodeRadioLg"
                                    >
                                      <small>is Required?</small>
                                    </label>
                                  </div>
                                )}
                                <div className="form-check form-switch form-check-custom form-check-solid text-end">
                                  <input
                                    className="form-check-input col-6"
                                    type="checkbox"
                                    id="officerCodeSwitchDefault"
                                    checked={fieldVal.field_officer_code}
                                    onChange={(e) =>
                                      setFieldVal({
                                        ...fieldVal,
                                        field_officer_code: e.target.checked,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 px-9 my-4">
                            <div className="d-flex justify-content-between">
                              <label className="" htmlFor="smsSwitchDefault">
                                SMS
                              </label>
                              <div className="form-check form-switch form-check-custom form-check-solid text-end">
                                <input
                                  className="form-check-input col-6"
                                  type="checkbox"
                                  id="smsSwitchDefault"
                                  checked={smsActive}
                                  onChange={(e) =>
                                    setSmsActive(e.target.checked)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <span className="d-flex justify-content-end">
                          <button
                            type="button"
                            className="btn btn-sm btn-primary me-3 ps-3"
                            onClick={() =>
                              document.getElementById("overview-tab")?.click()
                            }
                          >
                            <i className="bi bi-chevron-left"></i> Prev
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-primary pe-2"
                            onClick={() =>
                              document
                                .getElementById("contactAddress-tab")
                                ?.click()
                            }
                          >
                            Next <i className="bi bi-chevron-right"></i>
                          </button>
                        </span>
                      </div>
                      <div
                        className="tab-pane fade"
                        id={"contactAddress"}
                        role="tabpanel"
                        aria-labelledby="contactAddress-tab"
                      >
                        <div className="row">
                          <div className="col-md-4 col-12 my-3">
                            <div className="form-check form-check-custom form-check-solid text-end">
                              <label
                                htmlFor="basic-url"
                                className="form-label me-3"
                              >
                                Campaign wise winner
                              </label>
                              <input
                                className="form-check-input col-6"
                                type="checkbox"
                                checked={
                                  wiseWinner?.find(
                                    (item) => item == "campaign_wise"
                                  )
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setWiseWinner((posr) => [
                                      ...posr,
                                      "campaign_wise",
                                    ]);
                                    formik.setFieldValue(
                                      "price_amoung",
                                      [...wiseWinner, "campaign_wise"]?.join(
                                        ","
                                      )
                                    );
                                    let prize: any = {
                                      campaign_id: editId.id || "",
                                      campaign_name:
                                        editId.name || formik.values.name,
                                      prize: [],
                                    };
                                    setCampaignPrices([prize]);
                                  } else {
                                    let eghfwgr = wiseWinner?.filter(
                                      (data) => data !== "campaign_wise"
                                    );
                                    setWiseWinner(eghfwgr);
                                    formik.setFieldValue(
                                      "price_amoung",
                                      eghfwgr?.join(",")
                                    );
                                    setCampaignPrices([]);
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-12 my-3">
                            <div className="form-check form-check-custom form-check-solid text-end">
                              <label
                                htmlFor="basic-url"
                                className="form-label me-3"
                              >
                                Area wise winner
                              </label>
                              <input
                                className="form-check-input col-6"
                                type="checkbox"
                                checked={
                                  wiseWinner?.find(
                                    (item) => item == "area_wise"
                                  )
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setWiseWinner((posr) => [
                                      ...posr,
                                      "area_wise",
                                    ]);
                                    formik.setFieldValue(
                                      "price_amoung",
                                      [...wiseWinner, "area_wise"]?.join(",")
                                    );
                                    let areaPrizes: any = [];
                                    selectedArea.map((area: any) =>
                                      areaPrizes.push({
                                        area_id: area.id,
                                        area_name: area.city,
                                        prize: [],
                                      })
                                    );
                                    setAreaPrices(areaPrizes);
                                  } else {
                                    let eghfwgr = wiseWinner?.filter(
                                      (data) => data !== "area_wise"
                                    );
                                    setWiseWinner(eghfwgr);
                                    formik.setFieldValue(
                                      "price_amoung",
                                      eghfwgr?.join(",")
                                    );
                                    setAreaPrices([]);
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-12 my-3">
                            <div className="form-check form-check-custom form-check-solid text-end">
                              <label
                                htmlFor="basic-url"
                                className="form-label me-3"
                              >
                                RO wise winner
                              </label>
                              <input
                                className="form-check-input col-6"
                                type="checkbox"
                                checked={
                                  wiseWinner?.find((item) => item == "ro_wise")
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setWiseWinner((posr) => [
                                      ...posr,
                                      "ro_wise",
                                    ]);
                                    formik.setFieldValue(
                                      "price_amoung",
                                      [...wiseWinner, "ro_wise"]?.join(",")
                                    );
                                    let roPrizes: any = [];
                                    selectedRo.map((area: any) =>
                                      roPrizes.push({
                                        ro_id: area.id,
                                        ro_name: area.ro_name,
                                        prize: [],
                                      })
                                    );
                                    setRoPrices(roPrizes);
                                  } else {
                                    let eghfwgr = wiseWinner?.filter(
                                      (data) => data !== "ro_wise"
                                    );
                                    setWiseWinner(eghfwgr);
                                    formik.setFieldValue(
                                      "price_amoung",
                                      eghfwgr?.join(",")
                                    );
                                    setRoPrices([]);
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-12 my-3">
                            <label htmlFor="basic-url" className="form-label">
                              Campaign Winner Type
                            </label>
                            <div className="input-group first mb-3 input_prepend">
                              <select
                                className="form-control"
                                value={winnerType?.campaign_winner}
                                onChange={(e) => {
                                  setWinnerType({
                                    ...winnerType,
                                    campaign_winner: e.target.value,
                                  });
                                }}
                              >
                                <option value="">select</option>
                                <option value="static">Static</option>
                                <option value="dynamic">Dynamic</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4 col-12 my-3">
                            <label htmlFor="basic-url" className="form-label">
                              Area Winner Type
                            </label>
                            <div className="input-group first mb-3 input_prepend">
                              <select
                                className="form-control"
                                value={winnerType?.area_winner}
                                onChange={(e) => {
                                  setWinnerType({
                                    ...winnerType,
                                    area_winner: e.target.value,
                                  });
                                }}
                              >
                                <option value="">select</option>
                                <option value="static">Static</option>
                                <option value="dynamic">Dynamic</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4 col-12 my-3">
                            <label htmlFor="basic-url" className="form-label">
                              RO Winner Type
                            </label>
                            <div className="input-group first mb-3 input_prepend">
                              <select
                                className="form-control"
                                value={winnerType?.ro_winner}
                                onChange={(e) => {
                                  setWinnerType({
                                    ...winnerType,
                                    ro_winner: e.target.value,
                                  });
                                }}
                              >
                                <option value="">select</option>
                                <option value="static">Static</option>
                                <option value="dynamic">Dynamic</option>
                              </select>
                            </div>
                          </div>
                          {editId &&
                            editId.id != undefined &&
                            campaignPrices?.length > 0 && (
                              <>
                                <h3 className="mb-3">Campaign Prizes</h3>
                                {campaignPrices?.map((data: any, i: any) => {
                                  return (
                                    <div
                                      className="col-12 col-md-4 my-4"
                                      key={i}
                                    >
                                      <div className="card custom_prize_box p-3 pb-0">
                                        <div className="d-flex justify-content-between flex-wrap">
                                          <label
                                            className="d-flex align-items-center mb-3"
                                            htmlFor={"priceDetails" + i}
                                          >
                                            {data.campaign_name}
                                          </label>
                                          <div className="input-group first mb-3 input_prepend">
                                            <Select
                                              isMulti
                                              options={prizesList}
                                              closeMenuOnSelect={false}
                                              components={makeAnimated()}
                                              getOptionLabel={(option: any) =>
                                                option.name
                                              }
                                              getOptionValue={(option: any) =>
                                                option.id
                                              }
                                              value={prizesList?.filter(
                                                (item: any) =>
                                                  data.prize
                                                    ?.map((dat: any) =>
                                                      dat.prize_id?.toString()
                                                    )
                                                    ?.indexOf(
                                                      item.id?.toString()
                                                    ) !== -1
                                              )}
                                              classNamePrefix=""
                                              className={"w-100 "}
                                              onChange={(val: any) => {
                                                setCampaignPrices(
                                                  (prevData) => {
                                                    const updatedData = [
                                                      ...prevData,
                                                    ];
                                                    const objectToUpdate =
                                                      updatedData[i];
                                                    if (objectToUpdate) {
                                                      objectToUpdate.campaign_id =
                                                        editId.id;
                                                      objectToUpdate.campaign_name =
                                                        editId.name;
                                                      let prizesDetails =
                                                        objectToUpdate;
                                                      if (prizesDetails) {
                                                        if (val.length > 0) {
                                                          let djfg = val.filter(
                                                            (element) =>
                                                              !prizesDetails.prize
                                                                ?.map(
                                                                  (dat: any) =>
                                                                    dat.prize_id?.toString()
                                                                )
                                                                .includes(
                                                                  element.id?.toString()
                                                                )
                                                          );
                                                          if (djfg.length > 0) {
                                                            prizesDetails.prize =
                                                              [
                                                                ...prizesDetails.prize,
                                                                {
                                                                  prize_id:
                                                                    djfg[0].id,
                                                                  name: djfg[0]
                                                                    .name,
                                                                  count: 1,
                                                                },
                                                              ];
                                                          } else {
                                                            let rev =
                                                              prizesDetails.prize.filter(
                                                                (element) =>
                                                                  val
                                                                    ?.map(
                                                                      (
                                                                        dat: any
                                                                      ) =>
                                                                        dat.id?.toString()
                                                                    )
                                                                    .includes(
                                                                      element.prize_id?.toString()
                                                                    )
                                                              );
                                                            prizesDetails.prize =
                                                              rev;
                                                            console.log(
                                                              "foewyriweurlwejrgi",
                                                              rev,
                                                              val
                                                            );
                                                          }
                                                        } else {
                                                          prizesDetails.prize =
                                                            [];
                                                        }
                                                      }
                                                    }
                                                    return updatedData;
                                                  }
                                                );
                                              }}
                                              placeholder={"select.."}
                                            />
                                          </div>
                                        </div>
                                        {data.prize?.map((vall, g) => {
                                          return (
                                            <div className="w-100 mb-4" key={g}>
                                              <label
                                                htmlFor="basic-url"
                                                className="form-label mb-0"
                                              >
                                                {vall.name}
                                              </label>
                                              <div className="input-group mb-3">
                                                <input
                                                  type="number"
                                                  className="form-control"
                                                  value={vall.count}
                                                  onChange={(e) =>
                                                    setCampaignPrices(
                                                      (prevData) => {
                                                        const updatedData = [
                                                          ...prevData,
                                                        ];
                                                        const objectToUpdate =
                                                          updatedData[i];
                                                        if (objectToUpdate) {
                                                          let prizesCounts = [
                                                            ...objectToUpdate?.prize,
                                                          ];
                                                          let prizeTemp =
                                                            prizesCounts[g];
                                                          if (prizeTemp) {
                                                            prizeTemp.count =
                                                              e.target.value;
                                                          }
                                                        }
                                                        return updatedData;
                                                      }
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  );
                                })}
                              </>
                            )}
                          {editId &&
                            editId.id != undefined &&
                            areaPrices?.length > 0 && (
                              <>
                                <h3 className="mb-3">Area Prizes</h3>
                                {areaPrices?.map((data: any, i: any) => {
                                  return (
                                    <div
                                      className="col-12 col-md-4 my-4"
                                      key={i}
                                    >
                                      <div className="card custom_prize_box p-3 pb-0">
                                        <div className="d-flex justify-content-between flex-wrap">
                                          <label
                                            className="d-flex align-items-center mb-3"
                                            htmlFor={"priceDetails" + i}
                                          >
                                            {data.area_name}
                                          </label>
                                          <div className="input-group first mb-3 input_prepend">
                                            <Select
                                              isMulti
                                              options={prizesList}
                                              closeMenuOnSelect={false}
                                              components={makeAnimated()}
                                              getOptionLabel={(option: any) =>
                                                option.name
                                              }
                                              getOptionValue={(option: any) =>
                                                option.id
                                              }
                                              value={prizesList?.filter(
                                                (item: any) =>
                                                  data.prize
                                                    ?.map((dat: any) =>
                                                      dat.prize_id?.toString()
                                                    )
                                                    .indexOf(
                                                      item.id?.toString()
                                                    ) !== -1
                                              )}
                                              classNamePrefix=""
                                              className={"w-100 "}
                                              onChange={(val: any) => {
                                                setAreaPrices((prevData) => {
                                                  const updatedData = [
                                                    ...prevData,
                                                  ];
                                                  const objectToUpdate =
                                                    updatedData[i];
                                                  if (objectToUpdate) {
                                                    let prizesDetails =
                                                      objectToUpdate;
                                                    if (prizesDetails) {
                                                      if (val.length > 0) {
                                                        let djfg = val.filter(
                                                          (element) =>
                                                            !prizesDetails.prize
                                                              ?.map(
                                                                (dat: any) =>
                                                                  dat.prize_id?.toString()
                                                              )
                                                              .includes(
                                                                element.id?.toString()
                                                              )
                                                        );
                                                        if (djfg.length > 0) {
                                                          prizesDetails.prize =
                                                            [
                                                              ...prizesDetails.prize,
                                                              {
                                                                prize_id:
                                                                  djfg[0].id,
                                                                name: djfg[0]
                                                                  .name,
                                                                count: 1,
                                                              },
                                                            ];
                                                        } else {
                                                          let rev =
                                                            prizesDetails.prize.filter(
                                                              (element) =>
                                                                val
                                                                  ?.map(
                                                                    (
                                                                      dat: any
                                                                    ) =>
                                                                      dat.id?.toString()
                                                                  )
                                                                  .includes(
                                                                    element.prize_id?.toString()
                                                                  )
                                                            );
                                                          prizesDetails.prize =
                                                            rev;
                                                          console.log(
                                                            "foewyriweurlwejrgi",
                                                            rev,
                                                            val
                                                          );
                                                        }
                                                      } else {
                                                        prizesDetails.prize =
                                                          [];
                                                      }
                                                    }
                                                  }
                                                  return updatedData;
                                                });
                                              }}
                                              placeholder={"select.."}
                                            />
                                          </div>
                                        </div>
                                        {data.prize?.map((vall, g) => {
                                          return (
                                            <div className="w-100 mb-4" key={g}>
                                              <label
                                                htmlFor="basic-url"
                                                className="form-label mb-0"
                                              >
                                                {vall.name}
                                              </label>
                                              <div className="input-group mb-3">
                                                <input
                                                  type="number"
                                                  className="form-control"
                                                  value={vall.count}
                                                  onChange={(e) =>
                                                    setAreaPrices(
                                                      (prevData) => {
                                                        const updatedData = [
                                                          ...prevData,
                                                        ];
                                                        const objectToUpdate =
                                                          updatedData[i];
                                                        if (objectToUpdate) {
                                                          let prizesCounts = [
                                                            ...objectToUpdate?.prize,
                                                          ];
                                                          let prizeTemp =
                                                            prizesCounts[g];
                                                          if (prizeTemp) {
                                                            prizeTemp.count =
                                                              e.target.value;
                                                          }
                                                        }
                                                        return updatedData;
                                                      }
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  );
                                })}
                              </>
                            )}
                          {editId &&
                            editId.id != undefined &&
                            roPrices?.length > 0 && (
                              <>
                                <h3 className="mb-3">RO Prizes</h3>
                                {roPrices?.map((data: any, i: any) => {
                                  return (
                                    <div
                                      className="col-12 col-md-4 my-4"
                                      key={i}
                                    >
                                      <div className="card custom_prize_box p-3 pb-0">
                                        <div className="d-flex justify-content-between flex-wrap">
                                          <label
                                            className="d-flex align-items-center mb-3"
                                            htmlFor={"priceDetails" + i}
                                          >
                                            {data.ro_name}
                                          </label>
                                          <div className="input-group first mb-3 input_prepend">
                                            <Select
                                              isMulti
                                              options={prizesList}
                                              closeMenuOnSelect={false}
                                              components={makeAnimated()}
                                              getOptionLabel={(option: any) =>
                                                option.name
                                              }
                                              getOptionValue={(option: any) =>
                                                option.id
                                              }
                                              value={prizesList?.filter(
                                                (item: any) =>
                                                  data.prize
                                                    ?.map((dat: any) =>
                                                      dat.prize_id?.toString()
                                                    )
                                                    .indexOf(
                                                      item.id?.toString()
                                                    ) !== -1
                                              )}
                                              classNamePrefix=""
                                              className={"w-100 "}
                                              onChange={(val: any) => {
                                                setRoPrices((prevData) => {
                                                  const updatedData = [
                                                    ...prevData,
                                                  ];
                                                  const objectToUpdate =
                                                    updatedData[i];
                                                  if (objectToUpdate) {
                                                    let prizesDetails =
                                                      objectToUpdate;
                                                    if (prizesDetails) {
                                                      if (val.length > 0) {
                                                        let djfg = val.filter(
                                                          (element) =>
                                                            !prizesDetails.prize
                                                              ?.map(
                                                                (dat: any) =>
                                                                  dat.prize_id?.toString()
                                                              )
                                                              .includes(
                                                                element.id?.toString()
                                                              )
                                                        );
                                                        if (djfg.length > 0) {
                                                          prizesDetails.prize =
                                                            [
                                                              ...prizesDetails.prize,
                                                              {
                                                                prize_id:
                                                                  djfg[0].id,
                                                                name: djfg[0]
                                                                  .name,
                                                                count: 1,
                                                              },
                                                            ];
                                                        } else {
                                                          let rev =
                                                            prizesDetails.prize.filter(
                                                              (element) =>
                                                                val
                                                                  ?.map(
                                                                    (
                                                                      dat: any
                                                                    ) =>
                                                                      dat.id?.toString()
                                                                  )
                                                                  .includes(
                                                                    element.prize_id?.toString()
                                                                  )
                                                            );
                                                          prizesDetails.prize =
                                                            rev;
                                                          console.log(
                                                            "foewyriweurlwejrgi",
                                                            rev,
                                                            val
                                                          );
                                                        }
                                                      } else {
                                                        prizesDetails.prize =
                                                          [];
                                                      }
                                                    }
                                                  }
                                                  return updatedData;
                                                });
                                              }}
                                              placeholder={"select.."}
                                            />
                                          </div>
                                        </div>
                                        {data.prize?.map((vall, g) => {
                                          return (
                                            <div className="w-100 mb-4" key={g}>
                                              <label
                                                htmlFor="basic-url"
                                                className="form-label mb-0"
                                              >
                                                {vall.name}
                                              </label>
                                              <div className="input-group mb-3">
                                                <input
                                                  type="number"
                                                  className="form-control"
                                                  value={vall.count}
                                                  onChange={(e) =>
                                                    setRoPrices((prevData) => {
                                                      const updatedData = [
                                                        ...prevData,
                                                      ];
                                                      const objectToUpdate =
                                                        updatedData[i];
                                                      if (objectToUpdate) {
                                                        let prizesCounts = [
                                                          ...objectToUpdate?.prize,
                                                        ];
                                                        let prizeTemp =
                                                          prizesCounts[g];
                                                        if (prizeTemp) {
                                                          prizeTemp.count =
                                                            e.target.value;
                                                        }
                                                      }
                                                      return updatedData;
                                                    })
                                                  }
                                                />
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  );
                                })}
                              </>
                            )}
                        </div>
                        <span className="d-flex justify-content-end">
                          <button
                            type="button"
                            className="btn btn-sm btn-primary me-3 ps-3"
                            onClick={() =>
                              document.getElementById("additional-tab")?.click()
                            }
                          >
                            <i className="bi bi-chevron-left"></i> Prev
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-primary pe-3"
                            onClick={() =>
                              document.getElementById("notes-tab")?.click()
                            }
                          >
                            Next <i className="bi bi-chevron-right"></i>
                          </button>
                        </span>
                      </div>
                      <div
                        className="tab-pane fade"
                        id={"notes"}
                        role="tabpanel"
                        aria-labelledby="notes-tab"
                      >
                        <div className="d-flex align-items-center justify-content-between mb-4">
                          <h3 className="m-0">Custom Prizes</h3>
                        </div>
                        {customPrize.map((item: any, i) => {
                          if (item.status == "1") {
                            return (
                              <div
                                className="card custom_prize_box mb-4"
                                key={i}
                              >
                                <div className="card-body py-3">
                                  <div className="d-flex flex-end">
                                    <button
                                      className="btn btn-sm btn-secondary btn-active-color-danger btn-icon"
                                      onClick={() =>
                                        setCustomPrize((prevData) => {
                                          const updatedData = [...prevData];
                                          const objectToUpdate = updatedData[i];
                                          if (objectToUpdate) {
                                            objectToUpdate.status = "0";
                                          }
                                          return updatedData;
                                        })
                                      }
                                    >
                                      <i className="bi bi-trash"></i>
                                    </button>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-4 col-12 my-3">
                                      <label
                                        htmlFor="basic-url"
                                        className="form-label"
                                      >
                                        Start Date
                                      </label>
                                      <div className="input-group mb-3">
                                        <input
                                          type="date"
                                          className="form-control"
                                          min={formik.values.start_date}
                                          max={formik.values.end_date}
                                          value={item.start_date}
                                          onChange={(e) =>
                                            setCustomPrize((prevData) => {
                                              const updatedData = [...prevData];
                                              const objectToUpdate =
                                                updatedData[i];
                                              if (objectToUpdate) {
                                                objectToUpdate.start_date =
                                                  e.target.value;
                                              }
                                              return updatedData;
                                            })
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-12 my-3">
                                      <label
                                        htmlFor="basic-url"
                                        className="form-label"
                                      >
                                        End Date
                                      </label>
                                      <div className="input-group mb-3">
                                        <input
                                          type="date"
                                          className="form-control"
                                          min={
                                            item.start_date ||
                                            formik.values.start_date
                                          }
                                          max={formik.values.end_date}
                                          value={item.end_date}
                                          onChange={(e) =>
                                            setCustomPrize((prevData) => {
                                              const updatedData = [...prevData];
                                              const objectToUpdate =
                                                updatedData[i];
                                              if (objectToUpdate) {
                                                objectToUpdate.end_date =
                                                  e.target.value;
                                              }
                                              return updatedData;
                                            })
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-12 my-3">
                                      <label
                                        htmlFor="basic-url"
                                        className="form-label"
                                      >
                                        Winners
                                      </label>
                                      <div className="input-group first mb-3 input_prepend">
                                        <select
                                          className="form-control"
                                          value={item.options}
                                          onChange={(e) =>
                                            setCustomPrize((prevData) => {
                                              const updatedData = [...prevData];
                                              const objectToUpdate =
                                                updatedData[i];
                                              if (objectToUpdate) {
                                                objectToUpdate.options =
                                                  e.target.value;
                                              }
                                              return updatedData;
                                            })
                                          }
                                        >
                                          <option value="include">
                                            Include
                                          </option>
                                          <option value="exclude">
                                            Exclude
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-12 my-3">
                                      <label
                                        htmlFor="basic-url"
                                        className="form-label"
                                      >
                                        Prize on
                                      </label>
                                      <div className="input-group first mb-3 input_prepend">
                                        <select
                                          className="form-control"
                                          value={item.type}
                                          onChange={(e) =>
                                            setCustomPrize((prevData) => {
                                              const updatedData = [...prevData];
                                              const objectToUpdate =
                                                updatedData[i];
                                              if (objectToUpdate) {
                                                objectToUpdate.type =
                                                  e.target.value;
                                              }
                                              return updatedData;
                                            })
                                          }
                                        >
                                          <option value="fixed">Fixed</option>
                                          <option value="daily">Daily</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-md-8 col-12 my-3">
                                      <div className="row align-items-center h-100">
                                        <div className="col-sm-4 col-12 my-3">
                                          <div className="form-check form-check-custom form-check-solid text-end">
                                            <label
                                              htmlFor="basic-url"
                                              className="form-label me-3"
                                            >
                                              Campaign wise winner
                                            </label>
                                            <input
                                              className="form-check-input col-6"
                                              type="checkbox"
                                              checked={
                                                item.prize_wise?.find(
                                                  (item) =>
                                                    item == "campaign_wise"
                                                )
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) => {
                                                if (e.target.checked) {
                                                  let prize: any = {
                                                    campaign_id:
                                                      editId?.id || "",
                                                    campaign_name:
                                                      editId?.name ||
                                                      formik.values.name,
                                                    prize: [],
                                                  };
                                                  setCustomPrize((prevData) => {
                                                    const updatedData = [
                                                      ...prevData,
                                                    ];
                                                    const objectToUpdate =
                                                      updatedData[i];
                                                    if (objectToUpdate) {
                                                      objectToUpdate.prize_wise =
                                                        [
                                                          ...objectToUpdate.prize_wise,
                                                          "campaign_wise",
                                                        ];
                                                      objectToUpdate.prizes = {
                                                        ...objectToUpdate.prizes,
                                                        campaign: [prize],
                                                      };
                                                    }
                                                    return updatedData;
                                                  });
                                                } else {
                                                  setCustomPrize((prevData) => {
                                                    const updatedData = [
                                                      ...prevData,
                                                    ];
                                                    const objectToUpdate =
                                                      updatedData[i];
                                                    if (objectToUpdate) {
                                                      objectToUpdate.prize_wise =
                                                        objectToUpdate.prize_wise?.filter(
                                                          (data) =>
                                                            data !==
                                                            "campaign_wise"
                                                        );
                                                      objectToUpdate.prizes = {
                                                        ...objectToUpdate.prizes,
                                                        campaign: [],
                                                      };
                                                    }
                                                    return updatedData;
                                                  });
                                                }
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-sm-4 col-12 my-3">
                                          <div className="form-check form-check-custom form-check-solid text-end">
                                            <label
                                              htmlFor="basic-url"
                                              className="form-label me-3"
                                            >
                                              Area wise winner
                                            </label>
                                            <input
                                              className="form-check-input col-6"
                                              type="checkbox"
                                              checked={
                                                item.prize_wise?.find(
                                                  (item) => item == "area_wise"
                                                )
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) => {
                                                if (e.target.checked) {
                                                  let areaPrizes: any = [];
                                                  selectedArea.map(
                                                    (area: any) =>
                                                      areaPrizes.push({
                                                        area_id: area.id,
                                                        area_name: area.city,
                                                        prize: [],
                                                      })
                                                  );
                                                  setCustomPrize((prevData) => {
                                                    const updatedData = [
                                                      ...prevData,
                                                    ];
                                                    const objectToUpdate =
                                                      updatedData[i];
                                                    if (objectToUpdate) {
                                                      objectToUpdate.prize_wise =
                                                        [
                                                          ...objectToUpdate.prize_wise,
                                                          "area_wise",
                                                        ];
                                                      objectToUpdate.prizes = {
                                                        ...objectToUpdate.prizes,
                                                        area: areaPrizes,
                                                      };
                                                    }
                                                    return updatedData;
                                                  });
                                                } else {
                                                  setCustomPrize((prevData) => {
                                                    const updatedData = [
                                                      ...prevData,
                                                    ];
                                                    const objectToUpdate =
                                                      updatedData[i];
                                                    if (objectToUpdate) {
                                                      objectToUpdate.prize_wise =
                                                        objectToUpdate.prize_wise?.filter(
                                                          (data) =>
                                                            data !== "area_wise"
                                                        );
                                                      objectToUpdate.prizes = {
                                                        ...objectToUpdate.prizes,
                                                        area: [],
                                                      };
                                                    }
                                                    return updatedData;
                                                  });
                                                }
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-sm-4 col-12 my-3">
                                          <div className="form-check form-check-custom form-check-solid text-end">
                                            <label
                                              htmlFor="basic-url"
                                              className="form-label me-3"
                                            >
                                              RO wise winner
                                            </label>
                                            <input
                                              className="form-check-input col-6"
                                              type="checkbox"
                                              checked={
                                                item.prize_wise?.find(
                                                  (item) => item == "ro_wise"
                                                )
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) => {
                                                if (e.target.checked) {
                                                  let roPrizes: any = [];
                                                  selectedRo.map((area: any) =>
                                                    roPrizes.push({
                                                      ro_id: area.id,
                                                      ro_name: area.ro_name,
                                                      prize: [],
                                                    })
                                                  );
                                                  setCustomPrize((prevData) => {
                                                    const updatedData = [
                                                      ...prevData,
                                                    ];
                                                    const objectToUpdate =
                                                      updatedData[i];
                                                    if (objectToUpdate) {
                                                      objectToUpdate.prize_wise =
                                                        [
                                                          ...objectToUpdate.prize_wise,
                                                          "ro_wise",
                                                        ];
                                                      objectToUpdate.prizes = {
                                                        ...objectToUpdate.prizes,
                                                        ro: roPrizes,
                                                      };
                                                    }
                                                    return updatedData;
                                                  });
                                                } else {
                                                  setCustomPrize((prevData) => {
                                                    const updatedData = [
                                                      ...prevData,
                                                    ];
                                                    const objectToUpdate =
                                                      updatedData[i];
                                                    if (objectToUpdate) {
                                                      objectToUpdate.prize_wise =
                                                        objectToUpdate.prize_wise?.filter(
                                                          (data) =>
                                                            data !== "ro_wise"
                                                        );
                                                      objectToUpdate.prizes = {
                                                        ...objectToUpdate.prizes,
                                                        ro: [],
                                                      };
                                                    }
                                                    return updatedData;
                                                  });
                                                }
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-12 my-3">
                                      <label
                                        htmlFor="basic-url"
                                        className="form-label"
                                      >
                                        Campaign Winner Type
                                      </label>
                                      <div className="input-group first mb-3 input_prepend">
                                        <select
                                          className="form-control"
                                          value={
                                            item.winner_type?.campaign_winner
                                          }
                                          onChange={(e) => {
                                            setCustomPrize((prevData) => {
                                              const updatedData = [...prevData];
                                              const objectToUpdate =
                                                updatedData[i];
                                              if (objectToUpdate) {
                                                objectToUpdate.winner_type = {
                                                  ...objectToUpdate.winner_type,
                                                  campaign_winner:
                                                    e.target.value,
                                                };
                                              }
                                              return updatedData;
                                            });
                                          }}
                                        >
                                          <option value="">select</option>
                                          <option value="static">Static</option>
                                          <option value="dynamic">
                                            Dynamic
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-12 my-3">
                                      <label
                                        htmlFor="basic-url"
                                        className="form-label"
                                      >
                                        Area Winner Type
                                      </label>
                                      <div className="input-group first mb-3 input_prepend">
                                        <select
                                          className="form-control"
                                          value={item.winner_type?.area_winner}
                                          onChange={(e) => {
                                            setCustomPrize((prevData) => {
                                              const updatedData = [...prevData];
                                              const objectToUpdate =
                                                updatedData[i];
                                              if (objectToUpdate) {
                                                objectToUpdate.winner_type = {
                                                  ...objectToUpdate.winner_type,
                                                  area_winner: e.target.value,
                                                };
                                              }
                                              return updatedData;
                                            });
                                          }}
                                        >
                                          <option value="">select</option>
                                          <option value="static">Static</option>
                                          <option value="dynamic">
                                            Dynamic
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-12 my-3">
                                      <label
                                        htmlFor="basic-url"
                                        className="form-label"
                                      >
                                        RO Winner Type
                                      </label>
                                      <div className="input-group first mb-3 input_prepend">
                                        <select
                                          className="form-control"
                                          value={item.winner_type?.ro_winner}
                                          onChange={(e) => {
                                            setCustomPrize((prevData) => {
                                              const updatedData = [...prevData];
                                              const objectToUpdate =
                                                updatedData[i];
                                              if (objectToUpdate) {
                                                objectToUpdate.winner_type = {
                                                  ...objectToUpdate.winner_type,
                                                  ro_winner: e.target.value,
                                                };
                                              }
                                              return updatedData;
                                            });
                                          }}
                                        >
                                          <option value="">select</option>
                                          <option value="static">Static</option>
                                          <option value="dynamic">
                                            Dynamic
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    {editId &&
                                      editId.id != undefined &&
                                      item.prizes?.campaign?.length > 0 && (
                                        <>
                                          <h3 className="mb-3">Prizes</h3>
                                          {item.prizes?.campaign?.map(
                                            (data: any, l: any) => {
                                              const obj = prizesList?.filter(
                                                (item: any) =>
                                                  data.prize
                                                    ?.map((dat: any) =>
                                                      dat.prize_id?.toString()
                                                    )
                                                    .indexOf(
                                                      item.id?.toString()
                                                    ) !== -1
                                              );

                                              return (
                                                <div
                                                  className="col-12 col-md-4 my-4 px-3"
                                                  key={l}
                                                >
                                                  <div className="card custom_prize_box p-3 pb-0">
                                                    <div className="d-flex justify-content-between flex-wrap">
                                                      <label className="form-label d-flex align-items-center mb-3">
                                                        {data.campaign_name}
                                                      </label>
                                                      <div className="input-group first mb-4 input_prepend">
                                                        <Select
                                                          isMulti
                                                          options={prizesList}
                                                          closeMenuOnSelect={
                                                            false
                                                          }
                                                          components={makeAnimated()}
                                                          getOptionLabel={(
                                                            option: any
                                                          ) => option.name}
                                                          getOptionValue={(
                                                            option: any
                                                          ) => option.id}
                                                          value={obj}
                                                          classNamePrefix=""
                                                          className={"w-100 "}
                                                          onChange={(
                                                            val: any
                                                          ) => {
                                                            setCustomPrize(
                                                              (prevData) => {
                                                                const updatedData =
                                                                  [...prevData];
                                                                const objectToUpdate =
                                                                  updatedData[
                                                                    i
                                                                  ];
                                                                if (
                                                                  objectToUpdate
                                                                ) {
                                                                  let prizesDetails =
                                                                    [
                                                                      ...objectToUpdate
                                                                        .prizes
                                                                        ?.campaign,
                                                                    ];
                                                                  let sdkfg: any =
                                                                    prizesDetails[
                                                                      l
                                                                    ];
                                                                  if (sdkfg) {
                                                                    if (
                                                                      val.length >
                                                                      0
                                                                    ) {
                                                                      let djfg =
                                                                        val.filter(
                                                                          (
                                                                            element
                                                                          ) =>
                                                                            !sdkfg.prize
                                                                              ?.map(
                                                                                (
                                                                                  dat: any
                                                                                ) =>
                                                                                  dat.prize_id?.toString()
                                                                              )
                                                                              .includes(
                                                                                element.id?.toString()
                                                                              )
                                                                        );
                                                                      if (
                                                                        djfg.length >
                                                                        0
                                                                      ) {
                                                                        sdkfg.prize =
                                                                          [
                                                                            ...sdkfg.prize,
                                                                            {
                                                                              prize_id:
                                                                                djfg[0]
                                                                                  .id,
                                                                              name: djfg[0]
                                                                                .name,
                                                                              count: 1,
                                                                            },
                                                                          ];
                                                                      } else {
                                                                        let rev =
                                                                          sdkfg.prize.filter(
                                                                            (
                                                                              element
                                                                            ) =>
                                                                              val
                                                                                ?.map(
                                                                                  (
                                                                                    dat: any
                                                                                  ) =>
                                                                                    dat.id?.toString()
                                                                                )
                                                                                .includes(
                                                                                  element.prize_id?.toString()
                                                                                )
                                                                          );
                                                                        sdkfg.prize =
                                                                          rev;
                                                                        console.log(
                                                                          "foewyriweurlwejrgi",
                                                                          rev,
                                                                          val
                                                                        );
                                                                      }
                                                                    } else {
                                                                      sdkfg.prize =
                                                                        [];
                                                                    }
                                                                  }
                                                                }
                                                                return updatedData;
                                                              }
                                                            );
                                                          }}
                                                          placeholder={
                                                            "select.."
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                    {data.prize?.map(
                                                      (vall, g) => {
                                                        return (
                                                          <div
                                                            className="w-100 mb-4"
                                                            key={g}
                                                          >
                                                            <label
                                                              htmlFor="basic-url"
                                                              className="form-label mb-0"
                                                            >
                                                              {vall.name}
                                                            </label>
                                                            <div className="input-group mb-3">
                                                              <input
                                                                type="number"
                                                                className="form-control"
                                                                value={
                                                                  vall.count
                                                                }
                                                                onChange={(e) =>
                                                                  setCustomPrize(
                                                                    (
                                                                      prevData
                                                                    ) => {
                                                                      const updatedData =
                                                                        [
                                                                          ...prevData,
                                                                        ];
                                                                      const objectToUpdate =
                                                                        updatedData[
                                                                          i
                                                                        ];
                                                                      if (
                                                                        objectToUpdate
                                                                      ) {
                                                                        let prizesCounts =
                                                                          [
                                                                            ...objectToUpdate
                                                                              .prizes
                                                                              ?.campaign[
                                                                              l
                                                                            ]
                                                                              ?.prize,
                                                                          ];
                                                                        let prizeTemp =
                                                                          prizesCounts[
                                                                            g
                                                                          ];
                                                                        if (
                                                                          prizeTemp
                                                                        ) {
                                                                          prizeTemp.count =
                                                                            e.target.value;
                                                                        }
                                                                      }
                                                                      return updatedData;
                                                                    }
                                                                  )
                                                                }
                                                              />
                                                            </div>
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                        </>
                                      )}
                                    {editId &&
                                      editId.id != undefined &&
                                      item.prizes?.area?.length > 0 && (
                                        <>
                                          <h3 className="mb-3">Area Prizes</h3>
                                          {item.prizes?.area?.map(
                                            (data: any, j: any) => {
                                              return (
                                                <div
                                                  className="col-12 col-md-4 my-4 px-3"
                                                  key={j}
                                                >
                                                  <div className="card custom_prize_box p-3 pb-0">
                                                    <div className="d-flex justify-content-between flex-wrap">
                                                      <label className="form-label d-flex align-items-center mb-3">
                                                        {data.area_name}
                                                      </label>
                                                      <div className="input-group first mb-3 input_prepend">
                                                        <Select
                                                          isMulti
                                                          options={prizesList}
                                                          closeMenuOnSelect={
                                                            false
                                                          }
                                                          components={makeAnimated()}
                                                          getOptionLabel={(
                                                            option: any
                                                          ) => option.name}
                                                          getOptionValue={(
                                                            option: any
                                                          ) => option.id}
                                                          value={prizesList?.filter(
                                                            (item: any) =>
                                                              data.prize
                                                                ?.map(
                                                                  (dat: any) =>
                                                                    dat.prize_id?.toString()
                                                                )
                                                                .indexOf(
                                                                  item.id?.toString()
                                                                ) !== -1
                                                          )}
                                                          classNamePrefix=""
                                                          className={"w-100 "}
                                                          onChange={(
                                                            val: any
                                                          ) => {
                                                            setCustomPrize(
                                                              (prevData) => {
                                                                const updatedData =
                                                                  [...prevData];
                                                                const objectToUpdate =
                                                                  updatedData[
                                                                    i
                                                                  ];
                                                                if (
                                                                  objectToUpdate
                                                                ) {
                                                                  let prizesDetails =
                                                                    [
                                                                      ...objectToUpdate
                                                                        .prizes
                                                                        ?.area,
                                                                    ];
                                                                  let sdkfg: any =
                                                                    prizesDetails[
                                                                      j
                                                                    ];
                                                                  if (sdkfg) {
                                                                    if (
                                                                      val.length >
                                                                      0
                                                                    ) {
                                                                      let djfg =
                                                                        val.filter(
                                                                          (
                                                                            element
                                                                          ) =>
                                                                            !sdkfg.prize
                                                                              ?.map(
                                                                                (
                                                                                  dat: any
                                                                                ) =>
                                                                                  dat.prize_id?.toString()
                                                                              )
                                                                              .includes(
                                                                                element.id?.toString()
                                                                              )
                                                                        );
                                                                      if (
                                                                        djfg.length >
                                                                        0
                                                                      ) {
                                                                        sdkfg.prize =
                                                                          [
                                                                            ...sdkfg.prize,
                                                                            {
                                                                              prize_id:
                                                                                djfg[0]
                                                                                  .id,
                                                                              name: djfg[0]
                                                                                .name,
                                                                              count: 1,
                                                                            },
                                                                          ];
                                                                      } else {
                                                                        let rev =
                                                                          sdkfg.prize.filter(
                                                                            (
                                                                              element
                                                                            ) =>
                                                                              val
                                                                                ?.map(
                                                                                  (
                                                                                    dat: any
                                                                                  ) =>
                                                                                    dat.id?.toString()
                                                                                )
                                                                                .includes(
                                                                                  element.prize_id?.toString()
                                                                                )
                                                                          );
                                                                        sdkfg.prize =
                                                                          rev;
                                                                        console.log(
                                                                          "foewyriweurlwejrgi",
                                                                          rev,
                                                                          val
                                                                        );
                                                                      }
                                                                    } else {
                                                                      sdkfg.prize =
                                                                        [];
                                                                    }
                                                                  }
                                                                }
                                                                return updatedData;
                                                              }
                                                            );
                                                          }}
                                                          placeholder={
                                                            "select.."
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                    {data.prize?.map(
                                                      (vall, g) => {
                                                        return (
                                                          <div
                                                            className="w-100 mb-4"
                                                            key={g}
                                                          >
                                                            <label
                                                              htmlFor="basic-url"
                                                              className="form-label mb-0"
                                                            >
                                                              {vall.name}
                                                            </label>
                                                            <div className="input-group mb-3">
                                                              <input
                                                                type="number"
                                                                className="form-control"
                                                                value={
                                                                  vall.count
                                                                }
                                                                onChange={(e) =>
                                                                  setCustomPrize(
                                                                    (
                                                                      prevData
                                                                    ) => {
                                                                      const updatedData =
                                                                        [
                                                                          ...prevData,
                                                                        ];
                                                                      const objectToUpdate =
                                                                        updatedData[
                                                                          i
                                                                        ];
                                                                      if (
                                                                        objectToUpdate
                                                                      ) {
                                                                        let prizesCounts =
                                                                          [
                                                                            ...objectToUpdate
                                                                              .prizes
                                                                              ?.area[
                                                                              j
                                                                            ]
                                                                              ?.prize,
                                                                          ];
                                                                        let prizeTemp =
                                                                          prizesCounts[
                                                                            g
                                                                          ];
                                                                        if (
                                                                          prizeTemp
                                                                        ) {
                                                                          prizeTemp.count =
                                                                            e.target.value;
                                                                        }
                                                                      }
                                                                      return updatedData;
                                                                    }
                                                                  )
                                                                }
                                                              />
                                                            </div>
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                        </>
                                      )}
                                    {editId &&
                                      editId.id != undefined &&
                                      item.prizes?.ro?.length > 0 && (
                                        <>
                                          <h3 className="mb-3">RO Prizes</h3>
                                          {item.prizes?.ro?.map(
                                            (data: any, k: any) => {
                                              return (
                                                <div
                                                  className="col-12 col-md-4 my-4"
                                                  key={k}
                                                >
                                                  <div className="card custom_prize_box p-3 pb-0">
                                                    <div className="d-flex justify-content-between flex-wrap">
                                                      <label
                                                        className="form-label d-flex align-items-center mb-3"
                                                        htmlFor={
                                                          "priceDetails" + i
                                                        }
                                                      >
                                                        {data.ro_name}
                                                      </label>
                                                      <div className="input-group first mb-3 input_prepend">
                                                        <Select
                                                          isMulti
                                                          options={prizesList}
                                                          closeMenuOnSelect={
                                                            false
                                                          }
                                                          components={makeAnimated()}
                                                          getOptionLabel={(
                                                            option: any
                                                          ) => option.name}
                                                          getOptionValue={(
                                                            option: any
                                                          ) => option.id}
                                                          value={prizesList?.filter(
                                                            (item: any) =>
                                                              data.prize
                                                                ?.map(
                                                                  (dat: any) =>
                                                                    dat.prize_id?.toString()
                                                                )
                                                                .indexOf(
                                                                  item.id?.toString()
                                                                ) !== -1
                                                          )}
                                                          classNamePrefix=""
                                                          className={"w-100 "}
                                                          onChange={(
                                                            val: any
                                                          ) => {
                                                            setCustomPrize(
                                                              (prevData) => {
                                                                const updatedData =
                                                                  [...prevData];
                                                                const objectToUpdate =
                                                                  updatedData[
                                                                    i
                                                                  ];
                                                                if (
                                                                  objectToUpdate
                                                                ) {
                                                                  let prizesDetails =
                                                                    [
                                                                      ...objectToUpdate
                                                                        .prizes
                                                                        ?.ro,
                                                                    ];
                                                                  let sdkfg: any =
                                                                    prizesDetails[
                                                                      k
                                                                    ];
                                                                  if (sdkfg) {
                                                                    if (
                                                                      val.length >
                                                                      0
                                                                    ) {
                                                                      let djfg =
                                                                        val.filter(
                                                                          (
                                                                            element
                                                                          ) =>
                                                                            !sdkfg.prize
                                                                              ?.map(
                                                                                (
                                                                                  dat: any
                                                                                ) =>
                                                                                  dat.prize_id?.toString()
                                                                              )
                                                                              .includes(
                                                                                element.id?.toString()
                                                                              )
                                                                        );
                                                                      if (
                                                                        djfg.length >
                                                                        0
                                                                      ) {
                                                                        sdkfg.prize =
                                                                          [
                                                                            ...sdkfg.prize,
                                                                            {
                                                                              prize_id:
                                                                                djfg[0]
                                                                                  .id,
                                                                              name: djfg[0]
                                                                                .name,
                                                                              count: 1,
                                                                            },
                                                                          ];
                                                                      } else {
                                                                        let rev =
                                                                          sdkfg.prize.filter(
                                                                            (
                                                                              element
                                                                            ) =>
                                                                              val
                                                                                ?.map(
                                                                                  (
                                                                                    dat: any
                                                                                  ) =>
                                                                                    dat.id?.toString()
                                                                                )
                                                                                .includes(
                                                                                  element.prize_id?.toString()
                                                                                )
                                                                          );
                                                                        sdkfg.prize =
                                                                          rev;
                                                                        console.log(
                                                                          "foewyriweurlwejrgi",
                                                                          rev,
                                                                          val
                                                                        );
                                                                      }
                                                                    } else {
                                                                      sdkfg.prize =
                                                                        [];
                                                                    }
                                                                  }
                                                                }
                                                                return updatedData;
                                                              }
                                                            );
                                                          }}
                                                          placeholder={
                                                            "select.."
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                    {data.prize?.map(
                                                      (vall, g) => {
                                                        return (
                                                          <div
                                                            className="w-100 mb-4"
                                                            key={g}
                                                          >
                                                            <label
                                                              htmlFor="basic-url"
                                                              className="form-label mb-0"
                                                            >
                                                              {vall.name}
                                                            </label>
                                                            <div className="input-group mb-3">
                                                              <input
                                                                type="number"
                                                                className="form-control"
                                                                value={
                                                                  vall.count
                                                                }
                                                                onChange={(e) =>
                                                                  setCustomPrize(
                                                                    (
                                                                      prevData
                                                                    ) => {
                                                                      const updatedData =
                                                                        [
                                                                          ...prevData,
                                                                        ];
                                                                      const objectToUpdate =
                                                                        updatedData[
                                                                          i
                                                                        ];
                                                                      if (
                                                                        objectToUpdate
                                                                      ) {
                                                                        let prizesCounts =
                                                                          [
                                                                            ...objectToUpdate
                                                                              .prizes
                                                                              ?.ro[
                                                                              k
                                                                            ]
                                                                              ?.prize,
                                                                          ];
                                                                        let prizeTemp =
                                                                          prizesCounts[
                                                                            g
                                                                          ];
                                                                        if (
                                                                          prizeTemp
                                                                        ) {
                                                                          prizeTemp.count =
                                                                            e.target.value;
                                                                        }
                                                                      }
                                                                      return updatedData;
                                                                    }
                                                                  )
                                                                }
                                                              />
                                                            </div>
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                        </>
                                      )}
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}
                        <div className="d-flex flex-end">
                          <button
                            type="button"
                            className="btn btn-sm btn-secondary btn-active-color-primary pe-3"
                            onClick={() => {
                              setCustomPrize([
                                ...customPrize,
                                {
                                  start_date: "",
                                  end_date: "",
                                  options: "include",
                                  type: "fixed",
                                  prize_wise: [],
                                  winner_type: {
                                    campaign_winner: "static",
                                    area_winner: "static",
                                    ro_winner: "static",
                                  },
                                  prizes: {
                                    campaign: [],
                                    area: [],
                                    ro: [],
                                  },
                                  status: "1",
                                },
                              ]);
                            }}
                          >
                            Add <i className="bi bi-plus-lg"></i>
                          </button>
                        </div>
                        <span className="d-flex justify-content-end mt-3">
                          <button
                            type="button"
                            className="btn btn-sm btn-primary me-3 ps-3"
                            onClick={() =>
                              document
                                .getElementById("contactAddress-tab")
                                ?.click()
                            }
                          >
                            <i className="bi bi-chevron-left"></i> Prev
                          </button>
                          <button
                            type="submit"
                            className="btn btn-sm btn-primary w-200px"
                          >
                            Submit
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default CampaignForm;
