import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const OMC_LIST_URL = `${API_URL}/omc_list`;
export const OMC_SAVE_URL = `${API_URL}/omc_save`;
export const OMC_UPDATE_URL = `${API_URL}/omc_update`;
export const OMC_DELETE_URL = `${API_URL}/omc_delete`;
export const OMC_TRANSLATION_UPDATE_URL = `${API_URL}/omc_translation_update`;
export const OMC_TRANSLATION_GET_URL = `${API_URL}/omc_translation_edit`;
export const STATE_OFFICE_LIST_URL = `${API_URL}/state_office_list`;
export const STATE_OFFICE_SAVE_URL = `${API_URL}/state_office_save`;
export const STATE_OFFICE_UPDATE_URL = `${API_URL}/state_office_update`;
export const STATE_OFFICE_DELETE_URL = `${API_URL}/state_office_delete`;
export const DIVISION_OFFICE_LIST_URL = `${API_URL}/division_office_list`;
export const DIVISION_OFFICE_SAVE_URL = `${API_URL}/division_office_save`;
export const DIVISION_OFFICE_UPDATE_URL = `${API_URL}/division_office_update`;
export const DIVISION_OFFICE_DELETE_URL = `${API_URL}/division_office_delete`;
export const AREA_OFFICE_LIST_URL = `${API_URL}/area_office_list`;
export const AREA_OFFICE_SAVE_URL = `${API_URL}/area_office_save`;
export const AREA_OFFICE_UPDATE_URL = `${API_URL}/area_office_update`;
export const AREA_OFFICE_DELETE_URL = `${API_URL}/area_office_delete`;

//rolls
export function getOmc() {
  return axios.get(OMC_LIST_URL).then((res) => res.data);
}

export function saveOmc(data) {
  return axios.post(OMC_SAVE_URL, data).then((res) => res.data);
}

export function updateOmc(id, data) {
  return axios.put(OMC_UPDATE_URL + "/" + id, data).then((res) => res.data);
}

export function deleteOmc(id) {
  return axios.put(OMC_DELETE_URL + "/" + id).then((res) => res.data);
}

//omc translation
export function updateOmcTranslation(id, data) {
  return axios
    .put(OMC_TRANSLATION_UPDATE_URL + "/" + id, data)
    .then((res) => res.data);
}

export function getOmcTranslation(id) {
  return axios.get(OMC_TRANSLATION_GET_URL + "/" + id).then((res) => res.data);
}

//State office
export function getStateOffice() {
  return axios.get(STATE_OFFICE_LIST_URL).then((res) => res.data);
}

export function saveStateOffice(data) {
  return axios.post(STATE_OFFICE_SAVE_URL, data).then((res) => res.data);
}

export function updateStateOffice(id, data) {
  return axios
    .put(STATE_OFFICE_UPDATE_URL + "/" + id, data)
    .then((res) => res.data);
}

export function deleteStateOffice(id) {
  return axios.put(STATE_OFFICE_DELETE_URL + "/" + id).then((res) => res.data);
}

//Division office
export function getDivisionOffice() {
  return axios.get(DIVISION_OFFICE_LIST_URL).then((res) => res.data);
}

export function saveDivisionOffice(data) {
  return axios.post(DIVISION_OFFICE_SAVE_URL, data).then((res) => res.data);
}

export function updateDivisionOffice(id, data) {
  return axios
    .put(DIVISION_OFFICE_UPDATE_URL + "/" + id, data)
    .then((res) => res.data);
}

export function deleteDivisionOffice(id) {
  return axios
    .put(DIVISION_OFFICE_DELETE_URL + "/" + id)
    .then((res) => res.data);
}

//Area office
export function getAreaOffice() {
  return axios.get(AREA_OFFICE_LIST_URL).then((res) => res.data);
}

export function saveAreaOffice(data) {
  return axios.post(AREA_OFFICE_SAVE_URL, data).then((res) => res.data);
}

export function updateAreaOffice(id, data) {
  return axios
    .put(AREA_OFFICE_UPDATE_URL + "/" + id, data)
    .then((res) => res.data);
}

export function deleteAreaOffice(id) {
  return axios.put(AREA_OFFICE_DELETE_URL + "/" + id).then((res) => res.data);
}
