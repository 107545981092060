import react, { useState, useEffect } from "react";
import { KTIcon, toAbsoluteUrl } from "../../../_metronic/helpers";
import {
  deleteMaster,
  getMaster,
  saveMaster,
  updateMaster,
} from "./core/_requests";
import { toast } from "react-toastify";
import { PageTitle } from "../../../_metronic/layout/core/PageData";
import { Paginator } from "primereact/paginator";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { getOmc } from "../OMC/core/_request";

const FuelType = () => {
  const [fuelType, setFuelType] = useState<any[]>([]);
  const [pageData, setPageData] = useState<any[]>([]);
  const [omc, setOmc] = useState<any[]>([]);
  const [fuelTypeForSearch, setFuelTypeForSearch] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [selectedOmc, setSelectedOmc] = useState<any[]>([]);
  const [editId, setEditId] = useState("");
  const [search, setSearch] = useState("");
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const fuelTypeList = async () => {
    setLoading(true);
    const response = await getMaster("fuel_type");
    setFuelType(response.output);
    setFuelTypeForSearch(response.output);
    setLoading(false);
  };

  const OmcList = async () => {
    const response = await getOmc();
    setOmc(response.output);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let fuelTypeName = (
      document.getElementById("fuelType_name") as HTMLInputElement
    ).value;
    if (fuelTypeName.length > 0) {
      const response = await saveMaster({
        option_type: "fuel_type",
        option_value: fuelTypeName,
        omc_id: selectedOmc
          ?.map((item: any) => item.id)
          ?.join(",")
          .toString(),
      });
      if (response.status == 200) {
        (document.getElementById("fuelType_name") as HTMLInputElement).value =
          "";
        document.getElementById("atom_fuelType_add_modal_close")?.click();
        fuelTypeList();
        toast.success(response.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(response.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } else {
      toast.warn("Please Enter FuelType name..", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleDelete = async () => {
    const response = await deleteMaster(deleteId);
    if (response.status == 200) {
      setDeleteId("");
      document.getElementById("atom_fuelType_delete_modal_close")?.click();
      fuelTypeList();
      toast.success(response.message, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(response.message, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleUpdate = async (id) => {
    let fuelTypeName = (
      document.getElementById("fuelType_name_update" + id) as HTMLInputElement
    ).value;
    if (fuelTypeName.length > 0) {
      const response = await updateMaster(id, {
        option_type: "fuel_type",
        option_value: fuelTypeName,
        omc_id: selectedOmc
          ?.map((item: any) => item.id)
          ?.join(",")
          .toString(),
      });
      if (response.status == 200) {
        setEditId("");
        fuelTypeList();
        toast.success(response.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        setEditId("");
        toast.error(response.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } else {
      toast.warn("Please Enter FuelType name..", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  useEffect(() => {
    if (search.length > 0) {
      const fuelType = fuelTypeForSearch.filter((p) =>
        p.option_value?.toLowerCase().includes(search)
      );
      setFuelType(fuelType);
    } else {
      setFuelType(fuelTypeForSearch);
    }
  }, [search]);

  useEffect(() => {
    fuelTypeList();
    OmcList();
  }, []);

  useEffect(() => {
    let page = fuelType?.slice(first, first + rows);
    setPageData(page);
  }, [first, rows, fuelType]);

  return (
    <>
      <PageTitle>Business Settings</PageTitle>
      <div
        className="modal fade"
        id="atom_fuelType_add_modal"
        aria-hidden="true"
      >
        <div className="modal-dialog mw-550px">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0 justify-content-between align-items-center">
              <div className="text-center">
                <h3 className="mb-3">Add Fuel Type</h3>
              </div>
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
                id="atom_fuelType_add_modal_close"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
            </div>
            <div className="modal-body scroll-y pb-10 pt-5">
              <form onSubmit={handleSubmit}>
                <div className="mb-8">
                  <Select
                    isMulti
                    options={omc}
                    closeMenuOnSelect={false}
                    components={makeAnimated()}
                    getOptionLabel={(option: any) => option.name}
                    getOptionValue={(option: any) => option.id}
                    value={omc?.filter(
                      (item: any) => selectedOmc?.indexOf(item) !== -1
                    )}
                    classNamePrefix=""
                    className={"w-100 "}
                    onChange={(val: any) => {
                      setSelectedOmc(val);
                    }}
                    placeholder={"select OMC.."}
                  />
                </div>
                <input
                  name="fuelType_name"
                  id="fuelType_name"
                  className="form-control form-control-solid mb-8"
                  placeholder="Enter FuelType Name.."
                />
                <span className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-sm btn-secondary me-3"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-sm btn-primary">
                    Save
                  </button>
                </span>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="atom_fuelType_delete_modal"
        aria-hidden="true"
      >
        <div className="modal-dialog mw-550px">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0 justify-content-between align-items-center">
              <div className="text-center">
                <h3 className="mb-3">Confirmation</h3>
              </div>
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
                id="atom_fuelType_delete_modal_close"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
            </div>
            <div className="modal-body scroll-y pb-10">
              <p>Are you sure want to delete?</p>
              <span className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-sm btn-secondary me-3"
                  data-bs-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="submit"
                  className="btn btn-sm btn-primary"
                  onClick={() => handleDelete()}
                >
                  Yes
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="w-100 h-100">
          <div className="d-flex justify-content-center flex-column align-items-center h-100">
            <div className="spinner-border taskloader" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="card">
          <div className="card-header d-flex align-items-center">
            <h3>Fuel Type</h3>
            <span className="d-flex">
              <span className="position-relative me-3">
                <input
                  onChange={(e) => setSearch(e.target.value?.toLowerCase())}
                  className="form-control form-control-sm form-control-solid mw-200px"
                  placeholder="Search.."
                />
                <i className="bi bi-search fs-3 me-3 position-absolute top-25 end-0"></i>
              </span>
              <button
                className="btn btn-sm btn-primary me-2 text-nowrap"
                data-bs-toggle="modal"
                data-bs-target="#atom_fuelType_add_modal"
                onClick={() => {
                  setSelectedOmc([]);
                  setDeleteId("");
                  setSearch("");
                  setEditId("");
                }}
              >
                <i className="bi bi-plus-lg"></i> Add Fuel Type
              </button>
            </span>
          </div>
          <div className="card-body pt-0">
            <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
              <thead>
                <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                  <th>Sl.No</th>
                  <th>OMC</th>
                  <th>FuelType</th>
                  <th className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody className="text-gray-600 fw-bold">
                {fuelType.length > 0 ? (
                  pageData.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1 + first}</td>
                        <td>
                          {editId == item.id ? (
                            <Select
                              isMulti
                              options={omc}
                              closeMenuOnSelect={false}
                              components={makeAnimated()}
                              getOptionLabel={(option: any) => option.name}
                              getOptionValue={(option: any) => option.id}
                              value={omc?.filter(
                                (item: any) => selectedOmc?.indexOf(item) !== -1
                              )}
                              classNamePrefix=""
                              className={"w-100 "}
                              onChange={(val: any) => {
                                setSelectedOmc(val);
                              }}
                              placeholder={"select OMC.."}
                            />
                          ) : (
                            <span>{item.omc_name?.replace(/,/g, ", ")}</span>
                          )}
                        </td>
                        <td>
                          {editId == item.id ? (
                            <input
                              name="fuelType_name_update"
                              defaultValue={item.option_value}
                              id={"fuelType_name_update" + item.id}
                              className="form-control form-control-solid"
                              placeholder="Enter FuelType Name.."
                            />
                          ) : (
                            <span>{item.option_value}</span>
                          )}
                        </td>
                        <td className="text-center">
                          {editId == item.id ? (
                            <>
                              <button
                                className="btn btn-sm btn-secondary btn-active-color-primary btn-icon me-2"
                                onClick={() => handleUpdate(item.id)}
                              >
                                <i className="bi bi-check-lg fs-3"></i>
                              </button>
                              <button
                                className="btn btn-sm btn-secondary btn-active-color-danger btn-icon me-2"
                                onClick={() => setEditId("")}
                              >
                                <i className="bi bi-x-lg fs-4"></i>
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                className="btn btn-sm btn-secondary btn-active-color-primary btn-icon me-2"
                                onClick={() => {
                                  setEditId(item.id);
                                  setSelectedOmc(
                                    omc?.filter(
                                      (val: any) =>
                                        item.omc_id
                                          ?.split(",")
                                          ?.indexOf(val.id?.toString()) !== -1
                                    )
                                  );
                                }}
                              >
                                <i className="bi bi-pencil-fill"></i>
                              </button>
                              <button
                                className="btn btn-sm btn-secondary btn-active-color-danger btn-icon"
                                data-bs-toggle="modal"
                                data-bs-target="#atom_fuelType_delete_modal"
                                onClick={() => setDeleteId(item.id)}
                              >
                                <i className="bi bi-trash"></i>
                              </button>
                            </>
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className="d-flex text-center w-100 align-content-center justify-content-center">
                        No matching records found
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="table_paginator mt-3 d-flex justify-content-end">
              <Paginator
                first={first}
                rows={rows}
                totalRecords={fuelType.length}
                rowsPerPageOptions={[10, 50, 100, 500, 1000]}
                onPageChange={onPageChange}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FuelType;
