import react, { useState, useEffect } from "react";
import { KTIcon, toAbsoluteUrl } from "../../../_metronic/helpers";
import {
  deleteOmc,
  getOmc,
  getOmcTranslation,
  saveOmc,
  updateOmc,
  updateOmcTranslation,
} from "./core/_request";
import { toast } from "react-toastify";
import { PageTitle } from "../../../_metronic/layout/core/PageData";
import { Paginator } from "primereact/paginator";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";

const Omc = () => {
  const [omc, setOmc] = useState<any[]>([]);
  const [pageData, setPageData] = useState<any[]>([]);
  const [roles, setRoles] = useState<any[]>([]);
  const [states, setStates] = useState<any[]>([]);
  const [OmcForSearch, setOmcForSearch] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [editId, setEditId] = useState<any>({});
  const [transData, setTransData] = useState<any>({});
  const [search, setSearch] = useState("");
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [imgPre, setImgPre] = useState(false);
  const [imagePreview, setImagePreview] = useState<any>(null);
  const [imageFile, setImageFile] = useState(null);

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const OmcList = async () => {
    setLoading(true);
    const response = await getOmc();
    setOmc(response.output);
    setOmcForSearch(response.output);
    setLoading(false);
  };

  const initialValues = {
    name: "",
    shortcode: "",
    logo: "",
    qr_color: "",

    omc_id: "",
    state: "",
    division: "",
    area: "",
    ro_code: "",
    ro_name: "",
  };

  const omcSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    shortcode: Yup.string().required("Name is required"),
  });

  const isValidFileUploaded = (file: any) => {
    const validExtensions = ["png", "jpeg", "jpg"];
    const fileExtension = file.type.split("/")[1];
    return validExtensions.includes(fileExtension);
  };

  const handleImagePreview = (e: any) => {
    if (e.target.files[0].size > 10485760) {
      toast.error("Image size should be below 10MB !", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      (document.getElementById("omcProfileImg") as HTMLInputElement).value = "";
      return;
    } else {
      const file = e.target.files[0];
      if (isValidFileUploaded(file)) {
        let image_as_base64: any = URL.createObjectURL(e.target.files[0]);
        let image_as_files: any = e.target.files[0];
        setImageFile(image_as_files);
        setImagePreview(image_as_base64);
        setImgPre(true);
      } else {
        (document.getElementById("omcProfileImg") as HTMLInputElement).value =
          "";
        toast.error("Image should be .jpg, .jpeg and .png only!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  const imgRemove = () => {
    setTimeout(() => {
      setImageFile(null);
      setImagePreview("");
      setImgPre(false);
      (document.getElementById("omcProfileImg") as HTMLInputElement).value = "";
    }, 500);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: omcSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true);
      try {
        const formData = new FormData();

        formData.append("name", values.name);
        formData.append("shortcode", values.shortcode);
        formData.append("qr_color", values.qr_color);
        imageFile && formData.append("logo", imageFile);
        !imagePreview && formData.append("logo", imageFile!);

        let response;
        if (editId && editId.id != undefined) {
          response = await updateOmc(editId.id, formData);
        } else {
          response = await saveOmc(formData);
        }

        if (response.status == 200) {
          setLoading(false);
          OmcList();
          resetForm();
          setImageFile(null);
          setImagePreview("");
          setImgPre(false);
          document.getElementById("atom_Omc_add_modal_close")?.click();
          toast.success(response.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } catch (error) {
        console.error(error);
        toast.error("Somethig went wrong, Please try after sometimes!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setStatus("Server Error");
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  const formik2 = useFormik({
    initialValues,
    validationSchema: "",
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true);
      try {
        let body = {
          omc_id: transData.id,
          state: values.state,
          division: values.division,
          area: values.area,
          ro_code: values.ro_code,
          ro_name: values.ro_name,
        };

        const response = await updateOmcTranslation(transData.id, body);

        if (response.status == 200) {
          setLoading(false);
          OmcList();
          resetForm();
          document.getElementById("atom_Omc_trans_modal_close")?.click();
          toast.success(response.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } catch (error) {
        console.error(error);
        toast.error("Somethig went wrong, Please try after sometimes!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setStatus("Server Error");
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  const handleDelete = async () => {
    const response = await deleteOmc(deleteId);
    if (response.status == 200) {
      setDeleteId("");
      document.getElementById("atom_Omc_delete_modal_close")?.click();
      OmcList();
      toast.success(response.message, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(response.message, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  useEffect(() => {
    if (editId && editId.id != undefined) {
      document.getElementById("ewrtyweriugw7r543274t2736g32ty3v4h")?.click();
      editId.logo &&
        setImagePreview(
          process.env.REACT_APP_API_URL +
            "/uploads/company/logo/" +
            editId.id +
            "/" +
            editId.logo
        );
      formik.setFieldValue("name", editId.name);
      formik.setFieldValue("shortcode", editId.shortcode);
      formik.setFieldValue("qr_color", editId.qr_color);
    }
  }, [editId]);

  useEffect(() => {
    if (search.length > 0) {
      const omc = OmcForSearch.filter(
        (p) =>
          p.name?.toLowerCase().includes(search) ||
          p.shortcode?.toLowerCase().includes(search)
      );
      setOmc(omc);
    } else {
      setOmc(OmcForSearch);
    }
  }, [search]);

  useEffect(() => {
    OmcList();
  }, []);

  useEffect(() => {
    let page = omc?.slice(first, first + rows);
    setPageData(page);
  }, [first, rows, omc]);

  useEffect(() => {
    if (transData && transData.id != undefined) {
      formik2.setFieldValue("state", transData.state);
      formik2.setFieldValue("division", transData.division);
      formik2.setFieldValue("area", transData.area);
      formik2.setFieldValue("ro_code", transData.ro_code);
      formik2.setFieldValue("ro_name", transData.ro_name);
    }
  }, [transData]);

  useEffect(() => {
    let inputElement = document.getElementById("rkjtgetguerktjhbrtjhebktjerge");
    if (inputElement) {
      inputElement.focus();
    }
  }, []);

  const handleEscKey = (event) => {
    if (event.ctrlKey && event.key === "ArrowLeft") {
      event.preventDefault();
      setFirst((pre) => {
        let val: number;
        if (pre < rows) {
          val = 0;
        } else {
          val = pre - rows;
        }
        return val;
      });
    } else if (event.ctrlKey && event.key === "ArrowRight") {
      event.preventDefault();
      setFirst((pre) => {
        let val: number;
        if (pre + rows > omc.length) {
          val = pre;
        } else {
          val = pre + rows;
        }
        return val;
      });
    } else if (event.ctrlKey && event.key === "s") {
      event.preventDefault();
      let inputElement = document.getElementById("fgkfhdgkdfuhguiyeruithelry");
      if (inputElement) {
        inputElement.focus();
      }
    } else if (event.ctrlKey && event.key === "a") {
      event.preventDefault();
      let inputElement = document.getElementById("elrkhweiurywieurg32liy4truy");
      if (inputElement) {
        inputElement.click();
      }
    } else if (event.key === "Escape") {
      let inputElement = document.querySelector(".modal-open");
      if (inputElement) {
        event.stopPropagation();
        let inputElement: any = document.querySelector(".modal.fade.show");
        inputElement.classList.remove("");
      }
    }
  };

  return (
    <div
      className="focused_div h-100"
      id="rkjtgetguerktjhbrtjhebktjerge"
      onKeyDown={handleEscKey}
      tabIndex={0}
    >
      <PageTitle>Manage OMC</PageTitle>
      <button
        type="button"
        className="d-none"
        data-bs-toggle="modal"
        data-bs-target="#atom_Omc_add_modal"
        id="ewrtyweriugw7r543274t2736g32ty3v4h"
      >
        open
      </button>
      <div
        className="modal fade"
        id="atom_Omc_add_modal"
        aria-hidden="true"
        data-bs-keyboard="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog mw-650px">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0 justify-content-between align-items-center">
              <div className="text-center">
                <h3 className="mb-3">Add OMC</h3>
              </div>
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
                id="atom_Omc_add_modal_close"
                onClick={() => {
                  formik.resetForm();
                  imgRemove();
                  setDeleteId("");
                  setSearch("");
                  setEditId({});
                }}
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
            </div>
            <div className="modal-body scroll-y pb-10 pt-5">
              <form noValidate onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-12 mb-3 ">
                    <div className="d-flex justify-content-center">
                      <label
                        htmlFor={imagePreview ? "" : "image"}
                        className="position-relative"
                      >
                        <input
                          type="file"
                          onChange={(e) => handleImagePreview(e)}
                          id="image"
                          className="form-control d-none"
                        />
                        <div className="prof_img_pre border">
                          {imagePreview ? (
                            <img
                              src={imagePreview}
                              className="profile_image w-100"
                            />
                          ) : (
                            <p className="h-100 w-100 m-0 d-flex justify-content-center align-items-center bg-gray-300 profile_image">
                              LOGO
                            </p>
                          )}
                          {imagePreview && (
                            <a
                              onClick={(e) => imgRemove()}
                              className="icon position-absolute px-1 top-0 end-0 rounded bg-gray border-0 rterterter"
                            >
                              <span className="svg-icon svg-icon-muted">
                                <svg
                                  width=""
                                  height="30"
                                  viewBox="3 3 18 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    opacity="0.3"
                                    x="2"
                                    y="2"
                                    width="20"
                                    height="20"
                                    rx="10"
                                    fill="currentColor"
                                  ></rect>
                                  <rect
                                    x="7"
                                    y="15.3137"
                                    width="12"
                                    height="2"
                                    rx="1"
                                    transform="rotate(-45 7 15.3137)"
                                    fill="currentColor"
                                  ></rect>
                                  <rect
                                    x="8.41422"
                                    y="7"
                                    width="12"
                                    height="2"
                                    rx="1"
                                    transform="rotate(45 8.41422 7)"
                                    fill="currentColor"
                                  ></rect>
                                </svg>
                              </span>
                            </a>
                          )}
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 col-12 mb-3">
                    <label htmlFor="basic-url" className="form-label required">
                      Name
                    </label>
                    <div className="input-group first mb-3 input_prepend">
                      <input
                        type="text"
                        {...formik.getFieldProps("name")}
                        className="form-control"
                        placeholder="Enter your Name.."
                      />
                    </div>
                    {formik.touched.name && formik.errors.name && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert" className="text-danger">
                            {formik.errors.name}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-md-6 col-12 mb-3">
                    <label htmlFor="basic-url" className="form-label">
                      Short Code
                    </label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        {...formik.getFieldProps("shortcode")}
                        className="form-control"
                        placeholder="Enter your shortcode"
                      />
                    </div>
                    {formik.touched.shortcode && formik.errors.shortcode && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert" className="text-danger">
                            {formik.errors.shortcode}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-md-6 col-12 mb-3">
                    <label htmlFor="basic-url" className="form-label">
                      QR Color code
                    </label>
                    <div className="input-group mb-3">
                      <input
                        type="color"
                        {...formik.getFieldProps("qr_color")}
                        className="form-control p-0"
                        placeholder="Enter your qr_color"
                      />
                    </div>
                    {formik.touched.qr_color && formik.errors.qr_color && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert" className="text-danger">
                            {formik.errors.qr_color}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <span className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-sm btn-secondary me-3"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      formik.resetForm();
                      imgRemove();
                      setDeleteId("");
                      setSearch("");
                      setEditId({});
                    }}
                  >
                    Discard
                  </button>
                  <button type="submit" className="btn btn-sm btn-primary">
                    Save
                  </button>
                </span>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="atom_Omc_delete_modal" aria-hidden="true">
        <div className="modal-dialog mw-550px">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0 justify-content-between align-items-center">
              <div className="text-center">
                <h3 className="mb-3">Confirmation</h3>
              </div>
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
                id="atom_Omc_delete_modal_close"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
            </div>
            <div className="modal-body scroll-y pb-10">
              <p>Are you sure want to delete?</p>
              <span className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-sm btn-secondary me-3"
                  data-bs-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="submit"
                  className="btn btn-sm btn-primary"
                  onClick={() => handleDelete()}
                >
                  Yes
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="atom_Omc_RO_modal"
        aria-hidden="true"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog mw-550px">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0 justify-content-between align-items-center">
              <div className="text-center">
                <h3 className="mb-3">OMC Translation</h3>
              </div>
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
                id="atom_Omc_trans_modal_close"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
            </div>
            <div className="modal-body scroll-y pb-10">
              <form noValidate onSubmit={formik2.handleSubmit}>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-bold fs-6">
                    State
                  </label>
                  <div className="col-lg-8 fv-row">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Enter state translation.."
                      {...formik2.getFieldProps("state")}
                    />
                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-bold fs-6">
                    Division
                  </label>
                  <div className="col-lg-8 fv-row">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Enter division translation.."
                      {...formik2.getFieldProps("division")}
                    />
                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-bold fs-6">
                    Area
                  </label>
                  <div className="col-lg-8 fv-row">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Enter Area name.."
                      {...formik2.getFieldProps("area")}
                    />
                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-bold fs-6">
                    RO Code
                  </label>
                  <div className="col-lg-8 fv-row">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Enter RO code.."
                      {...formik2.getFieldProps("ro_code")}
                    />
                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-bold fs-6">
                    RO Name
                  </label>
                  <div className="col-lg-8 fv-row">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Enter RO name.."
                      {...formik2.getFieldProps("ro_name")}
                    />
                  </div>
                </div>
                <span className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-sm btn-secondary me-3"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      formik2.resetForm();
                    }}
                  >
                    Discard
                  </button>
                  <button type="submit" className="btn btn-sm btn-primary">
                    Save
                  </button>
                </span>
              </form>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="w-100 h-100">
          <div className="d-flex justify-content-center flex-column align-items-center h-100">
            <div className="spinner-border taskloader" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="card">
          <div className="card-header d-flex align-items-center mobile-padding">
            <h3 className="mb-sm-0 mb-3">OMC</h3>
            <span className="d-flex">
              <span className="position-relative me-3">
                <input
                  onChange={(e) => setSearch(e.target.value?.toLowerCase())}
                  className="form-control form-control-sm form-control-solid mw-200px"
                  placeholder="Search.. (ctrl+s)"
                  id="fgkfhdgkdfuhguiyeruithelry"
                />
                <i className="bi bi-search fs-3 me-3 position-absolute top-25 end-0"></i>
              </span>
              <button
                className="btn btn-sm btn-primary me-2 text-nowrap"
                data-bs-toggle="modal"
                data-bs-target="#atom_Omc_add_modal"
                id="elrkhweiurywieurg32liy4truy"
                onClick={() => {
                  formik.resetForm();
                  imgRemove();
                  setDeleteId("");
                  setSearch("");
                  setEditId({});
                }}
              >
                <i className="bi bi-plus-lg"></i> Add OMC
              </button>
            </span>
          </div>
          <div className="card-body pt-0 ">
            <div className="overflow-x-auto">
              <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                <thead>
                  <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                    <th>Sl.No</th>
                    <th>Logo</th>
                    <th>Omc Name</th>
                    {/* <th className='text-center'>Level 1</th>
                        <th className='text-center'>Level 2</th>
                        <th className='text-center'>Level 3</th>
                        <th className='text-center'>RO</th> */}
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody className="text-gray-600 fw-bold">
                  {omc.length > 0 ? (
                    pageData.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1 + first}</td>
                          <td className="py-1">
                            <div className="prof_img_pre_list border">
                              {item.logo ? (
                                <img
                                  src={
                                    process.env.REACT_APP_API_URL +
                                    "/uploads/company/logo/" +
                                    item.id +
                                    "/" +
                                    item.logo
                                  }
                                  className="profile_image w-100"
                                />
                              ) : (
                                <p className="h-100 d-flex justify-content-center align-items-center bg-gray-300">
                                  LOGO
                                </p>
                              )}
                            </div>
                          </td>
                          <td className="d-flex flex-column">
                            <span>{item.name}</span>
                            <small>#{item.shortcode}</small>
                          </td>
                          {/* <td className='text-center text-nowrap'> 
                            <Link to={'stateOffice?omc_id='+item.id} className='btn btn-sm btn-secondary w-100'>
                                {item.state_translation || "Level 1"}
                            </Link>
                        </td>
                        <td className='text-center text-nowrap'>                            
                            <Link to={'divisionOffice?omc_id='+item.id} className='btn btn-sm btn-secondary w-100'>
                                {item.division_translation || "Level 2"}
                            </Link>  
                        </td>
                        <td className='text-center text-nowrap'>                          
                            <Link to={'officeArea?omc_id='+item.id} className='btn btn-sm btn-secondary w-100'>
                                {item.area_translation || "Level 3"}
                            </Link> 
                        </td>
                        <td className='text-center text-nowrap'>                          
                            <Link to={'manageRO?omc_id='+item.id} className='btn btn-sm btn-secondary w-100'>
                                {item.ro_name_translation || "RO"}
                            </Link> 
                        </td> */}
                          <td className="text-center text-nowrap">
                            <button
                              className="btn btn-sm btn-secondary btn-active-color-primary btn-icon me-2"
                              onClick={() => {
                                setEditId(item);
                              }}
                            >
                              <i className="bi bi-pencil-fill"></i>
                            </button>
                            <button
                              className="btn btn-sm btn-secondary btn-active-color-danger btn-icon me-2"
                              data-bs-toggle="modal"
                              data-bs-target="#atom_Omc_delete_modal"
                              onClick={() => setDeleteId(item.id)}
                            >
                              <i className="bi bi-trash"></i>
                            </button>
                            {/* <button className='btn btn-sm btn-secondary btn-active-color-warning btn-icon' data-bs-toggle='modal' data-bs-target='#atom_Omc_RO_modal' onClick={async() => {
                                const response = await getOmcTranslation(item.id)
                                if(response.status == 200) {
                                    setTransData(response.output[0]);
                                }
                            }}>
                                <i className="bi bi-file-earmark-medical"></i>
                            </button> */}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={7}>
                        <div className="d-flex text-center w-100 align-content-center justify-content-center">
                          No matching records found
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="table_paginator mt-3 d-flex justify-content-end">
              <Paginator
                first={first}
                rows={rows}
                totalRecords={omc.length}
                rowsPerPageOptions={[10, 50, 100, 500, 1000]}
                onPageChange={onPageChange}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Omc;
