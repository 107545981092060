import react, { useState, useEffect } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import { getCampaign } from "./core/_requests";

const PDFViewer = () => {
  const queryParams = new URLSearchParams(window.location.search);
  // const campaignId = 1;
  const campaignId = queryParams.get("cid");

  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [loading, setLoading] = useState(false);
  const [campaign, setCampaign] = useState<any>({});

  // pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  //     'pdfjs-dist/build/pdf.worker.entry',
  //     new URL(import.meta.url).origin
  //   ).toString();

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }
  console.log("rjkbwergirgweurwekrhgv", campaign);

  const CampaignList = async () => {
    setLoading(true);
    const response = await getCampaign();
    setCampaign(
      response.output?.find(
        (item: any) => item.id?.toString() == campaignId?.toString()
      )
    );
    setLoading(false);
  };

  useEffect(() => {
    CampaignList();
  }, []);

  return (
    <div>
      <div className="screen_height">
        {
          loading ? (
            <div className="w-100 h-100">
              <div className="d-flex justify-content-center flex-column align-items-center h-100">
                <div className="spinner-border taskloader" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-100">
              <h2 className="termsPageHeader text-center my-5">
                {campaign.name}
              </h2>
              <div className="w-100 pdf-width-height">
                <Document
                  file={
                    process.env.REACT_APP_API_URL +
                    "/uploads/campaign/pdf/" +
                    campaign.id +
                    "/" +
                    campaign.terms_conditions
                  }
                  className="w-100"
                  renderMode="canvas"
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page
                    className="d-flex justify-content-center"
                    pageNumber={pageNumber}
                    width={450}
                    height={650}
                  />
                </Document>
              </div>
              <div className="d-flex align-items-center justify-content-end mt-4 me-5">
                <button
                  className="btn btn-sm btn-secondary btn-active-color-success btn-icon"
                  onClick={() => {
                    if (pageNumber > 1) {
                      setPageNumber(pageNumber - 1);
                    }
                  }}
                >
                  <i className="bi bi-caret-left"></i>
                </button>
                <p className="my-0 mx-3">
                  Page {pageNumber} of {numPages}
                </p>
                <button
                  className="btn btn-sm btn-secondary btn-active-color-success btn-icon"
                  onClick={() => {
                    if (pageNumber < numPages) {
                      setPageNumber(pageNumber + 1);
                    }
                  }}
                >
                  <i className="bi bi-caret-right"></i>
                </button>
              </div>
            </div>
          )
          // <>{campaign?.terms_conditions != undefined &&
          // <iframe id="pdfviewer" src={`http://docs.google.com/gview?embedded=true&url=${process.env.REACT_APP_API_URL+'/uploads/campaign/pdf/'+campaign.id+'/'+campaign.terms_conditions}`} frameBorder={0} width="100%" height="100%"></iframe>}</>
          // <iframe src={process.env.REACT_APP_API_URL+'/uploads/campaign/pdf/'+campaign.id+'/'+campaign.terms_conditions} width="100%" height="100%"></iframe>
          // <object data={process.env.REACT_APP_API_URL+'/uploads/campaign/pdf/'+campaign.id+'/'+campaign.terms_conditions} type="application/pdf" width="100%" height="100%">
          //     <p>Your browser does not support PDF viewing. You can download the PDF file <a href={process.env.REACT_APP_API_URL+'/uploads/campaign/pdf/'+campaign.id+'/'+campaign.terms_conditions}>here</a>.</p>
          // </object>
        }

        {/* <h3>Registered RO List</h3> */}
      </div>
      <div className="footer footer-fixed py-4">
        <div className="d-sm-block d-none">
          <div className="container-fluid d-flex align-items-center justify-content-center">
            <div className="text-dark order-2 order-md-1">
              <span className="text-muted fw-bold me-2">2023 ©</span>
              <a href="#" className="text-gray-800 text-hover-primary">
                The Atom |{" "}
                <small className="text-gray-500">Technical Partner</small> @
                Vriksha Techno Solutions Pvt Ltd
              </a>
            </div>
          </div>
        </div>

        <div className="d-sm-none d-block">
          <div className="container-fluid d-flex flex-column align-items-center justify-content-center">
            <div className="text-dark">
              <span className="text-muted fw-bold me-2">2023 ©</span>
              <a href="#" className="text-gray-800 text-hover-primary">
                The Atom{" "}
              </a>
            </div>
            <div className="text-dark">
              <a href="#" className="text-dark">
                <small className="text-gray-500">Technical Partner</small> @
                Vriksha Techno Solutions Pvt Ltd
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PDFViewer;
