import react, { useState, useEffect, useRef } from "react";
import { getCampaignById, getRoByCampaign } from "./core/_requests";
import { PageTitle } from "../../../_metronic/layout/core/PageData";
import { Paginator } from "primereact/paginator";
import moment from "moment";
import { QRCode } from "react-qrcode-logo";
import { useReactToPrint } from "react-to-print";
import html2canvas from "html2canvas";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import {
  getAreaOffice,
  getDivisionOffice,
  getStateOffice,
} from "../OMC/core/_request";
import FocusTrap from "focus-trap-react";

const CampaignRoList = () => {
  const [campaign, setCampaign] = useState<any[]>([]);
  const [pageData, setPageData] = useState<any[]>([]);
  const [CampaignForSearch, setCampaignForSearch] = useState<any[]>([]);
  const [states, setStates] = useState<any[]>([]);
  const [divisions, setDivisions] = useState<any[]>([]);
  const [areas, setAreas] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentCampaign, setCurrentCampaign] = useState<any>({});
  const [qRCode, setQRCode] = useState<any>({});
  const [search, setSearch] = useState("");
  const [stateId, setstateId] = useState("");
  const [roId, setRoId] = useState("");
  const [divId, setDivId] = useState("");
  const [areaId, setAreaId] = useState("");
  const [vTId, setVTId] = useState("");
  const [fTId, setFTId] = useState("");
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [qrList, setQrList] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const campaignId = queryParams.get("cid");

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const campaignList = async () => {
    setLoading(true);
    const response = await getRoByCampaign(
      campaignId,
      stateId,
      roId,
      divId,
      areaId
    );
    setCampaign(response.output);
    setCampaignForSearch(response.output);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const currentCampaigns = async () => {
    const response = await getCampaignById(campaignId);
    setCurrentCampaign(response.output[0]);
  };

  const statesList = async () => {
    const response = await getStateOffice();
    setStates(
      response.output?.filter(
        (item: any) =>
          currentCampaign?.state?.split(",").indexOf(item.id?.toString()) !== -1
      )
    );
  };

  const divisionList = async () => {
    const response = await getDivisionOffice();
    setDivisions(
      response.output?.filter(
        (item: any) =>
          currentCampaign?.division?.split(",").indexOf(item.id?.toString()) !==
          -1
      )
    );
  };

  const areaList = async () => {
    const response = await getAreaOffice();
    setAreas(
      response.output?.filter(
        (item: any) =>
          currentCampaign?.area?.split(",").indexOf(item.id?.toString()) !== -1
      )
    );
  };

  useEffect(() => {
    if (search.length > 0) {
      const campaign = CampaignForSearch.filter(
        (p) =>
          p.name?.toLowerCase().includes(search) ||
          p.email?.toLowerCase().includes(search) ||
          p.mobile?.toString().toLowerCase().includes(search) ||
          p.vehicle_number?.toLowerCase().includes(search) ||
          p.fuel_type_name?.toLowerCase().includes(search) ||
          p.vehicle_type_name?.toLowerCase().includes(search) ||
          p.state_name?.toLowerCase().includes(search) ||
          p.division_name?.toLowerCase().includes(search) ||
          p.area_name?.toLowerCase().includes(search) ||
          p.ro_name?.toLowerCase().includes(search) ||
          p.sap_code?.toLowerCase().includes(search)
      );
      setCampaign(campaign);
    } else {
      setCampaign(CampaignForSearch);
    }
  }, [search]);

  useEffect(() => {
    if (stateId || roId || divId || areaId || vTId || fTId) {
      campaignList();
    }
  }, [stateId, roId, divId, areaId, vTId, fTId]);

  useEffect(() => {
    setLoading(true);
    campaignList();
    currentCampaigns();
  }, []);

  useEffect(() => {
    if (currentCampaign.id) {
      areaList();
      divisionList();
      statesList();
    }
  }, [currentCampaign.id]);

  useEffect(() => {
    let page = campaign?.slice(first, first + rows);
    setPageData(page);
  }, [first, rows, campaign]);

  const jebiuwgri: any = useRef();
  const handlePrint = useReactToPrint({
    content: () => jebiuwgri.current,
  });

  const handleExportImage = () => {
    html2canvas(
      document.getElementById("werljhwiueriyweriuweriuy") as HTMLElement
    ).then(function (canvas) {
      console.log("jerohweiurwoeriuwer", canvas);
      const ctx = canvas?.getContext("2d");
      if (ctx) {
        const dataUrl = canvas?.toDataURL("image/png");
        const downloadLink = document.createElement("a");
        downloadLink.href = dataUrl;
        downloadLink.download = `${qRCode.ro_name}(${qRCode.sap_code}).png`;
        downloadLink.click();
      }
    });
  };

  async function createZip() {
    const zip = new JSZip();
    const eurwuergwe: any = [];

    campaign.forEach((lfh, i) => {
      html2canvas(
        document.getElementById(
          "multipleQRCodeDownloadInZip" + i
        ) as HTMLElement
      ).then(function (canvas) {
        console.log("wtjhtgetuguihrtiubegd", lfh);

        const ctx = canvas?.getContext("2d");
        if (ctx) {
          const dataUrl = canvas?.toDataURL("image/png");
          // const downloadLink = document.createElement('a');
          // downloadLink.href = dataUrl;
          // downloadLink.download = `${lfh.ro_name}(${lfh.sap_code}).png`;
          // downloadLink.click();
          // const imageData = dataUrl?.replace(/^data:image\/png;base64,/, '');
          // const werwerwe = base64ToFile(dataUrl, 'fileName.png', 'image/x-png');
          // img?.file(`image${i + 1}.png`, dataUrl?.split(',')[1], { base64: true });
          // setQrList(false);

          const byteCharacters = atob(dataUrl.split(",")[1]);

          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const rtjhetg = new Blob([byteArray], { type: "image/png" });

          // const treterte = new Blob([atob(dataUrl.split(',')[1])], { type: 'application/octet-stream' });

          // const file = new File([treterte], 'image.png', { type: 'image/png' });
          // img?.file;
          zip?.file(`${lfh.ro_name}.png`, rtjhetg);
          // eurwuergwe.push(file);
          // saveAs(dataUrl, 'files.jpg');
          // eurwuergwe?.push(file);
          // console.log("erjwergwurweurgwejrb", file);
          setQrList(false);
        }
      });
    });
    setTimeout(() => {
      zip?.generateAsync({ type: "blob" }).then((blob) => {
        saveAs(blob, `${campaign[0].area_name}.zip`);
      });
    }, campaign.length * 1000);
  }

  useEffect(() => {
    let inputElement = document.getElementById("jeriueryuiwergefrhwgervjhtfd");
    if (inputElement) {
      inputElement.focus();
    }
  }, []);

  const handleEscKey = (event) => {
    if (event.ctrlKey && event.key === "ArrowLeft") {
      event.preventDefault();
      setFirst((pre) => {
        let val: number;
        if (pre < rows) {
          val = 0;
        } else {
          val = pre - rows;
        }
        return val;
      });
    } else if (event.ctrlKey && event.key === "ArrowRight") {
      event.preventDefault();
      setFirst((pre) => {
        let val: number;
        if (pre + rows > campaign.length) {
          val = pre;
        } else {
          val = pre + rows;
        }
        return val;
      });
    } else if (event.ctrlKey && event.key === "s") {
      event.preventDefault();
      let inputElement = document.getElementById(
        "eertlmrjegthbtjhertjhbrergfbdf"
      );
      if (inputElement) {
        inputElement.focus();
      }
    }
  };

  return (
    <div
      className="focused_div h-100"
      id="jeriueryuiwergefrhwgervjhtfd"
      onKeyDown={handleEscKey}
      tabIndex={0}
    >
      <PageTitle>Manage Campaign</PageTitle>
      <div
        className="modal fade"
        id="atom_Campaign_poster_qr_modal"
        aria-hidden="true"
      >
        <div className="modal-dialog h-100 w-100 m-0 mx-sm-auto">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0 justify-content-between align-items-center">
              <div className="text-center">
                <h3 className="mb-3">Campaign QR</h3>
              </div>
              <span>
                <button
                  className="btn btn-sm btn-secondary btn-active-color-primary btn-icon me-2"
                  onClick={handlePrint}
                >
                  <i className="bi bi-printer"></i>
                </button>
                <button
                  className="btn btn-sm btn-secondary btn-active-color-primary btn-icon me-2"
                  onClick={handleExportImage}
                >
                  <i className="bi bi-cloud-download"></i>
                </button>
              </span>
            </div>
            <div className="modal-body mh-650px" id="werljhwiueriyweriuweriuy">
              <h2 className="mb-2 fs-1 text-center">{qRCode.ro_name}</h2>
              <p className="mb-7 fs-5 text-center"># {qRCode.sap_code}</p>
              <QRCode
                value={
                  process.env.REACT_APP_FRONT_URL +
                  "campaigns?cid=" +
                  qRCode.campaign_id +
                  "&rid=" +
                  qRCode.id
                }
                logoImage={
                  process.env.REACT_APP_API_URL +
                  "/uploads/company/logo/" +
                  qRCode.omc_id +
                  "/" +
                  qRCode.omc_logo
                }
                removeQrCodeBehindLogo={true}
                logoPaddingStyle="circle"
                size={1000}
                qrStyle="dots"
                eyeRadius={25}
                logoWidth={300}
                eyeColor={qRCode.omc_qr_color}
                enableCORS={true}
              />
              <div className="d-none">
                <div
                  className="qr_container d-flex flex-column align-items-center justify-content-center"
                  ref={jebiuwgri}
                >
                  <h2 className="mb-2 fs-1">{qRCode.ro_name}</h2>
                  <p className="mb-7 fs-5"># {qRCode.sap_code}</p>
                  <div className="print_qr">
                    <QRCode
                      value={
                        process.env.REACT_APP_FRONT_URL +
                        "campaigns?cid=" +
                        qRCode.campaign_id +
                        "&rid=" +
                        qRCode.id
                      }
                      logoImage={
                        process.env.REACT_APP_API_URL +
                        "/uploads/company/logo/" +
                        qRCode.omc_id +
                        "/" +
                        qRCode.omc_logo
                      }
                      removeQrCodeBehindLogo={true}
                      logoPaddingStyle="circle"
                      size={1300}
                      qrStyle="dots"
                      eyeRadius={25}
                      logoWidth={300}
                      eyeColor={qRCode.omc_qr_color}
                      enableCORS={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="w-100 h-100">
          <div className="d-flex justify-content-center flex-column align-items-center h-100">
            <div className="spinner-border taskloader" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="card">
          <div className="card-header d-flex align-items-center">
            <div className="d-flex align-items-center">
              <div className="prof_img_pre_list border me-3">
                {currentCampaign.omc_logo ? (
                  <img
                    src={
                      process.env.REACT_APP_API_URL +
                      "/uploads/company/logo/" +
                      currentCampaign.omc_id +
                      "/" +
                      currentCampaign.omc_logo
                    }
                    className="profile_image w-100"
                  />
                ) : (
                  <p className="h-100 d-flex justify-content-center align-items-center bg-gray-300">
                    LOGO
                  </p>
                )}
              </div>
              <div className="d-flex flex-column">
                <h3>{currentCampaign.name}</h3>
                <small>
                  {moment(currentCampaign.start_date).format("DD/MM/YYYY")} -{" "}
                  {moment(currentCampaign.end_date).format("DD/MM/YYYY")}
                </small>
              </div>
            </div>
            <span className="d-flex">
              <span className="position-relative me-3">
                <input
                  onChange={(e) => setSearch(e.target.value?.toLowerCase())}
                  className="form-control form-control-sm form-control-solid mw-200px"
                  placeholder="Search.. (ctrl+s)"
                  id="eertlmrjegthbtjhertjhbrergfbdf"
                />
                <i className="bi bi-search fs-3 me-3 position-absolute top-25 end-0"></i>
              </span>
              {(search ||
                stateId ||
                roId ||
                areaId ||
                divId ||
                vTId ||
                fTId) && (
                <button
                  className="btn btn-sm btn-secondary btn-active-color-info btn-icon me-2"
                  data-bs-toggle="modal"
                  data-bs-target="#atom_Campaign_poster_modal"
                  onClick={() => {
                    campaignList();
                    setSearch("");
                    setstateId("");
                    setRoId("");
                    setAreaId("");
                    setDivId("");
                    setVTId("");
                    setFTId("");
                    (
                      document.getElementById(
                        "eertlmrjegthbtjhertjhbrergfbdf"
                      ) as HTMLInputElement
                    ).value = "";
                    (
                      document.getElementById(
                        "fdgdgdfgdfgerthbwgerhertwbtjertg"
                      ) as HTMLInputElement
                    ).value = "";
                    (
                      document.getElementById(
                        "erkjbcburtb4uyr3i4gu34u3y5t3"
                      ) as HTMLInputElement
                    ).value = "";
                    (
                      document.getElementById(
                        "etkjethuithuthjebtiuerto8ty458y5"
                      ) as HTMLInputElement
                    ).value = "";
                    (
                      document.getElementById(
                        "etkertheruitiertutiyu4ygitgubergd456"
                      ) as HTMLInputElement
                    ).value = "";
                    (
                      document.getElementById(
                        "yjthebthebtuyegtuy4gtug5th45btjb"
                      ) as HTMLInputElement
                    ).value = "";
                    (
                      document.getElementById(
                        "eterjhtrevrjtyutygertit74t5ygtu4y5tiu"
                      ) as HTMLInputElement
                    ).value = "";
                  }}
                >
                  <i className="bi bi-arrow-clockwise"></i>
                </button>
              )}
              {pageData.length > 0 && areaId != "" && (
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={() => {
                    setQrList(true);
                    setTimeout(() => {
                      createZip();
                    }, 500);
                  }}
                >
                  {/* {!qrList ?  : 
                    <div className="spinner-border taskloader" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>} */}
                  {qrList ? (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      please_wait...{" "}
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  ) : (
                    <span>
                      <i className="bi bi-qr-code-scan"></i> Download
                    </span>
                  )}
                </button>
              )}
            </span>
          </div>
          <div className="card-body pt-0">
            <div className="overflow-x-auto">
              <table
                className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                id="ertieute8utyiuertiuert"
              >
                <thead>
                  <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                    <th>Sl.No</th>
                    <th style={{ minWidth: "200px" }}>RO Name</th>
                    <th style={{ minWidth: "150px" }} className="p-0">
                      <div className="d-flex align-items-center">
                        <select
                          className="form-select border-0 text-muted fw-bolder fs-7 text-uppercase gs-0 mb-2"
                          id="etkjethuithuthjebtiuerto8ty458y5"
                          value={stateId}
                          onChange={(e) => {
                            setstateId(e.target.value);
                            setDivId("");
                            setAreaId("");
                          }}
                        >
                          <option value="">State</option>
                          {states.map((item, i) => {
                            return (
                              <option value={item.id} key={i}>
                                {item.state}
                              </option>
                            );
                          })}
                        </select>
                        {/* State */}
                      </div>
                    </th>
                    <th style={{ minWidth: "150px" }} className="p-0">
                      <div className="d-flex align-items-center">
                        <select
                          className="form-select border-0 text-muted fw-bolder fs-7 text-uppercase gs-0 mb-2"
                          id="etkertheruitiertutiyu4ygitgubergd456"
                          value={divId}
                          onChange={(e) => {
                            setDivId(e.target.value);
                            setAreaId("");
                          }}
                        >
                          <option value="">
                            {divisions?.find(
                              (item: any) =>
                                item.omc_id?.toString() ==
                                currentCampaign?.omc_id?.toString()
                            )?.type || "Retail"}
                          </option>
                          {divisions.map((item, i) => {
                            if (stateId) {
                              if (stateId == item.state?.toString()) {
                                return (
                                  <option value={item.id} key={i}>
                                    {item.division}
                                  </option>
                                );
                              }
                            } else {
                              return (
                                <option value={item.id} key={i}>
                                  {item.division}
                                </option>
                              );
                            }
                          })}
                        </select>
                        {/* Retail */}
                      </div>
                    </th>
                    <th style={{ minWidth: "150px" }} className="p-0">
                      <div className="d-flex align-items-center">
                        <select
                          className="form-select border-0 text-muted fw-bolder fs-7 text-uppercase gs-0 mb-2"
                          id="yjthebthebtuyegtuy4gtug5th45btjb"
                          value={areaId}
                          onChange={(e) => setAreaId(e.target.value)}
                        >
                          <option value="">
                            {areas?.find(
                              (item: any) =>
                                item.omc_id?.toString() ==
                                currentCampaign?.omc_id?.toString()
                            )?.type || "Area"}
                          </option>
                          {areas.map((item, i) => {
                            if (stateId && divId) {
                              if (divId == item.division?.toString()) {
                                return (
                                  <option value={item.id} key={i}>
                                    {item.city}
                                  </option>
                                );
                              }
                            } else if (!stateId && divId) {
                              if (divId == item.division?.toString()) {
                                return (
                                  <option value={item.id} key={i}>
                                    {item.city}
                                  </option>
                                );
                              }
                            } else if (stateId && !divId) {
                              if (stateId == item.state?.toString()) {
                                return (
                                  <option value={item.id} key={i}>
                                    {item.city}
                                  </option>
                                );
                              }
                            } else {
                              return (
                                <option value={item.id} key={i}>
                                  {item.city}
                                </option>
                              );
                            }
                          })}
                        </select>
                        {/* Area */}
                      </div>
                    </th>
                    <th style={{ minWidth: "200px" }}>Created At</th>
                    <th>QR</th>
                  </tr>
                </thead>
                <tbody className="text-gray-600 fw-bold">
                  {campaign.length > 0 ? (
                    pageData.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1 + first}</td>
                          <td className="py-1">
                            <div className="d-flex align-items-center">
                              <div className="d-flex flex-column">
                                <span>{item.ro_name}</span>
                                <small># {item.sap_code}</small>
                              </div>
                            </div>
                          </td>
                          <td>{item.state_name}</td>
                          <td>{item.division_name}</td>
                          <td>{item.area_name}</td>
                          <td className="py-1">
                            <div className="d-flex align-items-center">
                              <div className="d-flex flex-column">
                                <span>
                                  {moment(item.created_at).format("DD-MM-YYYY")}
                                </span>
                                <small>
                                  {moment(item.created_at).format("hh:mm a")}
                                </small>
                              </div>
                            </div>
                          </td>
                          <td className="text-center text-nowrap">
                            <button
                              className="btn btn-sm btn-secondary btn-active-color-primary btn-icon me-2"
                              data-bs-toggle="modal"
                              data-bs-target="#atom_Campaign_poster_qr_modal"
                              onClick={() => {
                                setQRCode(item);
                              }}
                            >
                              <i className="bi bi-qr-code-scan"></i>
                            </button>
                          </td>
                          {/* <td className='text-center text-nowrap'>
                                {moment(item.end_date) < moment() ?
                                <Link to={"winnerPage?cid="++item.id} className='btn btn-sm btn-success me-2' title='more'>
                                    SELECT WINNER
                                </Link> : 
                                <p className='text-secondary text-wrap disabled me-2'>
                                    Campaign not completed yet!
                                </p>}
                            </td> */}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={7}>
                        <div className="d-flex text-center w-100 align-content-center justify-content-center">
                          No matching records found
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="table_paginator mt-3 d-flex justify-content-end">
              <Paginator
                first={first}
                rows={rows}
                totalRecords={campaign.length}
                rowsPerPageOptions={[10, 50, 100, 500, 1000]}
                onPageChange={onPageChange}
              />
            </div>
          </div>
        </div>
      )}
      <div className={qrList ? "fade" : "d-none"}>
        {campaign?.map((data, i) => {
          return (
            <div className="h-100 w-100" key={i}>
              <div
                className="qr_container d-flex flex-column align-items-center justify-content-center mh-600px mw-500px"
                id={"multipleQRCodeDownloadInZip" + i}
              >
                <h2 className="mb-2 fs-1">{data.ro_name}</h2>
                <p className="mb-7 fs-5"># {data.sap_code}</p>
                <div className="print_qr">
                  <QRCode
                    value={
                      process.env.REACT_APP_FRONT_URL +
                      "campaigns?cid=" +
                      data.campaign_id +
                      "&rid=" +
                      data.id
                    }
                    logoImage={
                      process.env.REACT_APP_API_URL +
                      "/uploads/company/logo/" +
                      data.omc_id +
                      "/" +
                      data.omc_logo
                    }
                    removeQrCodeBehindLogo={true}
                    logoPaddingStyle="circle"
                    size={1300}
                    qrStyle="dots"
                    eyeRadius={25}
                    logoWidth={300}
                    eyeColor={data.omc_qr_color}
                    enableCORS={true}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CampaignRoList;
