import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import {
  ListsWidget1,
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget5,
  ListsWidget6,
  MixedWidget10,
  MixedWidget11,
  MixedWidget2,
  StatisticsWidget5,
  TablesWidget10,
  TablesWidget5,
} from "../../../_metronic/partials/widgets";
import { QRcodeGenerator } from "../../../_metronic/helpers/components/QRgenerator";
import ConstructionPage from "../../modules/errors/underConstruction";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { getDashboardCounts, getWinnersCampaign } from "./core/_requests";
import { getDashboardCount } from "../../../_metronic/partials/widgets/mixed/core/_requests";

const DashboardPage = () => (
  <>
    <div className="row">
      <div className="col-sm-3">
        <div className="card">OMC # 8</div>
      </div>
    </div>
    {/* <QRcodeGenerator /> */}
    {/* <ConstructionPage /> */}
    {/* begin::Row */}
    <div className="row g-5 g-xl-8">
      {/* <div className='col-xl-4'>
        <StatisticsWidget5
          className='card-xl-stretch mb-xl-8'
          svgIcon='basket'
          color='body-white'
          iconColor='primary'
          title='Shopping Cart'
          description='Lands, Houses, Ranchos, Farms'
          titleColor='gray-900'
          descriptionColor='gray-400'
        />
      </div> */}

      {/* <div className='col-xl-4'>
        <StatisticsWidget5
          className='card-xl-stretch mb-xl-8'
          svgIcon='element-11'
          color='primary'
          iconColor='white'
          title='Appartments'
          description='Flats, Shared Rooms, Duplex'
          titleColor='white'
          descriptionColor='white'
        />
      </div> */}

      {/* <div className='col-xl-4'>
        <StatisticsWidget5
          className='card-xl-stretch mb-5 mb-xl-8'
          svgIcon='left'
          color='dark'
          iconColor='gray-100'
          title='Sales Stats'
          description='50% Increased for FY20'
          titleColor='gray-100'
          descriptionColor='gray-100'
        />
      </div> */}
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className="row g-5 g-xl-8">
      {/* begin::Col */}
      {/* <div className='col-xl-4'>
        <ListsWidget1 className='card-xl-stretch mb-xl-8' />
      </div> */}
      {/* end::Col */}

      {/* begin::Col */}
      {/* <div className='col-xl-8'>
        <TablesWidget5 className='card-xl-stretch mb-5 mb-xl-8' />
      </div> */}
      {/* end::Col */}
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className="row gy-5 g-xl-8">
      {/* <div className='col-xxl-4'>
        <MixedWidget2
          className='card-xl-stretch mb-xl-8'
          chartColor='danger'
          chartHeight='200px'
          strokeColor='#cb1e46'
        />
      </div> */}
      {/* <div className='col-xxl-4'>
        <ListsWidget5 className='card-xxl-stretch' />
      </div> */}
      <div className="mt-10 mt-md-15 mt-xl-20">
        {/* <MixedWidget10
          className='card-xxl-stretch-50 mb-5 mb-xl-8'
          chartColor='primary'
          chartHeight='150px'
        /> */}
        <MixedWidget11
          className="mb-2 mt-2 my-md-5 my-xl-8"
          chartColor="primary"
          chartHeight="600px"
        />
      </div>
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className="row gy-5 gx-xl-8">
      {/* <div className=''>
        <ListsWidget3 className='mb-xl-3' />
      </div> */}
      {/* <div className='col-xl-8'>
        <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
      </div> */}
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className="row gy-5 g-xl-8">
      {/* <div className='col-xl-4'>
        <ListsWidget2 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget6 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
      </div> */}
    </div>
    {/* end::Row */}
  </>
);

const DashboardWrapper = () => {
  const intl = useIntl();
  const [counts, setCounts] = useState<any>({});
  const [winners, setWinners] = useState<any[]>([]);
  const [campaignDetails, setCampaignDetails] = useState<any[]>([]);

  const dashboardCards = async () => {
    const response = await getDashboardCounts();
    setCounts(response.output[0]);
  };

  const dashboardWinners = async () => {
    const response = await getWinnersCampaign();
    setWinners(response.output);
  };

  const chartValues = async () => {
    const response = await getDashboardCount("", "");
    setCampaignDetails(response.output?.slice(-10));
  };

  useEffect(() => {
    dashboardCards();
    dashboardWinners();
    chartValues();
  }, []);

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.DASHBOARD" })}
      </PageTitle>
      {/* <DashboardPage /> */}
      <div className="row">
        <div className="col-sm-6 col-xl-3 px-3 mb-3 text-nowrap">
          <div className="card">
            <div className="card-body px-5 py-5 d-flex">
              <div className="prof_img_pre_list border me-4">
                <p className="h-100 d-flex justify-content-center align-items-center bg_primary">
                  <i className="bi bi-buildings fs-1 text-light"></i>
                </p>
              </div>
              <span className="ms">
                <h3>OMC</h3>
                <h4 className="text-gray-500"># {counts.omc_count}</h4>
              </span>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-xl-3 px-3 mb-3 text-nowrap">
          <div className="card">
            <div className="card-body px-5 py-5 d-flex">
              <div className="prof_img_pre_list border me-4">
                <p className="h-100 d-flex justify-content-center align-items-center bg_primary">
                  <i className="bi bi-award fs-1 text-light"></i>
                </p>
              </div>
              <span className="ms">
                <h3>Campaigns</h3>
                <h4 className="text-gray-500"># {counts.campaign_count}</h4>
              </span>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-xl-3 px-3 mb-3 text-nowrap">
          <div className="card">
            <div className="card-body px-5 py-5 d-flex">
              <div className="prof_img_pre_list border me-4">
                <p className="h-100 d-flex justify-content-center align-items-center bg_primary">
                  <i className="bi bi-patch-plus fs-1 text-light"></i>
                </p>
              </div>
              <span className="ms">
                <h3>Participants</h3>
                <h4 className="text-gray-500"># {counts.participant_count}</h4>
              </span>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-xl-3 px-3 mb-3 text-nowrap">
          <div className="card">
            <div className="card-body px-5 py-5 d-flex">
              <div className="prof_img_pre_list border me-4">
                <p className="h-100 d-flex justify-content-center align-items-center bg_primary">
                  <i className="bi bi-speedometer fs-1 text-light"></i>
                </p>
              </div>
              <span className="ms">
                <h3>RO</h3>
                <h4 className="text-gray-500"># {counts.ro_count}</h4>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <MixedWidget11
          className="mb-2 mt-2 my-md-5 my-xl-8"
          chartColor="primary"
          chartHeight="600px"
        />
      </div>
      <div className="row">
        <div className="col-lg-6 mb-3">
          <div className="card h-100">
            <div className="card-body">
              <table className="table table-hover">
                <thead>
                  <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0 border-bottom">
                    <th>Campaigns</th>
                    <th>participants</th>
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {campaignDetails.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>{item.campaign_name}</td>
                        <td>{item.count}</td>
                        <td className="py-1 text-center">
                          <Link
                            to={
                              "/campaign/campaignRoList?cid=" + item.campaign_id
                            }
                            className="btn btn-sm btn-secondary btn-active-color-primary btn-icon me-2"
                          >
                            <i className="bi bi-box-arrow-up-right"></i>
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-lg-6 mb-3">
          <div className="card h-100">
            <div className="card-body">
              <table className="table table-hover">
                <thead>
                  <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0 border-bottom">
                    <th>Campaigns</th>
                    <th>RO Name</th>
                    <th>Winner</th>
                  </tr>
                </thead>
                <tbody>
                  {winners?.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>{item.campaign_name}</td>
                        <td>{item.ro_name}</td>
                        <td>{item.customer_name}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { DashboardWrapper };
