import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const REPORTS_URL = `${API_URL}/reports_list`;
export const CAMPAIGN_REGISTRATION_LIST_URL = `${API_URL}/campaign_registration_list`;
// http://localhost:8082/reports_list?start_date=2023-10-27&end_date=2023-10-30

export function getReports(
  start,
  end,
  cid,
  area: any,
  division: any,
  state: any,
  fuel_type: any,
  vehicle_type: any,
  ro: any
) {
  return axios
    .get(
      REPORTS_URL +
        "?start_date=" +
        start +
        "&end_date=" +
        end +
        "&cid=" +
        cid +
        "&area=" +
        area +
        "&state=" +
        state +
        "&division=" +
        division +
        "&fuel_type=" +
        fuel_type +
        "&vehicle_type=" +
        vehicle_type +
        "&ro=" +
        ro
    )
    .then((res) => res.data);
}

export function getCampaignRegReport(
  id: string,
  from: string,
  to: string,
  area: any,
  division: any,
  state: any,
  fuel_type: any,
  vehicle_type: any,
  ro: any
) {
  return axios
    .get(
      CAMPAIGN_REGISTRATION_LIST_URL +
        "/" +
        id +
        "?start_date=" +
        from +
        "&end_date=" +
        to +
        "&area=" +
        area +
        "&state=" +
        state +
        "&division=" +
        division +
        "&fuel_type=" +
        fuel_type +
        "&vehicle_type=" +
        vehicle_type +
        "&ro=" +
        ro
    )
    .then((res) => res.data);
}
