import react, { useState, useEffect } from "react";
import { KTIcon, toAbsoluteUrl } from "../../../_metronic/helpers";
import { toast } from "react-toastify";
import { PageTitle } from "../../../_metronic/layout/core/PageData";
import { Paginator } from "primereact/paginator";
import {
  campaignGetById,
  deleteCustomWinner,
  getCustomWinnerList,
  getRoDropdown,
} from "./core/_requests";
import moment from "moment";
import { getAreaOffice } from "../OMC/core/_request";
import { Link } from "react-router-dom";

const CustomPrizePage = () => {
  const [states, setStates] = useState<any[]>([]);
  const [pageData, setPageData] = useState<any[]>([]);
  const [winnerList, setWinnerList] = useState<any>({});
  const [customPrice, setCustomPrice] = useState<any>({});
  const [statesForSearch, setStatesForSearch] = useState<any[]>([]);
  const [winners, setWinners] = useState<any[]>([]);
  const [areas, setAreas] = useState<any[]>([]);
  const [ro, setRo] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [editId, setEditId] = useState("");
  const [search, setSearch] = useState("");
  const [winnerWise, setWinnerWise] = useState("");
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const queryParams = new URLSearchParams(window.location.search);
  const campaignId: any = queryParams.get("cid");

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const areaList = async () => {
    const response = await getAreaOffice();
    setAreas(response.output);
  };

  const RoList = async () => {
    const response = await getRoDropdown();
    setRo(response.output);
  };
  console.log("ewthritgeoirutgoeirt", customPrice);

  const statesList = async () => {
    setLoading(true);
    const response = await campaignGetById(campaignId);
    setWinnerList(response.output[0]);
    let customPrizeData = JSON.parse(response.output[0]?.json_data);
    let customPrizeList: any = [];
    for (let i in customPrizeData) {
      let list = {
        ...customPrizeData[i],
        prize_wise: customPrizeData[i].prize_wise?.split(","),
        prizes: JSON.parse(customPrizeData[i].prizes),
        winner_type: JSON.parse(customPrizeData[i].winner_type),
        start_date: moment(customPrizeData[i].start_date).format("DD/MM/YYYY"),
        end_date: moment(customPrizeData[i].end_date).format("DD/MM/YYYY"),
        start_date_raw: customPrizeData[i].start_date,
        end_date_raw: customPrizeData[i].end_date,
      };
      customPrizeList.push(list);
    }
    setStates(customPrizeList);
    console.log("thjeriuteoritgeiut", customPrizeList);

    setLoading(false);
  };

  // const handleSubmit = async (e) => {
  //     e.preventDefault();
  //     let stateName = (document.getElementById('state_name') as HTMLInputElement).value;
  //     if(stateName.length > 0) {
  //     const response = await saveStates({name: stateName})
  //     if(response.status == 200) {
  //         (document.getElementById('state_name') as HTMLInputElement).value = "";
  //         document.getElementById('atom_state_add_modal_close')?.click();
  //         statesList();
  //         toast.success(response.message, {
  //             position: "bottom-center",
  //             autoClose: 5000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //             theme: "light",
  //         });
  //     } else {
  //         toast.error(response.message, {
  //             position: "bottom-center",
  //             autoClose: 5000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //             theme: "light",
  //         });
  //     }} else {
  //         toast.warn('Please Enter State name..', {
  //             position: "bottom-center",
  //             autoClose: 5000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //             theme: "light",
  //         });
  //     }
  // }

  // const handleDelete = async () => {
  //     const response = await deleteStates(deleteId)
  //     if(response.status == 200) {
  //         setDeleteId('');
  //         document.getElementById('atom_state_delete_modal_close')?.click();
  //         statesList();
  //         toast.success(response.message, {
  //             position: "bottom-center",
  //             autoClose: 5000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //             theme: "light",
  //         });
  //     } else {
  //         toast.error(response.message, {
  //             position: "bottom-center",
  //             autoClose: 5000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //             theme: "light",
  //         });
  //     }
  // }

  // const handleUpdate = async (id) => {
  //     let stateName = (document.getElementById('state_name_update'+id) as HTMLInputElement).value;
  //     if(stateName.length > 0) {
  //         const response = await updateStates(id, {name: stateName})
  //         if(response.status == 200) {
  //             setEditId('');
  //             statesList();
  //             toast.success(response.message, {
  //                 position: "bottom-center",
  //                 autoClose: 5000,
  //                 hideProgressBar: false,
  //                 closeOnClick: true,
  //                 pauseOnHover: true,
  //                 draggable: true,
  //                 progress: undefined,
  //                 theme: "light",
  //             });
  //         } else {
  //             setEditId('');
  //             toast.error(response.message, {
  //                 position: "bottom-center",
  //                 autoClose: 5000,
  //                 hideProgressBar: false,
  //                 closeOnClick: true,
  //                 pauseOnHover: true,
  //                 draggable: true,
  //                 progress: undefined,
  //                 theme: "light",
  //             });
  //         }
  //     } else {
  //         toast.warn('Please Enter State name..', {
  //             position: "bottom-center",
  //             autoClose: 5000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //             theme: "light",
  //         });
  //     }
  // }
  const egrerw = async () => {
    const response = await getCustomWinnerList(
      winnerList.id,
      customPrice.cp_id,
      "",
      ""
    );
    setWinners(response.output);
    console.log("ewrlheoirugwoieurgowieur", response);
  };

  useEffect(() => {
    if (winnerList.id && customPrice.cp_id) {
      egrerw();
    }
  }, [winnerList, customPrice]);

  useEffect(() => {
    areaList();
    RoList();
  }, []);

  useEffect(() => {
    if (search.length > 0) {
      const states = statesForSearch.filter((p) =>
        p.name?.toLowerCase().includes(search)
      );
      console.log("foghiuehtoeirhtpeoirth", states);
      setStates(states);
    } else {
      setStates(statesForSearch);
    }
  }, [search]);

  useEffect(() => {
    if (campaignId) {
      statesList();
    }
  }, [campaignId]);

  useEffect(() => {
    let page = states?.slice(first, first + rows);
    setPageData(page);
  }, [first, rows, states]);

  return (
    <>
      <PageTitle>Manage Campaign</PageTitle>
      <div
        className="modal fade"
        id="atom_Custom_winner_list"
        aria-hidden="true"
      >
        <div className="modal-dialog mw-550px">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0 justify-content-between align-items-center">
              <div className="text-center">
                <h3 className="mb-3">Winners</h3>
              </div>
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
                id="atom_state_add_modal_close"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
            </div>
            <div className="modal-body scroll-y pb-10 pt-5 mh-500px">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>sl.no</th>
                    <th>Wise</th>
                    <th>Name</th>
                    <th>Contact</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {winners?.map((data: any, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1 + first}</td>
                        <td className="text-capitalize">
                          {data.wise?.split("_")?.join(" ")}{" "}
                          {`(${
                            data.wise == "area_winner"
                              ? data.area_name
                              : data.wise == "ro_winner"
                              ? data.ro_name
                              : data.campaign_name
                          })`}
                        </td>
                        <td>{data.customer_name}</td>
                        <td>
                          {data.mobile} & {data.email}
                        </td>
                        <td>
                          <button
                            className="btn btn-sm btn-secondary btn-active-color-danger btn-icon me-2"
                            onClick={async () => {
                              const response = await deleteCustomWinner(
                                winnerList.id,
                                customPrice.cp_id,
                                data.id
                              );
                              if (response.status == 200) {
                                toast.success("Deleted Successfully!", {
                                  position: "bottom-center",
                                  autoClose: 5000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                  theme: "light",
                                });
                                egrerw();
                              }
                            }}
                          >
                            <i className="bi bi-trash"></i>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="atom_Custom_winner_list_area_modal"
        aria-hidden="true"
      >
        <div className="modal-dialog mw-550px">
          <div className="modal-content mh-600px">
            <div className="modal-header border-0 pb-0 justify-content-between align-items-center">
              <div className="text-center">
                <h3 className="mb-3">{winnerList.name}</h3>
              </div>
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
                id="atom_Campaign_winner_list_modal_close"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
            </div>
            <div className="modal-body scroll-y pb-10">
              <select
                className="form-select mb-3"
                onChange={(e) => {
                  setWinnerWise(e.target.value);
                }}
              >
                <option>select</option>
                {customPrice.prize_wise?.map((dat, i) => {
                  return (
                    <option key={i} className="text-capitalize" value={dat}>
                      {dat.split("_")?.join("  ")}
                    </option>
                  );
                })}
              </select>
              {winnerWise && (
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>sl.no</th>
                      <th>
                        {winnerWise == "area_wise"
                          ? "Area"
                          : winnerWise == "ro_wise"
                          ? "RO"
                          : "Campaign"}
                      </th>
                      <th>Link</th>
                    </tr>
                  </thead>
                  {winnerWise == "area_wise" ? (
                    <tbody>
                      {areas
                        ?.filter((item) =>
                          winnerList.area
                            ?.split(",")
                            .some(
                              (item2) =>
                                item2?.toString() === item.id?.toString()
                            )
                        )
                        ?.map((data: any, i) => {
                          return (
                            <tr key={i}>
                              <td>{i + 1 + first}</td>
                              <td>{data.city}</td>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-sm btn-success me-2"
                                  title="Area wise winner"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      `${
                                        process.env.REACT_APP_FRONT_URL
                                      }customWinnerPage?cid=${
                                        winnerList.id
                                      }&rid=&aid=${
                                        data.id
                                      }&wise=area_winner&cpid=${
                                        customPrice.cp_id
                                      }&fdate=${
                                        customPrice.date ||
                                        moment(
                                          customPrice.start_date_raw
                                        ).format("YYYY-MM-DD")
                                      }&tdate=${
                                        customPrice.date ||
                                        moment(customPrice.end_date_raw).format(
                                          "YYYY-MM-DD"
                                        )
                                      }`
                                    );
                                    toast.success("Copied!", {
                                      position: "bottom-center",
                                      autoClose: 5000,
                                      hideProgressBar: false,
                                      closeOnClick: true,
                                      pauseOnHover: true,
                                      draggable: true,
                                      progress: undefined,
                                      theme: "light",
                                    });
                                  }}
                                >
                                  copy link!
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  ) : winnerWise == "ro_wise" ? (
                    <tbody>
                      {ro
                        ?.filter((item) =>
                          winnerList.ro_id
                            ?.split(",")
                            .some(
                              (item2) =>
                                item2?.toString() === item.id?.toString()
                            )
                        )
                        ?.map((data: any, i) => {
                          return (
                            <tr key={i}>
                              <td>{i + 1 + first}</td>
                              <td>{data.ro_name}</td>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-sm btn-success me-2"
                                  title="RO wise winner"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      `${
                                        process.env.REACT_APP_FRONT_URL
                                      }customWinnerPage?cid=${
                                        winnerList.id
                                      }&rid=${
                                        data.id
                                      }&aid=&wise=ro_winner&cpid=${
                                        customPrice.cp_id
                                      }&fdate=${
                                        customPrice.date ||
                                        moment(
                                          customPrice.start_date_raw
                                        ).format("YYYY-MM-DD")
                                      }&tdate=${
                                        customPrice.date ||
                                        moment(customPrice.end_date_raw).format(
                                          "YYYY-MM-DD"
                                        )
                                      }`
                                    );
                                    toast.success("Copied!", {
                                      position: "bottom-center",
                                      autoClose: 5000,
                                      hideProgressBar: false,
                                      closeOnClick: true,
                                      pauseOnHover: true,
                                      draggable: true,
                                      progress: undefined,
                                      theme: "light",
                                    });
                                  }}
                                >
                                  copy link!
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>{winnerList.name}</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-sm btn-success me-2"
                            title="RO wise winner"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                `${
                                  process.env.REACT_APP_FRONT_URL
                                }customWinnerPage?cid=${
                                  winnerList.id
                                }&rid=&aid=&wise=campaign_winner&cpid=${
                                  customPrice.cp_id
                                }&fdate=${
                                  customPrice.date ||
                                  moment(customPrice.start_date_raw).format(
                                    "YYYY-MM-DD"
                                  )
                                }&tdate=${
                                  customPrice.date ||
                                  moment(customPrice.end_date_raw).format(
                                    "YYYY-MM-DD"
                                  )
                                }`
                              );
                              toast.success("Copied!", {
                                position: "bottom-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                              });
                            }}
                          >
                            copy link!
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="w-100 h-100">
          <div className="d-flex justify-content-center flex-column align-items-center h-100">
            <div className="spinner-border taskloader" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="card">
          <div className="card-header d-flex align-items-center">
            <h3>{winnerList.name}</h3>
          </div>
          <div className="card-body pt-0">
            <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
              <thead>
                <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                  <th>Sl.No</th>
                  <th>Custom Prize</th>
                  <th>Type</th>
                  <th>Prize Wise</th>
                  <th>Date</th>
                  <th className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody className="text-gray-600 fw-bold">
                {states.length > 0 ? (
                  pageData.map((item, i) => {
                    const datesList: any = [];
                    let currentDate = moment(item.start_date_raw);
                    let now = moment();

                    while (currentDate.isSameOrBefore(item.end_date_raw)) {
                      datesList.push(currentDate.format("DD-MM-YYYY"));
                      currentDate.add(1, "days");
                    }

                    return (
                      <tr key={i}>
                        <td>{i + 1 + first}</td>
                        <td>Custom Prize {i + 1}</td>
                        <td>{item.type}</td>
                        <td>{item.prize_wise?.join(", ")}</td>
                        <td>
                          {item.type === "fixed" ? (
                            <span>{`${item.start_date} - ${item.end_date}`}</span>
                          ) : (
                            <div className="d-flex flex-column text-nowrap">
                              {datesList.map((item, e) => {
                                return (
                                  <span className="py-4" key={e}>
                                    {item}
                                  </span>
                                );
                              })}
                            </div>
                          )}
                        </td>
                        <td className="text-center">
                          {item.type === "fixed" ? (
                            <>
                              {moment(item.end_date_raw).add(1, "days") <
                              moment() ? (
                                <span>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-success text-nowrap me-3"
                                    data-bs-toggle="modal"
                                    data-bs-target="#atom_Custom_winner_list_area_modal"
                                    onClick={() =>
                                      setCustomPrice({ ...item, date: "" })
                                    }
                                  >
                                    Select Winner!
                                  </button>
                                  {/* <button type='button' className='btn btn-sm btn-success text-nowrap' data-bs-toggle='modal' data-bs-target='#atom_Custom_winner_list' onClick={() => {
                                    setCustomPrice({...item, date: ''})
                                    }}  >
                                    Winner
                                </button>*/}
                                  <Link
                                    className="btn btn-sm btn-success text-nowrap"
                                    to={`winnersList?cid=${campaignId}&cpid=${
                                      item.cp_id
                                    }&cpi=Custom Prize ${i + 1}`}
                                  >
                                    Winner
                                  </Link>
                                </span>
                              ) : (
                                <p className="text-secondary text-wrap disabled me-2">
                                  Campaign not completed yet!
                                </p>
                              )}
                            </>
                          ) : (
                            <div className="d-flex flex-column">
                              {datesList.map((iteme: any, e) => {
                                console.log(
                                  "ejhwfekhwiuergwiegrw",
                                  parseInt(moment().format("DD")),
                                  iteme
                                );

                                return (
                                  <>
                                    {parseInt(iteme.split("-")[0]) <
                                    parseInt(moment().format("DD")) ? (
                                      <span>
                                        {/* {new Date(iteme) < new Date ? <span> */}
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-success my-2 mx-auto text-nowrap me-3"
                                          data-bs-toggle="modal"
                                          data-bs-target="#atom_Custom_winner_list_area_modal"
                                          key={e}
                                          onClick={() =>
                                            setCustomPrice({
                                              ...item,
                                              date: `${iteme.split("-")[2]}-${
                                                iteme.split("-")[1]
                                              }-${iteme.split("-")[0]}`,
                                            })
                                          }
                                        >
                                          Select Winner!
                                        </button>
                                        <Link
                                          className="btn btn-sm btn-success text-nowrap"
                                          to={`winnersList?cid=${campaignId}&cpid=${
                                            item.cp_id
                                          }&cpi=Custom Prize ${i + 1}`}
                                        >
                                          Winner
                                        </Link>
                                        {/* <button type='button' className='btn btn-sm btn-success text-nowrap' data-bs-toggle='modal' data-bs-target='#atom_Custom_winner_list' onClick={() => {
                                    setCustomPrice({...item, date: ''})
                                    }} >
                                    Winner
                                    </button> */}
                                      </span>
                                    ) : (
                                      <p className="text-secondary text-nowrap disabled my-4">
                                        Campaign not completed yet!
                                      </p>
                                    )}
                                  </>
                                );
                              })}
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className="d-flex text-center w-100 align-content-center justify-content-center">
                        No matching records found
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="table_paginator mt-3 d-flex justify-content-end">
              <Paginator
                first={first}
                rows={rows}
                totalRecords={states.length}
                rowsPerPageOptions={[10, 50, 100, 500, 1000]}
                onPageChange={onPageChange}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomPrizePage;
