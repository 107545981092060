import react, { useState, useEffect, useRef } from "react";
import { PageTitle } from "../../../_metronic/layout/core/PageData";
import { Paginator } from "primereact/paginator";
import moment from "moment";
import { getMaster } from "../BussinesSettings/core/_requests";
import { getRo } from "../RO/core/_requests";
import { useReactToPrint } from "react-to-print";
import jsPDF from "jspdf";
import { Margin, usePDF } from "react-to-pdf";
import {
  getAreaOffice,
  getDivisionOffice,
  getStateOffice,
} from "../OMC/core/_request";
import { getCampaign, getCampaignById } from "../campaign/core/_requests";
import { getCampaignRegReport, getReports } from "./core/_requests";
import * as XLSX from "xlsx/xlsx.mjs";

const RegistrationListReport = () => {
  const [campaign, setCampaign] = useState<any[]>([]);
  const [pageData, setPageData] = useState<any[]>([]);
  const [CampaignForSearch, setCampaignForSearch] = useState<any[]>([]);
  const [states, setStates] = useState<any[]>([]);
  const [divisions, setDivisions] = useState<any[]>([]);
  const [fuelType, setFuelType] = useState<any[]>([]);
  const [vehicleType, setVehicleType] = useState<any[]>([]);
  const [areas, setAreas] = useState<any[]>([]);
  const [ro, setRo] = useState<any[]>([]);
  const [campaignsList, setCampaignsList] = useState<any[]>([]);
  const [countList, setCountList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentCampaign, setCurrentCampaign] = useState<any>({});
  const [fieldds, setFieldds] = useState<any>({});
  const [search, setSearch] = useState("");
  const [stateId, setstateId] = useState("");
  const [roId, setRoId] = useState("");
  const [divId, setDivId] = useState("");
  const [areaId, setAreaId] = useState("");
  const [vTId, setVTId] = useState("");
  const [fTId, setFTId] = useState("");
  const [first, setFirst] = useState(0);
  const [campaignId, setCampaignId] = useState("");
  const [rows, setRows] = useState(10);
  const [fromDate, setFromDate] = useState<any>("");
  const [toDate, setToDate] = useState<any>("");
  const [dates, setDates] = useState<any[]>([]);
  const [datesList, setDatesList] = useState<any[]>([]);
  const [colTotal, setColTotal] = useState<any[]>([]);
  // const queryParams = new URLSearchParams(window.location.search);
  // const campaignId = queryParams.get('cid');
  console.log("welkjthoiuerthreiuhlerkjter", currentCampaign);

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const campaignList = async () => {
    setLoading(true);
    const response = await getCampaignRegReport(
      campaignId,
      fromDate,
      toDate,
      areaId,
      divId,
      stateId,
      fTId,
      vTId,
      roId
    );
    setCampaign(response.output);
    setCampaignForSearch(response.output);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const CampaignList = async () => {
    const response = await getCampaign();
    setCampaignsList(response.output);
  };

  const regListCountList = async () => {
    const response = await getReports(
      fromDate,
      toDate,
      campaignId,
      areaId,
      divId,
      stateId,
      fTId,
      vTId,
      roId
    );
    setCountList(response.output);
  };

  const currentCampaigns = async () => {
    const response = await getCampaignById(campaignId);
    setCurrentCampaign(response.output[0]);
    if (response.output[0]?.forms) {
      let hiugrte = JSON.parse(response.output[0]?.forms);
      setFieldds(hiugrte);
    }
  };

  const statesList = async () => {
    const response = await getStateOffice();
    setStates(
      response.output?.filter(
        (item: any) =>
          currentCampaign?.state?.split(",").indexOf(item.id?.toString()) !== -1
      )
    );
  };

  const divisionList = async () => {
    const response = await getDivisionOffice();
    setDivisions(
      response.output?.filter(
        (item: any) =>
          currentCampaign?.division?.split(",").indexOf(item.id?.toString()) !==
          -1
      )
    );
  };

  const areaList = async () => {
    const response = await getAreaOffice();
    setAreas(
      response.output?.filter(
        (item: any) =>
          currentCampaign?.area?.split(",").indexOf(item.id?.toString()) !== -1
      )
    );
  };

  const RoList = async () => {
    const response = await getRo();
    setRo(response.output);
  };

  const fuelTypeList = async () => {
    const response = await getMaster("fuel_type");
    setFuelType(
      response.output?.filter(
        (item: any) =>
          currentCampaign?.fuel_type
            ?.split(",")
            .indexOf(item.id?.toString()) !== -1
      )
    );
  };

  const vehicleTypeList = async () => {
    const response = await getMaster("vehicle_type");
    setVehicleType(
      response.output?.filter(
        (item: any) =>
          currentCampaign?.vehicle_type
            ?.split(",")
            .indexOf(item.id?.toString()) !== -1
      )
    );
  };

  useEffect(() => {
    if (search.length > 0) {
      const campaign = CampaignForSearch.filter(
        (p) =>
          p.name?.toLowerCase().includes(search) ||
          p.email?.toLowerCase().includes(search) ||
          p.mobile?.toString().toLowerCase().includes(search) ||
          p.vehicle_number?.toLowerCase().includes(search) ||
          p.fuel_type_name?.toLowerCase().includes(search) ||
          p.vehicle_type_name?.toLowerCase().includes(search) ||
          p.state_name?.toLowerCase().includes(search) ||
          p.division_name?.toLowerCase().includes(search) ||
          p.area_name?.toLowerCase().includes(search) ||
          p.ro_name?.toLowerCase().includes(search) ||
          p.sap_code?.toLowerCase().includes(search) ||
          p.customer_attendant_name?.toLowerCase().includes(search) ||
          p.customer_attendant_code?.toLowerCase().includes(search) ||
          p.field_officer_name?.toLowerCase().includes(search) ||
          p.field_officer_code?.toLowerCase().includes(search)
      );
      setCampaign(campaign);
    } else {
      setCampaign(CampaignForSearch);
    }
  }, [search]);

  useEffect(() => {
    CampaignList();
    RoList();
  }, []);

  useEffect(() => {
    if (currentCampaign.id) {
      areaList();
      divisionList();
      statesList();
      fuelTypeList();
      vehicleTypeList();
    }
  }, [currentCampaign.id]);

  useEffect(() => {
    if (campaignId) {
      campaignList();
      regListCountList();
      currentCampaigns();
    }
  }, [campaignId, stateId, roId, divId, areaId, vTId, fTId]);

  useEffect(() => {
    let page = campaign?.slice(first, first + rows);
    setPageData(page);
  }, [first, rows, campaign]);

  const lrjiuetgiert: any = useRef();
  const lrjiuetgiertwerheowiur: any = useRef();

  function download_csv(csv, filename) {
    var csvFile;
    var downloadLink;
    csvFile = new Blob([csv], { type: "text/csv" });
    downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  function export_table_to_csv(index) {
    if (index == 0) {
      var csv_data: any = [];

      var rows = document.getElementsByClassName("trow");
      for (var i = 0; i < rows.length; i++) {
        var cols: any = rows[i]?.querySelectorAll("td,th");

        var csvrow: any = [];
        for (var j = 0; j < cols.length; j++) {
          csvrow?.push(cols[j]?.innerHTML);
        }

        csv_data?.push(csvrow?.join(","));
      }

      return csv_data?.join("\n");
    } else {
      var csv_data: any = [];

      var rows = document.getElementsByClassName("trow2");
      for (var i = 0; i < rows.length; i++) {
        var cols: any = rows[i]?.querySelectorAll("td,th");

        var csvrow: any = [];
        for (var j = 0; j < cols.length; j++) {
          csvrow?.push(cols[j]?.innerHTML);
        }

        csv_data?.push(csvrow?.join(","));
      }

      return csv_data?.join("\n");
    }
  }

  function tableToCSV() {
    var csv = [
      fieldds.nameField,
      fieldds.emailField,
      fieldds.mobileField,
      fieldds.vtypeField,
      fieldds.vnumberField,
      fieldds.ftypeField,
      fieldds.bill_amount,
      fieldds.customer_attendant_name,
      fieldds.customer_attendant_code,
      fieldds.field_officer_name,
      fieldds.field_officer_code,
      fieldds.sloganField,
      fieldds.bill_image,
      fieldds.selfi_image,
    ];
    let kfjh = 7;
    for (let efyg in csv) {
      if (csv[efyg] == true) {
        kfjh = kfjh + 1;
      }
    }
    let sdjfygus = String.fromCharCode(kfjh + 1 + 64);
    console.log("sdjfygus", sdjfygus);

    var blob,
      wb: any = { SheetNames: [], Sheets: {} };

    if (sdjfygus) {
      for (let i = 0; i < 2; i++) {
        var sheetd: any =
          i == 0 ? "Registration_Details" : "Registration_count";

        var ws1 = XLSX.read(export_table_to_csv(i), { type: "binary" })?.Sheets
          .Sheet1;
        if (i == 0) {
          for (let a = 0; a < CampaignForSearch?.length; a++) {
            let rown = a + 2;
            console.log("etjeorpiteriotheoiru", sdjfygus + (a + 2));
            ws1[`${sdjfygus + (a + 2)}`].z = "dd-mm-yyyy";
          }
        } else {
          const rowNum = 0;
          for (let colNum = 3; colNum < dates.length + 5; colNum++) {
            const cellAddress = XLSX.utils.encode_cell({
              r: rowNum,
              c: colNum,
            });
            if (ws1[cellAddress]) {
              ws1[cellAddress].z = "dd-mm-yyyy";
            }
          }
        }

        wb.SheetNames.push(sheetd);
        wb.Sheets[sheetd] = ws1;
      }
    }

    blob = new Blob(
      [s2ab(XLSX.write(wb, { bookType: "xlsx", type: "binary" }))],
      {
        type: "application/octet-stream",
      }
    );

    download_csv(
      blob,
      `${currentCampaign.name} (Report for ${fromDate} - ${toDate}).xlsx`
    );
  }

  const { toPDF, targetRef } = usePDF({
    filename: `${currentCampaign.name} (Report for ${fromDate} - ${toDate}).pdf`,
    page: { orientation: "landscape", margin: Margin.MEDIUM },
  });

  useEffect(() => {
    if (toDate && fromDate) {
      const dates: any = [];
      const datesList: any = [];
      let currentDate = moment(fromDate);

      while (currentDate.isSameOrBefore(toDate)) {
        dates.push(currentDate.format("YYYY-MM-DD"));
        datesList.push(currentDate.format("YYYY-MM-DD"));
        currentDate.add(1, "days");
      }

      setDates(dates);
      setDatesList(datesList);
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    if (datesList.length > 0 && countList.length > 0) {
      let gtuegtug: any = [];
      let hfgj = datesList?.map((date: any) => {
        let sdkfhfh: any = countList
          ?.map((item: any) => item[date] || 0)
          ?.reduce((accumulator, currentValue) => {
            return accumulator + currentValue;
          }, 0);
        // return {[date]: sdkfhfh};
        // console.log("eljriuhroiuwegrogweir", {[date]: sdkfhfh});
        gtuegtug.push({ [date]: sdkfhfh });
      });

      console.log("kfhwieuroiurgoiwueroiuwer", gtuegtug);
      setColTotal(gtuegtug);
    }
  }, [datesList, countList]);

  useEffect(() => {
    if (fromDate && toDate && campaignId) {
      regListCountList();
      campaignList();
    }
  }, [fromDate, toDate, campaignId]);

  return (
    <>
      <PageTitle>Reports</PageTitle>
      {loading ? (
        <div className="w-100 h-100">
          <div className="d-flex justify-content-center flex-column align-items-center h-100">
            <div className="spinner-border taskloader" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="card">
          <div className="card-header d-flex align-items-center mobile-padding">
            <h3 className="mb-sm-0 mb-3">
              {currentCampaign.name || "Reports"}
            </h3>
            <span className="d-flex">
              <input
                type="date"
                max={moment().format("YYYY-MM-DD")}
                className="form-control form-control-sm mw-200px me-3"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
              {fromDate && (
                <input
                  type="date"
                  min={fromDate}
                  max={moment().format("YYYY-MM-DD")}
                  className="form-control form-control-sm mw-200px me-3"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              )}
              {fromDate && toDate && (
                <select
                  className="form-select form-select-sm mw-200px me-3"
                  value={campaignId}
                  onChange={(e) => {
                    if (e.target.value) {
                      setCampaignId(e.target.value);
                      let hgdf = CampaignForSearch?.filter(
                        (cam) =>
                          cam.campaign_id?.toString() ==
                          e.target.value?.toString()
                      );
                      setCampaign(hgdf);
                      setFirst(0);
                      setRows(10);
                      setstateId("");
                      setDivId("");
                      setFTId("");
                      setVTId("");
                      setAreaId("");
                    }
                  }}
                >
                  <option value="">select campaign</option>
                  {campaignsList.map((item, i) => {
                    return (
                      <option value={item.id} key={i}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              )}
              {pageData.length > 0 && (
                <button
                  type="button"
                  className="btn btn-sm btn-secondary btn-active-color-primary btn-icon px-3"
                  id="dropdownMenuReport"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="bi bi-cloud-download"></i>
                </button>
              )}
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuReport"
              >
                <button
                  className="dropdown-item"
                  type="button"
                  onClick={() => toPDF()}
                >
                  PDF
                </button>
                <button
                  className="dropdown-item"
                  type="button"
                  onClick={tableToCSV}
                >
                  XLSX
                </button>
              </div>
            </span>
          </div>
          <div className="card-body pt-0">
            <div className="overflow-x-auto">
              <table
                ref={targetRef}
                className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
              >
                {campaign.length > 0 && (
                  <thead>
                    <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                      <th>Sl.No</th>
                      {fieldds.nameField && (
                        <th style={{ minWidth: "200px" }}>Name</th>
                      )}
                      <th style={{ minWidth: "100px" }}>Entry Type</th>
                      {fieldds.emailField ||
                        (fieldds.mobileField && (
                          <th style={{ minWidth: "200px" }}>Email & Phone</th>
                        ))}
                      {fieldds.vnumberField && (
                        <th style={{ minWidth: "200px" }}>Vehicle Number</th>
                      )}
                      {fieldds.ftypeField && (
                        <th style={{ minWidth: "150px" }} className="p-0">
                          <div className="d-flex align-items-center">
                            <select
                              className="form-select border-0 text-muted fw-bolder fs-7 text-uppercase gs-0 mb-2"
                              id="wejhrweryifgrgerjwerjb"
                              value={fTId}
                              onChange={(e) => setFTId(e.target.value)}
                            >
                              <option value="">Fuel Type</option>
                              {fuelType.map((item, i) => {
                                return (
                                  <option value={item.id} key={i}>
                                    {item.option_value}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </th>
                      )}
                      {fieldds.vtypeField && (
                        <th style={{ minWidth: "150px" }} className="p-0">
                          <div className="d-flex align-items-center">
                            <select
                              className="form-select border-0 text-muted fw-bolder fs-7 text-uppercase gs-0 mb-2"
                              id="erjwgerugwuerywerfytfwe"
                              value={vTId}
                              onChange={(e) => setVTId(e.target.value)}
                            >
                              <option value="">Vehicle Type</option>
                              {vehicleType.map((item, i) => {
                                return (
                                  <option value={item.id} key={i}>
                                    {item.option_value}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </th>
                      )}
                      <th style={{ minWidth: "150px" }} className="p-0">
                        <div className="d-flex align-items-center">
                          <select
                            className="form-select border-0 text-muted fw-bolder fs-7 text-uppercase gs-0 mb-2"
                            id="erwuehrugweorbwuerfiuyf"
                            value={stateId}
                            onChange={(e) => {
                              setstateId(e.target.value);
                              setDivId("");
                              setAreaId("");
                            }}
                          >
                            <option value="">State</option>
                            {states.map((item, i) => {
                              return (
                                <option value={item.id} key={i}>
                                  {item.state}
                                </option>
                              );
                            })}
                          </select>
                          {/* State */}
                        </div>
                      </th>
                      <th style={{ minWidth: "150px" }} className="p-0">
                        <div className="d-flex align-items-center">
                          <select
                            className="form-select border-0 text-muted fw-bolder fs-7 text-uppercase gs-0 mb-2"
                            id="werhwegruwgeruweriuyiyrvgv"
                            value={divId}
                            onChange={(e) => {
                              setDivId(e.target.value);
                              setAreaId("");
                            }}
                          >
                            <option value="">
                              {divisions?.find(
                                (item: any) =>
                                  item.omc_id?.toString() ==
                                  currentCampaign?.omc_id?.toString()
                              )?.type || "Retail"}
                            </option>
                            {divisions.map((item, i) => {
                              if (stateId) {
                                if (stateId == item.state?.toString()) {
                                  return (
                                    <option value={item.id} key={i}>
                                      {item.division}
                                    </option>
                                  );
                                }
                              } else {
                                return (
                                  <option value={item.id} key={i}>
                                    {item.division}
                                  </option>
                                );
                              }
                            })}
                          </select>
                          {/* Retail */}
                        </div>
                      </th>
                      <th style={{ minWidth: "150px" }} className="p-0">
                        <div className="d-flex align-items-center">
                          <select
                            className="form-select border-0 text-muted fw-bolder fs-7 text-uppercase gs-0 mb-2"
                            id="wernwegryuegruv4i37giuh4brhb"
                            value={areaId}
                            onChange={(e) => setAreaId(e.target.value)}
                          >
                            <option value="">
                              {areas?.find(
                                (item: any) =>
                                  item.omc_id?.toString() ==
                                  currentCampaign?.omc_id?.toString()
                              )?.type || "Area"}
                            </option>
                            {areas.map((item, i) => {
                              if (stateId && divId) {
                                if (divId == item.division?.toString()) {
                                  return (
                                    <option value={item.id} key={i}>
                                      {item.city}
                                    </option>
                                  );
                                }
                              } else if (!stateId && divId) {
                                if (divId == item.division?.toString()) {
                                  return (
                                    <option value={item.id} key={i}>
                                      {item.city}
                                    </option>
                                  );
                                }
                              } else if (stateId && !divId) {
                                if (stateId == item.state?.toString()) {
                                  return (
                                    <option value={item.id} key={i}>
                                      {item.city}
                                    </option>
                                  );
                                }
                              } else {
                                return (
                                  <option value={item.id} key={i}>
                                    {item.city}
                                  </option>
                                );
                              }
                            })}
                          </select>
                          {/* Area */}
                        </div>
                      </th>
                      <th style={{ minWidth: "150px" }} className="">
                        <div className="d-flex align-items-center">
                          {/* <select className='form-select border-0 text-muted fw-bolder fs-7 text-uppercase gs-0 mb-2' id='werkwgertrgehwhrvhwgefrfuwiervjh' onChange={(e) => setRoId(e.target.value)}>
                                        <option value="" >{currentCampaign?.ro_name_translation || 'RO Name'}</option>
                                        {ro.map((item, i) => {
                                            return(
                                                <option value={item.id} key={i} >{item.ro_name}</option>
                                            )
                                        })}
                                    </select> */}
                          RO Name
                        </div>
                      </th>
                      {fieldds.bill_amount && (
                        <th style={{ minWidth: "200px" }}>Bill Amount</th>
                      )}
                      {fieldds.bill_image && (
                        <th style={{ minWidth: "200px" }}>Bill Image</th>
                      )}
                      {fieldds.selfi_image && (
                        <th style={{ minWidth: "200px" }}>Selfie Image</th>
                      )}
                      {fieldds.customer_attendant_name && (
                        <th style={{ minWidth: "200px" }}>
                          Customer Attendant Name
                        </th>
                      )}
                      {fieldds.customer_attendant_code && (
                        <th style={{ minWidth: "200px" }}>
                          Customer Attendant Code
                        </th>
                      )}
                      {fieldds.field_officer_name && (
                        <th style={{ minWidth: "200px" }}>
                          Field Officer Name
                        </th>
                      )}
                      {fieldds.field_officer_code && (
                        <th style={{ minWidth: "200px" }}>
                          Field Officer Code
                        </th>
                      )}
                      {fieldds.sloganField && (
                        <th style={{ minWidth: "200px" }}>Slogan</th>
                      )}
                      <th className="text-center" style={{ minWidth: "200px" }}>
                        Created At
                      </th>
                    </tr>
                  </thead>
                )}
                <tbody className="text-gray-600 fw-bold">
                  {campaign.length > 0 ? (
                    pageData.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1 + first}</td>
                          {fieldds.nameField && <td>{item.name}</td>}
                          <td>{item.entry_type}</td>
                          {fieldds.emailField ||
                            (fieldds.mobileField && (
                              <td className="py-1">
                                <div className="d-flex align-items-center">
                                  <div className="d-flex flex-column">
                                    <span>{item.email}</span>
                                    <small>{item.mobile}</small>
                                  </div>
                                </div>
                              </td>
                            ))}
                          {fieldds.vnumberField && (
                            <td>{item.vehicle_number}</td>
                          )}
                          {fieldds.ftypeField && <td>{item.fuel_type_name}</td>}
                          {fieldds.vtypeField && (
                            <td>{item.vehicle_type_name}</td>
                          )}
                          <td>{item.state_name}</td>
                          <td>{item.division_name}</td>
                          <td>{item.area_name}</td>
                          <td className="py-1">
                            <div className="d-flex align-items-center">
                              <div className="d-flex flex-column">
                                <span>{item.ro_name}</span>
                                <small># {item.sap_code}</small>
                              </div>
                            </div>
                          </td>
                          {fieldds.bill_amount && <td>{item.bill_amount}</td>}
                          {fieldds.bill_image && (
                            <td className="py-1">
                              <div className="">
                                {item.bill_image ? (
                                  <a
                                    href={
                                      process.env.REACT_APP_API_URL +
                                      "/uploads/campaign/registration/bill_image/" +
                                      item.id +
                                      "/" +
                                      item.bill_image
                                    }
                                    className="prof_img_pre_list border d-flex align-items-center justify-content-center"
                                    target="_blank"
                                  >
                                    <img
                                      src={
                                        process.env.REACT_APP_API_URL +
                                        "/uploads/campaign/registration/bill_image/" +
                                        item.id +
                                        "/" +
                                        item.bill_image
                                      }
                                      className="profile_image w-100"
                                    />
                                  </a>
                                ) : (
                                  <span className="prof_img_pre_list border d-flex align-items-center justify-content-center">
                                    <p className="h-100 w-100 d-flex justify-content-center align-items-center bg-gray-300 m-0 text-light">
                                      Bill
                                    </p>
                                  </span>
                                )}
                              </div>
                            </td>
                          )}
                          {fieldds.selfi_image && (
                            <td className="py-1">
                              <div className="">
                                {item.selfi_image ? (
                                  <a
                                    href={
                                      process.env.REACT_APP_API_URL +
                                      "/uploads/campaign/registration/selfi_image/" +
                                      item.id +
                                      "/" +
                                      item.selfi_image
                                    }
                                    className="prof_img_pre_list border d-flex align-items-center justify-content-center"
                                    target="_blank"
                                  >
                                    <img
                                      src={
                                        process.env.REACT_APP_API_URL +
                                        "/uploads/campaign/registration/selfi_image/" +
                                        item.id +
                                        "/" +
                                        item.selfi_image
                                      }
                                      className="profile_image w-100"
                                    />
                                  </a>
                                ) : (
                                  <span className="prof_img_pre_list border d-flex align-items-center justify-content-center">
                                    <p className="h-100 w-100 d-flex justify-content-center align-items-center bg-gray-300 m-0 text-light">
                                      Selfie Image{" "}
                                    </p>
                                  </span>
                                )}
                              </div>
                            </td>
                          )}
                          {fieldds.customer_attendant_name && (
                            <td>{item.customer_attendant_name}</td>
                          )}
                          {fieldds.customer_attendant_code && (
                            <td>{item.customer_attendant_code}</td>
                          )}
                          {fieldds.field_officer_name && (
                            <td>{item.field_officer_name}</td>
                          )}
                          {fieldds.field_officer_code && (
                            <td>{item.field_officer_code}</td>
                          )}
                          {fieldds.sloganField && <td>{item.slogan}</td>}
                          <td className="py-1">
                            <div className="d-flex align-items-center justify-content-center">
                              <div className="d-flex flex-column text-center">
                                <span>
                                  {moment(item.created_date).format(
                                    "DD-MM-YYYY"
                                  )}
                                </span>
                                <small>
                                  {moment(item.created_date).format("hh:mm a")}
                                </small>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      {fromDate && toDate && campaignId ? (
                        <td colSpan={11}>
                          <div className="d-flex text-center w-100 align-content-center justify-content-center">
                            No matching records found
                          </div>
                        </td>
                      ) : (
                        <td colSpan={11}>
                          <div className="d-flex text-center w-100 align-content-center justify-content-center">
                            Select date range and campaign to generate report
                          </div>
                        </td>
                      )}
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="table_paginator mt-3 d-flex justify-content-end">
              <Paginator
                first={first}
                rows={rows}
                totalRecords={campaign.length}
                rowsPerPageOptions={[10, 50, 100, 500, 1000]}
                onPageChange={onPageChange}
              />
            </div>
          </div>
          <div className="d-none">
            <div className="p-9 pdf_width">
              <table className="table table-bordered align-middle table-row-dashed fs-9 gy-5 dataTable no-footer">
                <thead>
                  {/* <tr className='text-center fw-bolder fs-8 text-uppercase gs-0 trow'>ertgerutgerutgeuy</tr>
                        <tr className='text-center fw-bolder fs-8 text-uppercase gs-0 trow'></tr> */}
                  <tr className="text-start text-muted fw-bolder fs-8 text-uppercase gs-0 trow">
                    <th>Sl.No</th>
                    {fieldds.nameField && <th>Name</th>}
                    <th>Entry Type</th>
                    {fieldds.emailField && <th>Email</th>}
                    {fieldds.mobileField && <th>Phone</th>}
                    {fieldds.vtypeField && <th>Vehicle Type</th>}
                    {fieldds.vnumberField && <th>Vehicle Number</th>}
                    {fieldds.ftypeField && <th>Fuel Type</th>}
                    <th>State</th>
                    <th>
                      {divisions?.find(
                        (item: any) =>
                          item.omc_id?.toString() ==
                          currentCampaign?.omc_id?.toString()
                      )?.type || "Retail"}
                    </th>
                    <th>
                      {areas?.find(
                        (item: any) =>
                          item.omc_id?.toString() ==
                          currentCampaign?.omc_id?.toString()
                      )?.type || "Area"}
                    </th>
                    <th>RO Name</th>
                    <th>
                      {ro?.find(
                        (item: any) =>
                          item.omc_id?.toString() ==
                          currentCampaign?.omc_id?.toString()
                      )?.type || "RO Code"}
                    </th>
                    {fieldds.bill_amount && <th>Bill Amount</th>}
                    {fieldds.bill_image && <th>Bill Image</th>}
                    {fieldds.selfi_image && <th>Selfie Image</th>}
                    {fieldds.customer_attendant_name && (
                      <th>Customer Attendant Name</th>
                    )}
                    {fieldds.customer_attendant_code && (
                      <th>Customer Attendant Code</th>
                    )}
                    {fieldds.field_officer_name && <th>Field Officer Name</th>}
                    {fieldds.field_officer_code && <th>Field Officer Code</th>}
                    {fieldds.sloganField && <th>Slogan</th>}
                    <th>Created Date</th>
                    <th>Created Time</th>
                  </tr>
                </thead>
                <tbody className="text-gray-600 fw-bold">
                  {campaign.length > 0 ? (
                    campaign.map((item, i) => {
                      return (
                        <tr key={i} className="trow">
                          <td>{i + 1}</td>
                          {fieldds.nameField && (
                            <td>{item.name?.replace(/,/g, "")}</td>
                          )}
                          <td>{item.entry_type?.replace(/,/g, "")}</td>
                          {fieldds.emailField && (
                            <td>{item.email?.replace(/,/g, "")}</td>
                          )}
                          {fieldds.mobileField && (
                            <td>{item.mobile?.replace(/,/g, "")}</td>
                          )}
                          {fieldds.vtypeField && (
                            <td>{item.vehicle_type_name?.replace(/,/g, "")}</td>
                          )}
                          {fieldds.vnumberField && (
                            <td>{item.vehicle_number?.replace(/,/g, "")}</td>
                          )}
                          {fieldds.ftypeField && (
                            <td>{item.fuel_type_name?.replace(/,/g, "")}</td>
                          )}
                          <td>{item.state_name?.replace(/,/g, "")}</td>
                          <td>{item.division_name?.replace(/,/g, "")}</td>
                          <td>{item.area_name?.replace(/,/g, "")}</td>
                          <td>{item.ro_name?.replace(/,/g, "")}</td>
                          <td>{item.sap_code?.replace(/,/g, "")}</td>
                          {fieldds.bill_amount && (
                            <td>{item.bill_amount?.replace(/,/g, "")}</td>
                          )}
                          {fieldds.bill_image && (
                            <td>
                              {item.bill_image
                                ? process.env.REACT_APP_API_URL +
                                  "/uploads/campaign/registration/bill_image/" +
                                  item.id +
                                  "/" +
                                  item.bill_image
                                : ""}
                            </td>
                          )}
                          {fieldds.selfi_image && (
                            <td>
                              {item.selfi_image
                                ? process.env.REACT_APP_API_URL +
                                  "/uploads/campaign/registration/selfi_image/" +
                                  item.id +
                                  "/" +
                                  item.selfi_image
                                : ""}
                            </td>
                          )}
                          {fieldds.customer_attendant_name && (
                            <td>
                              {item.customer_attendant_name?.replace(/,/g, "")}
                            </td>
                          )}
                          {fieldds.customer_attendant_code && (
                            <td>
                              {item.customer_attendant_code?.replace(/,/g, "")}
                            </td>
                          )}
                          {fieldds.field_officer_name && (
                            <td>
                              {item.field_officer_name?.replace(/,/g, "")}
                            </td>
                          )}
                          {fieldds.field_officer_code && (
                            <td>
                              {item.field_officer_code?.replace(/,/g, "")}
                            </td>
                          )}
                          {fieldds.sloganField && (
                            <td>{item.slogan?.replace(/,/g, "")}</td>
                          )}
                          <td>
                            {moment(item.created_date).format("YYYY-MM-DD")}
                          </td>
                          <td>{moment(item.created_date).format("hh:mm a")}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={11}>
                        <div className="d-flex text-center w-100 align-content-center justify-content-center">
                          No matching records found
                          {/* Choose date range and campaign to generate report */}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="d-none">
            <div className="p-9 pdf_width">
              <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                <thead>
                  <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0 trow2">
                    <th>Sl.No</th>
                    <th style={{ minWidth: "200px" }}>
                      {countList.length > 0 ? countList[0]?.type : "RO Code"}
                    </th>
                    <th style={{ minWidth: "150px" }}>RO Name</th>
                    <th style={{ minWidth: "150px" }}>
                      {divisions?.find(
                        (item: any) =>
                          item.omc_id?.toString() ==
                          currentCampaign?.omc_id?.toString()
                      )?.type || "Retail"}
                    </th>
                    <th style={{ minWidth: "150px" }}>
                      {areas?.find(
                        (item: any) =>
                          item.omc_id?.toString() ==
                          currentCampaign?.omc_id?.toString()
                      )?.type || "Area"}
                    </th>
                    {dates.map((date: any, i) => {
                      return (
                        <th style={{ minWidth: "100px" }} key={i}>
                          {date}
                        </th>
                      );
                    })}
                    <th style={{ minWidth: "150px" }}>Total</th>
                  </tr>
                </thead>
                <tbody className="text-gray-600 fw-bold">
                  {countList.length > 0 ? (
                    <>
                      {countList.map((item, i) => {
                        return (
                          <tr key={i} className="trow2">
                            <td>{i + 1}</td>
                            <td>{item.sap_code?.replace(/,/g, "")}</td>
                            <td>{item.ro_name?.replace(/,/g, "")}</td>
                            <td>
                              {item.division_office_name?.replace(/,/g, "")}
                            </td>
                            <td>{item.area_office_name?.replace(/,/g, "")}</td>
                            {datesList.map((date: any, j) => {
                              return (
                                <td style={{ minWidth: "100px" }} key={j}>
                                  {item[date] || 0}
                                </td>
                              );
                            })}
                            <td>
                              {datesList
                                .map((date: any) => parseInt(item[date] || 0))
                                ?.reduce((accumulator, currentValue) => {
                                  return accumulator + currentValue;
                                }, 0)}
                            </td>
                          </tr>
                        );
                      })}
                      <tr className="trow2">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Total</td>
                        {colTotal?.map((obj: any, j: any) => {
                          return <td key={j}>{obj[Object.keys(obj)[0]]}</td>;
                        })}
                        <td>
                          {colTotal
                            ?.map(
                              (obj: any, j: any) => obj[Object.keys(obj)[0]]
                            )
                            ?.reduce((accumulator, currentValue) => {
                              return accumulator + currentValue;
                            }, 0)}
                        </td>
                      </tr>
                    </>
                  ) : (
                    <tr>
                      <td colSpan={11}>
                        <div className="d-flex text-center w-100 align-content-center justify-content-center">
                          Select date range and campaign to generate report
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="d-none">
            <div className="p-9 pdf_width" ref={lrjiuetgiert}>
              <table className="table table-bordered align-middle table-row-dashed fs-9 gy-5 dataTable no-footer">
                <thead>
                  <tr className="text-start text-muted fw-bolder fs-8 text-uppercase gs-0">
                    <th>Sl.No</th>
                    {fieldds.nameField && <th>Name</th>}
                    <th>Entry Type</th>
                    {fieldds.emailField ||
                      (fieldds.mobileField && <th>Email & Phone</th>)}
                    {fieldds.vnumberField ||
                      (fieldds.vtypeField && <th>Vehicle</th>)}
                    {fieldds.ftypeField && <th>Fuel Type</th>}
                    <th>State</th>
                    <th>
                      {divisions?.find(
                        (item: any) =>
                          item.omc_id?.toString() ==
                          currentCampaign?.omc_id?.toString()
                      )?.type || "Retail"}
                    </th>
                    <th>
                      {areas?.find(
                        (item: any) =>
                          item.omc_id?.toString() ==
                          currentCampaign?.omc_id?.toString()
                      )?.type || "Area"}
                    </th>
                    <th>RO Name</th>
                    {fieldds.bill_amount && <th>Bill Amount</th>}
                    {fieldds.customer_attendant_name && (
                      <th>Customer Attendant Name</th>
                    )}
                    {fieldds.customer_attendant_code && (
                      <th>Customer Attendant Code</th>
                    )}
                    {fieldds.field_officer_name && <th>Field Officer Name</th>}
                    {fieldds.field_officer_code && <th>Field Officer Code</th>}
                    {fieldds.sloganField && <th>Slogan</th>}
                    <th>Created Date</th>
                    <th>Created Time</th>
                  </tr>
                </thead>
                <tbody className="text-gray-600 fw-bold">
                  {campaign.length > 0 ? (
                    campaign.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          {fieldds.nameField && <td>{item.name}</td>}
                          <td>{item.entry_type}</td>
                          {fieldds.emailField ||
                            (fieldds.mobileField && (
                              <td className="py-1">
                                <div className="d-flex align-items-center">
                                  <div className="d-flex flex-column">
                                    <span>{item.email}</span>
                                    <small>{item.mobile}</small>
                                  </div>
                                </div>
                              </td>
                            ))}
                          {fieldds.vnumberField ||
                            (fieldds.vtypeField && (
                              <td className="py-1">
                                <div className="d-flex align-items-center">
                                  <div className="d-flex flex-column">
                                    <small>{item.vehicle_type_name}</small>
                                    <span>{item.vehicle_number}</span>
                                  </div>
                                </div>
                              </td>
                            ))}
                          {fieldds.ftypeField && <td>{item.fuel_type_name}</td>}
                          <td>{item.state_name}</td>
                          <td>{item.division_name}</td>
                          <td>{item.area_name}</td>
                          <td className="py-1">
                            <div className="d-flex align-items-center">
                              <div className="d-flex flex-column">
                                <span>{item.ro_name}</span>
                                <small># {item.sap_code}</small>
                              </div>
                            </div>
                          </td>
                          {fieldds.bill_amount && <td>{item.bill_amount}</td>}
                          {/* <td>{item.bill_image}</td> */}
                          {fieldds.customer_attendant_name && (
                            <td>{item.customer_attendant_name}</td>
                          )}
                          {fieldds.customer_attendant_code && (
                            <td>{item.customer_attendant_code}</td>
                          )}
                          {fieldds.field_officer_name && (
                            <td>{item.field_officer_name}</td>
                          )}
                          {fieldds.field_officer_code && (
                            <td>{item.field_officer_code}</td>
                          )}
                          {fieldds.sloganField && <td>{item.slogan}</td>}
                          <td className="py-1">
                            <div className="d-flex align-items-center">
                              <div className="d-flex flex-column">
                                <span>
                                  {moment(item.created_date).format(
                                    "DD-MM-YYYY"
                                  )}
                                </span>
                                <small>
                                  {moment(item.created_date).format("hh:mm a")}
                                </small>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={11}>
                        <div className="d-flex text-center w-100 align-content-center justify-content-center">
                          No matching records found
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RegistrationListReport;
