import react, { useState, useEffect, useRef } from "react";
import { KTIcon, toAbsoluteUrl } from "../../../_metronic/helpers";
import {
  deleteStates,
  getStates,
  saveStates,
  updateStates,
} from "../BussinesSettings/core/_requests";
import { toast } from "react-toastify";
import { PageTitle } from "../../../_metronic/layout/core/PageData";
import { Paginator } from "primereact/paginator";
import { getReports } from "./core/_requests";
import moment from "moment";
import { getCampaign, getCampaignById } from "../campaign/core/_requests";

const CampaignReport = () => {
  const [states, setStates] = useState<any[]>([]);
  const [pageData, setPageData] = useState<any[]>([]);
  const [statesForSearch, setStatesForSearch] = useState<any[]>([]);
  const [dates, setDates] = useState<any[]>([]);
  const [datesList, setDatesList] = useState<any[]>([]);
  const [campaign, setCampaign] = useState<any[]>([]);
  const [colTotal, setColTotal] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const targetRefReport = useRef<any>();
  const queryParams = new URLSearchParams(window.location.search);
  const campaignId = queryParams.get("cid");

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const CampaignList = async () => {
    const response = await getCampaign();
    setCampaign(response.output);
  };

  const statesList = async () => {
    setLoading(true);
    // const response = await getReports(fromDate, toDate, selectedCampaign, areaId, divId, stateId, fTId, vTId, roId);
    // setStates(response.output);
    // setStatesForSearch(response.output);
    setLoading(false);
  };

  function tableToCSV() {
    var csv_data: any = [];

    var rows = document.getElementsByClassName("trow");
    for (var i = 0; i < rows.length; i++) {
      var cols: any = rows[i]?.querySelectorAll("td,th");

      var csvrow: any = [];
      for (var j = 0; j < cols.length; j++) {
        csvrow?.push(cols[j]?.innerHTML);
      }

      csv_data?.push(csvrow?.join(","));
    }

    csv_data = csv_data?.join("\n");

    downloadCSVFile(csv_data);
  }

  function downloadCSVFile(csv_data) {
    let CSVFile = new Blob([csv_data], {
      type: "text/csv",
    });

    var temp_link = document.createElement("a");

    temp_link.download = `test.csv`;
    var url = window.URL.createObjectURL(CSVFile);
    temp_link.href = url;

    temp_link.style.display = "none";
    document.body.appendChild(temp_link);

    temp_link.click();
    document.body.removeChild(temp_link);
  }

  // useEffect(() => {
  //     if(search.length > 0) {
  //         const states = statesForSearch.filter((p) => p.name?.toLowerCase().includes(search));
  //         console.log("foghiuehtoeirhtpeoirth", states);
  //         setStates(states);
  //     } else {
  //         setStates(statesForSearch);
  //     }
  // }, [search]);

  useEffect(() => {
    CampaignList();
  }, []);

  useEffect(() => {
    if (fromDate && toDate && selectedCampaign) {
      statesList();
    }
  }, [fromDate, toDate, selectedCampaign]);

  useEffect(() => {
    let page = states?.slice(first, first + rows);
    setPageData(page);
  }, [first, rows, states]);

  useEffect(() => {
    if (toDate && fromDate) {
      const dates: any = [];
      const datesList: any = [];
      let currentDate = moment(fromDate);

      while (currentDate.isSameOrBefore(toDate)) {
        dates.push(currentDate.format("DD-MM-YYYY"));
        datesList.push(currentDate.format("YYYY-MM-DD"));
        currentDate.add(1, "days");
      }

      setDates(dates);
      setDatesList(datesList);
    }
  }, [fromDate, toDate]);

  // console.log("fkhweiurweiourh", datesList);

  console.log(
    "fkhweiurweiourh",
    datesList?.map((date: any) => {
      let sdkfhfh: any = [];
      sdkfhfh.push(
        pageData
          ?.map((item: any) => item[date] || 0)
          ?.reduce((accumulator, currentValue) => {
            return accumulator + currentValue;
          }, 0)
      );
      return sdkfhfh;
    })
  );

  useEffect(() => {
    if (datesList.length > 0 && pageData.length > 0) {
      let gtuegtug: any = [];
      let hfgj = datesList?.map((date: any) => {
        let sdkfhfh: any = pageData
          ?.map((item: any) => item[date] || 0)
          ?.reduce((accumulator, currentValue) => {
            return accumulator + currentValue;
          }, 0);
        // return {[date]: sdkfhfh};
        // console.log("eljriuhroiuwegrogweir", {[date]: sdkfhfh});
        gtuegtug.push({ [date]: sdkfhfh });
      });

      console.log("kfhwieuroiurgoiwueroiuwer", gtuegtug);
      setColTotal(gtuegtug);
    }
  }, [datesList, pageData]);

  return (
    <>
      <PageTitle>Reports</PageTitle>
      {/* <div className='modal fade' id='atom_state_add_modal' aria-hidden='true'>
      <div className='modal-dialog mw-550px'>
        <div className='modal-content'>
          <div className='modal-header border-0 pb-0 justify-content-between align-items-center'>
            <div className='text-center'>
              <h3 className='mb-3'>Add State</h3>
            </div>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal' id='atom_state_add_modal_close'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>
          <div className='modal-body scroll-y pb-10 pt-5'>
            <form onSubmit={handleSubmit}>
                <input name='state_name' id="state_name"
                className='form-control form-control-solid mb-8'
                placeholder='Enter State Name..'
                />
                <span className='d-flex justify-content-end'>
                    <button type='button' className='btn btn-sm btn-secondary me-3' data-bs-dismiss='modal'>Cancel</button>
                    <button type='submit' className='btn btn-sm btn-primary'>Save</button>
                </span>
            </form>
          </div>
        </div>
      </div>
    </div> */}
      {/* <div className='modal fade' id='atom_state_delete_modal' aria-hidden='true'>
      <div className='modal-dialog mw-550px'>
        <div className='modal-content'>
          <div className='modal-header border-0 pb-0 justify-content-between align-items-center'>
            <div className='text-center'>
              <h3 className='mb-3'>Confirmation</h3>
            </div>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal' id='atom_state_delete_modal_close'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>
          <div className='modal-body scroll-y pb-10'>
            <p>Are you sure want to delete?</p>
            <span className='d-flex justify-content-end'>
                <button type='button' className='btn btn-sm btn-secondary me-3' data-bs-dismiss='modal'>No</button>
                <button type='submit' className='btn btn-sm btn-primary' onClick={() => handleDelete()}>Yes</button>
            </span>
          </div>
        </div>
      </div>
    </div> */}
      {loading ? (
        <div className="w-100 h-100">
          <div className="d-flex justify-content-center flex-column align-items-center h-100">
            <div className="spinner-border taskloader" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="card">
          <div className="card-header d-flex align-items-center">
            <h3>Reports</h3>
            <span className="d-flex">
              <input
                type="date"
                max={moment().format("YYYY-MM-DD")}
                className="form-control form-control-sm mw-200px me-3"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
              {fromDate && (
                <input
                  type="date"
                  min={fromDate}
                  max={moment().format("YYYY-MM-DD")}
                  className="form-control form-control-sm mw-200px me-3"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              )}
              {fromDate && toDate && (
                <select
                  className="form-select form-select-sm mw-200px me-3"
                  value={selectedCampaign}
                  onChange={(e) => {
                    setSelectedCampaign(e.target.value);
                  }}
                >
                  <option value="">select campaign</option>
                  {campaign.map((item, i) => {
                    return <option value={item.id}>{item.name}</option>;
                  })}
                </select>
              )}
              {pageData.length > 0 && (
                <button
                  type="button"
                  className="btn btn-sm btn-secondary btn-active-color-primary btn-icon px-3"
                  id="dropdownMenuReport"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="bi bi-cloud-download"></i>
                </button>
              )}
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuReport"
              >
                {/* <button className="dropdown-item" type="button" onClick={() => toPDF()}>PDF</button> */}
                <button
                  className="dropdown-item"
                  type="button"
                  onClick={tableToCSV}
                >
                  CSV
                </button>
              </div>
            </span>
          </div>
          <div className="card-body pt-0">
            <div className="overflow-auto">
              <table
                ref={targetRefReport}
                className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
              >
                <thead>
                  <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0 trow">
                    <th>Sl.No</th>
                    <th style={{ minWidth: "200px" }}>
                      {pageData.length > 0 ? pageData[0]?.type : "RO Code"}
                    </th>
                    <th style={{ minWidth: "150px" }}>RO Name</th>
                    <th style={{ minWidth: "150px" }}>Area</th>
                    {dates.map((date: any) => {
                      return <th style={{ minWidth: "100px" }}>{date}</th>;
                    })}
                    <th style={{ minWidth: "150px" }}>Total</th>
                  </tr>
                </thead>
                <tbody className="text-gray-600 fw-bold">
                  {states.length > 0 ? (
                    <>
                      {pageData.map((item, i) => {
                        return (
                          <tr key={i} className="trow">
                            <td>{i + 1 + first}</td>
                            <td>{item.sap_code?.replace(/,/g, "")}</td>
                            <td>{item.ro_name?.replace(/,/g, "")}</td>
                            <td>{item.area_office_name?.replace(/,/g, "")}</td>
                            {datesList.map((date: any) => {
                              return (
                                <td style={{ minWidth: "100px" }}>
                                  {item[date] || 0}
                                </td>
                              );
                            })}
                            <td>
                              {datesList
                                .map((date: any) => parseInt(item[date] || 0))
                                ?.reduce((accumulator, currentValue) => {
                                  return accumulator + currentValue;
                                }, 0)}
                            </td>
                          </tr>
                        );
                      })}
                      <tr className="trow">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Total</td>
                        {colTotal?.map((obj: any, j: any) => {
                          return <td key={j}>{obj[Object.keys(obj)[0]]}</td>;
                        })}
                        <td>
                          {colTotal
                            ?.map(
                              (obj: any, j: any) => obj[Object.keys(obj)[0]]
                            )
                            ?.reduce((accumulator, currentValue) => {
                              return accumulator + currentValue;
                            }, 0)}
                        </td>
                      </tr>
                    </>
                  ) : (
                    <tr>
                      <td colSpan={11}>
                        <div className="d-flex text-center w-100 align-content-center justify-content-center">
                          Select date range and campaign to generate report
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="table_paginator mt-3 d-flex justify-content-end">
              <Paginator
                first={first}
                rows={rows}
                totalRecords={states.length}
                rowsPerPageOptions={[10, 50, 100, 500, 1000]}
                onPageChange={onPageChange}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CampaignReport;
