import react, { useState, useEffect, useRef } from "react";
import { getCampaignById, getCampaignRegList } from "./core/_requests";
import { PageTitle } from "../../../_metronic/layout/core/PageData";
import { Paginator } from "primereact/paginator";
import moment from "moment";
import {
  getAreas,
  getDivisions,
  getMaster,
  getStates,
} from "../BussinesSettings/core/_requests";
import { getRo } from "../RO/core/_requests";
import { useReactToPrint } from "react-to-print";
import jsPDF from "jspdf";
import { Margin, usePDF } from "react-to-pdf";
import {
  getAreaOffice,
  getDivisionOffice,
  getStateOffice,
} from "../OMC/core/_request";

const RegistrationList = () => {
  const [campaign, setCampaign] = useState<any[]>([]);
  const [pageData, setPageData] = useState<any[]>([]);
  const [CampaignForSearch, setCampaignForSearch] = useState<any[]>([]);
  const [states, setStates] = useState<any[]>([]);
  const [divisions, setDivisions] = useState<any[]>([]);
  const [fuelType, setFuelType] = useState<any[]>([]);
  const [vehicleType, setVehicleType] = useState<any[]>([]);
  const [areas, setAreas] = useState<any[]>([]);
  const [ro, setRo] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentCampaign, setCurrentCampaign] = useState<any>({});
  const [search, setSearch] = useState("");
  const [stateId, setstateId] = useState("");
  const [roId, setRoId] = useState("");
  const [divId, setDivId] = useState("");
  const [areaId, setAreaId] = useState("");
  const [vTId, setVTId] = useState("");
  const [fTId, setFTId] = useState("");
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [fieldds, setFieldds] = useState<any>({});
  const queryParams = new URLSearchParams(window.location.search);
  const campaignId = queryParams.get("cid");

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const campaignList = async () => {
    setLoading(true);
    const response = await getCampaignRegList(
      campaignId,
      stateId,
      roId,
      divId,
      areaId,
      vTId,
      fTId
    );
    setCampaign(response.output);
    setCampaignForSearch(response.output);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const currentCampaigns = async () => {
    const response = await getCampaignById(campaignId);
    setCurrentCampaign(response.output[0]);
    if (response.output[0]?.forms) {
      let hiugrte = JSON.parse(response.output[0]?.forms);
      setFieldds(hiugrte);
    }
  };

  const statesList = async () => {
    const response = await getStateOffice();
    setStates(
      response.output?.filter(
        (item: any) =>
          currentCampaign?.state?.split(",").indexOf(item.id?.toString()) !== -1
      )
    );
  };

  const divisionList = async () => {
    const response = await getDivisionOffice();
    setDivisions(
      response.output?.filter(
        (item: any) =>
          currentCampaign?.division?.split(",").indexOf(item.id?.toString()) !==
          -1
      )
    );
  };

  const areaList = async () => {
    const response = await getAreaOffice();
    setAreas(
      response.output?.filter(
        (item: any) =>
          currentCampaign?.area?.split(",").indexOf(item.id?.toString()) !== -1
      )
    );
  };

  const RoList = async () => {
    const response = await getRo();
    setRo(response.output);
  };

  const fuelTypeList = async () => {
    const response = await getMaster("fuel_type");
    setFuelType(
      response.output?.filter(
        (item: any) =>
          currentCampaign?.fuel_type
            ?.split(",")
            .indexOf(item.id?.toString()) !== -1
      )
    );
  };

  const vehicleTypeList = async () => {
    const response = await getMaster("vehicle_type");
    setVehicleType(
      response.output?.filter(
        (item: any) =>
          currentCampaign?.vehicle_type
            ?.split(",")
            .indexOf(item.id?.toString()) !== -1
      )
    );
  };

  useEffect(() => {
    if (search.length > 0) {
      const campaign = CampaignForSearch.filter(
        (p) =>
          p.name?.toLowerCase().includes(search) ||
          p.email?.toLowerCase().includes(search) ||
          p.mobile?.toString().toLowerCase().includes(search) ||
          p.vehicle_number?.toLowerCase().includes(search) ||
          p.fuel_type_name?.toLowerCase().includes(search) ||
          p.vehicle_type_name?.toLowerCase().includes(search) ||
          p.state_name?.toLowerCase().includes(search) ||
          p.division_name?.toLowerCase().includes(search) ||
          p.area_name?.toLowerCase().includes(search) ||
          p.ro_name?.toLowerCase().includes(search) ||
          p.sap_code?.toLowerCase().includes(search) ||
          p.customer_attendant_name?.toLowerCase().includes(search) ||
          p.customer_attendant_code?.toLowerCase().includes(search) ||
          p.field_officer_name?.toLowerCase().includes(search) ||
          p.field_officer_code?.toLowerCase().includes(search)
      );
      setCampaign(campaign);
    } else {
      setCampaign(CampaignForSearch);
    }
  }, [search]);

  useEffect(() => {
    if (currentCampaign?.id) {
      campaignList();
    }
    // if(stateId || roId || divId || areaId || vTId || fTId) {
    // }
    // if(stateId || roId || divId || areaId || vTId || fTId) {
    //     // const rtertert = campaign.filter((p) => p.ro_state_id?.toString() == stateId && p.ro_id?.toString() == roId && p.ro_division_id?.toString() == divId && p.vehicle_type?.toString() == vTId && p.fuel_type?.toString() == fTId && p.ro_area_id?.toString() == areaId);
    //     // setCampaign(rtertert);
    //     if(fTId) {
    //         let rtertert = campaign.filter((p) => p.fuel_type?.toString() == fTId);
    //         setCampaign(rtertert);
    //     }
    //     if(vTId) {
    //         let rtertert = campaign.filter((p) => p.vehicle_type?.toString() == vTId);
    //         setCampaign(rtertert);
    //     }
    //     if(areaId) {
    //         let rtertert = campaign.filter((p) => p.ro_area_id?.toString() == areaId);
    //         setCampaign(rtertert);
    //     }
    //     if(divId) {
    //         let rtertert = campaign.filter((p) => p.ro_division_id?.toString() == divId);
    //         setCampaign(rtertert);
    //     }
    //     if(roId) {
    //         let rtertert = campaign.filter((p) => p.ro_id?.toString() == roId);
    //         setCampaign(rtertert);
    //     }
    //     if(stateId) {
    //         let rtertert = campaign.filter((p) => p.ro_state_id?.toString() == stateId);
    //         setCampaign(rtertert);
    //     }
    // } else {
    //     setCampaign(CampaignForSearch);
    // }
  }, [stateId, roId, divId, areaId, vTId, fTId, currentCampaign?.id]);

  useEffect(() => {
    campaignList();
    currentCampaigns();
    RoList();
  }, []);

  useEffect(() => {
    if (currentCampaign?.id) {
      areaList();
      divisionList();
      statesList();
      fuelTypeList();
      vehicleTypeList();
    }
  }, [currentCampaign?.id]);

  useEffect(() => {
    let page = campaign?.slice(first, first + rows);
    setPageData(page);
  }, [first, rows, campaign]);

  const lrjiuetgiert: any = useRef();
  const lrjiuetgiertwerheowiur: any = useRef();

  const handlePrint = useReactToPrint({
    content: () => lrjiuetgiert.current,
  });

  const handleGeneratePdf = async () => {
    // const doc = new jsPDF();
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: [600, 870],
    });

    doc.html(lrjiuetgiertwerheowiur.current, {
      // html2canvas: { scale: 0.8 },
      async callback(doc) {
        await doc.save("RegList");
      },
    });
  };

  function tableToCSV() {
    var csv_data: any = [];

    var rows = document.getElementsByClassName("trow");
    for (var i = 0; i < rows.length; i++) {
      var cols: any = rows[i]?.querySelectorAll("td,th");

      var csvrow: any = [];
      for (var j = 0; j < cols.length; j++) {
        csvrow?.push(cols[j]?.innerHTML);
      }

      csv_data?.push(csvrow?.join(","));
    }

    csv_data = csv_data?.join("\n");

    downloadCSVFile(csv_data);
  }

  function downloadCSVFile(csv_data) {
    let CSVFile = new Blob([csv_data], {
      type: "text/csv",
    });

    var temp_link = document.createElement("a");

    temp_link.download = `${currentCampaign.name}.csv`;
    var url = window.URL.createObjectURL(CSVFile);
    temp_link.href = url;

    temp_link.style.display = "none";
    document.body.appendChild(temp_link);

    temp_link.click();
    document.body.removeChild(temp_link);
  }

  const { toPDF, targetRef } = usePDF({
    filename: `${currentCampaign.name}.pdf`,
    page: { orientation: "landscape", margin: Margin.MEDIUM },
  });

  return (
    <>
      <PageTitle>Manage Campaign</PageTitle>
      {loading ? (
        <div className="w-100 h-100">
          <div className="d-flex justify-content-center flex-column align-items-center h-100">
            <div className="spinner-border taskloader" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="card">
          <div className="card-header d-flex align-items-center">
            <div className="d-flex align-items-center">
              <div className="prof_img_pre_list border me-3">
                {currentCampaign.omc_logo ? (
                  <img
                    src={
                      process.env.REACT_APP_API_URL +
                      "/uploads/company/logo/" +
                      currentCampaign.omc_id +
                      "/" +
                      currentCampaign.omc_logo
                    }
                    className="profile_image w-100"
                  />
                ) : (
                  <p className="h-100 d-flex justify-content-center align-items-center bg-gray-300">
                    LOGO
                  </p>
                )}
              </div>
              <div className="d-flex flex-column">
                <h3>{currentCampaign.name}</h3>
                <small>
                  {moment(currentCampaign.start_date).format("DD/MM/YYYY")} -{" "}
                  {moment(currentCampaign.end_date).format("DD/MM/YYYY")}
                </small>
              </div>
            </div>
            <span className="d-flex">
              <span className="position-relative me-3">
                <input
                  onChange={(e) => setSearch(e.target.value?.toLowerCase())}
                  className="form-control form-control-sm form-control-solid mw-200px"
                  placeholder="Search.."
                  id="rethrtoiehrotiuerotiugeti"
                />
                <i className="bi bi-search fs-3 me-3 position-absolute top-25 end-0"></i>
              </span>
              {(search ||
                stateId ||
                roId ||
                areaId ||
                divId ||
                vTId ||
                fTId) && (
                <button
                  className="btn btn-sm btn-secondary btn-active-color-info btn-icon me-2"
                  data-bs-toggle="modal"
                  data-bs-target="#atom_Campaign_poster_modal"
                  onClick={() => {
                    // setCampaign(CampaignForSearch);
                    setSearch("");
                    setstateId("");
                    setRoId("");
                    setAreaId("");
                    setDivId("");
                    setVTId("");
                    setFTId("");
                    (
                      document.getElementById(
                        "rethrtoiehrotiuerotiugeti"
                      ) as HTMLInputElement
                    ).value = "";
                    (
                      document.getElementById(
                        "wejhrweryifgrgerjwerjb"
                      ) as HTMLInputElement
                    ).value = "";
                    (
                      document.getElementById(
                        "erjwgerugwuerywerfytfwe"
                      ) as HTMLInputElement
                    ).value = "";
                    (
                      document.getElementById(
                        "erwuehrugweorbwuerfiuyf"
                      ) as HTMLInputElement
                    ).value = "";
                    (
                      document.getElementById(
                        "werhwegruwgeruweriuyiyrvgv"
                      ) as HTMLInputElement
                    ).value = "";
                    (
                      document.getElementById(
                        "wernwegryuegruv4i37giuh4brhb"
                      ) as HTMLInputElement
                    ).value = "";
                    (
                      document.getElementById(
                        "werkwgertrgehwhrvhwgefrfuwiervjh"
                      ) as HTMLInputElement
                    ).value = "";
                  }}
                >
                  <i className="bi bi-arrow-clockwise"></i>
                </button>
              )}
              {pageData.length > 0 && (
                <button
                  className="btn btn-sm btn-secondary btn-active-color-info btn-icon me-2"
                  onClick={handlePrint}
                >
                  <i className="bi bi-printer"></i>
                </button>
              )}
              {pageData.length > 0 && (
                <div
                  className="btn btn-sm btn-secondary btn-active-color-primary btn-icon me-2"
                  id="dropdownMenu2"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="bi bi-cloud-download"></i>
                </div>
              )}
              <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                <button
                  className="dropdown-item"
                  type="button"
                  onClick={() => toPDF()}
                >
                  PDF
                </button>
                <button
                  className="dropdown-item"
                  type="button"
                  onClick={tableToCSV}
                >
                  CSV
                </button>
              </div>
            </span>
          </div>
          <div className="card-body pt-0">
            <div className="overflow-x-auto">
              <table
                ref={targetRef}
                className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
              >
                <thead>
                  <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                    <th>Sl.No</th>
                    {fieldds.nameField && (
                      <th style={{ minWidth: "200px" }}>Name</th>
                    )}
                    {fieldds.emailField ||
                      (fieldds.mobileField && (
                        <th style={{ minWidth: "200px" }}>Email & Phone</th>
                      ))}
                    {fieldds.vnumberField && (
                      <th style={{ minWidth: "200px" }}>Vehicle Number</th>
                    )}
                    {fieldds.ftypeField && (
                      <th style={{ minWidth: "150px" }} className="p-0">
                        <div className="d-flex align-items-center">
                          <select
                            className="form-select border-0 text-muted fw-bolder fs-7 text-uppercase gs-0 mb-2"
                            id="wejhrweryifgrgerjwerjb"
                            value={fTId}
                            onChange={(e) => setFTId(e.target.value)}
                          >
                            <option value="">Fuel Type</option>
                            {fuelType.map((item, i) => {
                              return (
                                <option value={item.id} key={i}>
                                  {item.option_value}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </th>
                    )}
                    {fieldds.vtypeField && (
                      <th style={{ minWidth: "150px" }} className="p-0">
                        <div className="d-flex align-items-center">
                          <select
                            className="form-select border-0 text-muted fw-bolder fs-7 text-uppercase gs-0 mb-2"
                            id="erjwgerugwuerywerfytfwe"
                            value={vTId}
                            onChange={(e) => setVTId(e.target.value)}
                          >
                            <option value="">Vehicle Type</option>
                            {vehicleType.map((item, i) => {
                              return (
                                <option value={item.id} key={i}>
                                  {item.option_value}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </th>
                    )}
                    <th style={{ minWidth: "150px" }} className="p-0">
                      <div className="d-flex align-items-center">
                        <select
                          className="form-select border-0 text-muted fw-bolder fs-7 text-uppercase gs-0 mb-2"
                          id="erwuehrugweorbwuerfiuyf"
                          value={stateId}
                          onChange={(e) => {
                            setstateId(e.target.value);
                            setDivId("");
                            setAreaId("");
                          }}
                        >
                          <option value="">State</option>
                          {states.map((item, i) => {
                            return (
                              <option value={item.id} key={i}>
                                {item.state}
                              </option>
                            );
                          })}
                        </select>
                        {/* State */}
                      </div>
                    </th>
                    <th style={{ minWidth: "150px" }} className="p-0">
                      <div className="d-flex align-items-center">
                        <select
                          className="form-select border-0 text-muted fw-bolder fs-7 text-uppercase gs-0 mb-2"
                          id="werhwegruwgeruweriuyiyrvgv"
                          value={divId}
                          onChange={(e) => {
                            setDivId(e.target.value);
                            setAreaId("");
                          }}
                        >
                          <option value="">
                            {divisions?.find(
                              (item: any) =>
                                item.omc_id?.toString() ==
                                currentCampaign?.omc_id?.toString()
                            )?.type || "Retail"}
                          </option>
                          {divisions.map((item, i) => {
                            if (stateId) {
                              if (stateId == item.state?.toString()) {
                                return (
                                  <option value={item.id} key={i}>
                                    {item.division}
                                  </option>
                                );
                              }
                            } else {
                              return (
                                <option value={item.id} key={i}>
                                  {item.division}
                                </option>
                              );
                            }
                          })}
                        </select>
                        {/* Retail */}
                      </div>
                    </th>
                    <th style={{ minWidth: "150px" }} className="p-0">
                      <div className="d-flex align-items-center">
                        <select
                          className="form-select border-0 text-muted fw-bolder fs-7 text-uppercase gs-0 mb-2"
                          id="wernwegryuegruv4i37giuh4brhb"
                          value={areaId}
                          onChange={(e) => {
                            setAreaId(e.target.value);
                          }}
                        >
                          <option value="">
                            {areas?.find(
                              (item: any) =>
                                item.omc_id?.toString() ==
                                currentCampaign?.omc_id?.toString()
                            )?.type || "Area"}
                          </option>
                          {areas.map((item, i) => {
                            if (stateId && divId) {
                              if (divId == item.division?.toString()) {
                                return (
                                  <option value={item.id} key={i}>
                                    {item.city}
                                  </option>
                                );
                              }
                            } else if (!stateId && divId) {
                              if (divId == item.division?.toString()) {
                                return (
                                  <option value={item.id} key={i}>
                                    {item.city}
                                  </option>
                                );
                              }
                            } else if (stateId && !divId) {
                              if (stateId == item.state?.toString()) {
                                return (
                                  <option value={item.id} key={i}>
                                    {item.city}
                                  </option>
                                );
                              }
                            } else {
                              return (
                                <option value={item.id} key={i}>
                                  {item.city}
                                </option>
                              );
                            }
                          })}
                        </select>
                        {/* Area */}
                      </div>
                    </th>
                    <th style={{ minWidth: "150px" }} className="">
                      <div className="d-flex align-items-center">
                        {/* <select className='form-select border-0 text-muted fw-bolder fs-7 text-uppercase gs-0 mb-2' id='werkwgertrgehwhrvhwgefrfuwiervjh' onChange={(e) => setRoId(e.target.value)}>
                                        <option value="" >{currentCampaign?.ro_name_translation || 'RO Name'}</option>
                                        {ro.map((item, i) => {
                                            return(
                                                <option value={item.id} key={i} >{item.ro_name}</option>
                                            )
                                        })}
                                    </select> */}
                        RO Name
                      </div>
                    </th>
                    {fieldds.bill_amount && (
                      <th style={{ minWidth: "200px" }}>Bill Amount</th>
                    )}
                    {fieldds.invoiceField && (
                      <th style={{ minWidth: "200px" }}>Bill Image</th>
                    )}
                    {fieldds.invoiceField && (
                      <th style={{ minWidth: "200px" }}>Selfie Image</th>
                    )}
                    {fieldds.customer_attendant_name && (
                      <th style={{ minWidth: "200px" }}>
                        Customer Attendant Name
                      </th>
                    )}
                    {fieldds.customer_attendant_code && (
                      <th style={{ minWidth: "200px" }}>
                        Customer Attendant Code
                      </th>
                    )}
                    {fieldds.field_officer_name && (
                      <th style={{ minWidth: "200px" }}>Field Officer Name</th>
                    )}
                    {fieldds.field_officer_code && (
                      <th style={{ minWidth: "200px" }}>Field Officer Code</th>
                    )}
                    {fieldds.sloganField && (
                      <th style={{ minWidth: "200px" }}>Slogan</th>
                    )}
                    <th className="text-center" style={{ minWidth: "200px" }}>
                      Created At
                    </th>
                  </tr>
                </thead>
                <tbody className="text-gray-600 fw-bold">
                  {campaign.length > 0 ? (
                    pageData.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1 + first}</td>
                          {fieldds.nameField && <td>{item.name}</td>}
                          {fieldds.emailField ||
                            (fieldds.mobileField && (
                              <td className="py-1">
                                <div className="d-flex align-items-center">
                                  <div className="d-flex flex-column">
                                    <span>{item.email}</span>
                                    <small>{item.mobile}</small>
                                  </div>
                                </div>
                              </td>
                            ))}
                          {fieldds.vnumberField && (
                            <td>{item.vehicle_number}</td>
                          )}
                          {fieldds.ftypeField && <td>{item.fuel_type_name}</td>}
                          {fieldds.vtypeField && (
                            <td>{item.vehicle_type_name}</td>
                          )}
                          <td>{item.state_name}</td>
                          <td>{item.division_name}</td>
                          <td>{item.area_name}</td>
                          <td className="py-1">
                            <div className="d-flex align-items-center">
                              <div className="d-flex flex-column">
                                <span>{item.ro_name}</span>
                                <small># {item.sap_code}</small>
                              </div>
                            </div>
                          </td>
                          {fieldds.bill_amount && <td>{item.bill_amount}</td>}
                          {fieldds.invoiceField && (
                            <td className="py-1">
                              <div className="">
                                {item.bill_image ? (
                                  <a
                                    href={
                                      process.env.REACT_APP_API_URL +
                                      "/uploads/campaign/registration/bill_image/" +
                                      item.id +
                                      "/" +
                                      item.bill_image
                                    }
                                    className="prof_img_pre_list border d-flex align-items-center justify-content-center"
                                  >
                                    <img
                                      src={
                                        process.env.REACT_APP_API_URL +
                                        "/uploads/campaign/registration/bill_image/" +
                                        item.id +
                                        "/" +
                                        item.bill_image
                                      }
                                      className="profile_image w-100"
                                    />
                                  </a>
                                ) : (
                                  <span className="prof_img_pre_list border d-flex align-items-center justify-content-center">
                                    <p className="h-100 w-100 d-flex justify-content-center align-items-center bg-gray-300 m-0 text-light">
                                      Bill
                                    </p>
                                  </span>
                                )}
                              </div>
                            </td>
                          )}
                          {fieldds.invoiceField && (
                            <td className="py-1">
                              <div className="">
                                {item.selfi_image ? (
                                  <a
                                    href={
                                      process.env.REACT_APP_API_URL +
                                      "/uploads/campaign/registration/selfi_image/" +
                                      item.id +
                                      "/" +
                                      item.selfi_image
                                    }
                                    className="prof_img_pre_list border d-flex align-items-center justify-content-center"
                                  >
                                    <img
                                      src={
                                        process.env.REACT_APP_API_URL +
                                        "/uploads/campaign/registration/selfi_image/" +
                                        item.id +
                                        "/" +
                                        item.selfi_image
                                      }
                                      className="profile_image w-100"
                                    />
                                  </a>
                                ) : (
                                  <span className="prof_img_pre_list border d-flex align-items-center justify-content-center">
                                    <p className="h-100 w-100 d-flex justify-content-center align-items-center bg-gray-300 m-0 text-light">
                                      Selfie
                                    </p>
                                  </span>
                                )}
                              </div>
                            </td>
                          )}
                          {fieldds.customer_attendant_name && (
                            <td>{item.customer_attendant_name}</td>
                          )}
                          {fieldds.customer_attendant_code && (
                            <td>{item.customer_attendant_code}</td>
                          )}
                          {fieldds.field_officer_name && (
                            <td>{item.field_officer_name}</td>
                          )}
                          {fieldds.field_officer_code && (
                            <td>{item.field_officer_code}</td>
                          )}
                          {fieldds.sloganField && <td>{item.slogan}</td>}
                          <td className="py-1">
                            <div className="d-flex align-items-center justify-content-center">
                              <div className="d-flex flex-column text-center">
                                <span>
                                  {moment(item.created_date).format(
                                    "DD-MM-YYYY"
                                  )}
                                </span>
                                <small>
                                  {moment(item.created_date).format("hh:mm a")}
                                </small>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={11}>
                        <div className="d-flex text-center w-100 align-content-center justify-content-center">
                          No matching records found
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="table_paginator mt-3 d-flex justify-content-end">
              <Paginator
                first={first}
                rows={rows}
                totalRecords={campaign.length}
                rowsPerPageOptions={[10, 50, 100, 500, 1000]}
                onPageChange={onPageChange}
              />
            </div>
          </div>
          <div className="d-none">
            <div className="p-9 pdf_width">
              <table className="table table-bordered align-middle table-row-dashed fs-9 gy-5 dataTable no-footer">
                <thead>
                  <tr className="text-start text-muted fw-bolder fs-8 text-uppercase gs-0 trow">
                    <th>Sl.No</th>
                    {fieldds.nameField && <th>Name</th>}
                    {fieldds.emailField && <th>Email</th>}
                    {fieldds.mobileField && <th>Phone</th>}
                    {fieldds.vtypeField && <th>Vehicle Type</th>}
                    {fieldds.vnumberField && <th>Vehicle Number</th>}
                    {fieldds.ftypeField && <th>Fuel Type</th>}
                    <th>State</th>
                    <th>
                      {divisions?.find(
                        (item: any) =>
                          item.omc_id?.toString() ==
                          currentCampaign?.omc_id?.toString()
                      )?.type || "Retail"}
                    </th>
                    <th>
                      {areas?.find(
                        (item: any) =>
                          item.omc_id?.toString() ==
                          currentCampaign?.omc_id?.toString()
                      )?.type || "Area"}
                    </th>
                    <th>RO Name</th>
                    <th>
                      {ro?.find(
                        (item: any) =>
                          item.omc_id?.toString() ==
                          currentCampaign?.omc_id?.toString()
                      )?.type || "RO Code"}
                    </th>
                    {fieldds.bill_amount && <th>Bill Amount</th>}
                    {/* <th>Bill Image</th> */}
                    {fieldds.customer_attendant_name && (
                      <th>Customer Attendant Name</th>
                    )}
                    {fieldds.customer_attendant_code && (
                      <th>Customer Attendant Code</th>
                    )}
                    {fieldds.field_officer_name && <th>Field Officer Name</th>}
                    {fieldds.field_officer_code && <th>Field Officer Code</th>}
                    {fieldds.sloganField && <th>Slogan</th>}
                    <th>Created Date</th>
                    <th>Created Time</th>
                  </tr>
                </thead>
                <tbody className="text-gray-600 fw-bold">
                  {campaign.length > 0 ? (
                    campaign.map((item, i) => {
                      return (
                        <tr key={i} className="trow">
                          <td>{i + 1 + first}</td>
                          {fieldds.nameField && (
                            <td>{item.name?.replace(/,/g, "")}</td>
                          )}
                          {fieldds.emailField && (
                            <td>{item.email?.replace(/,/g, "")}</td>
                          )}
                          {fieldds.mobileField && (
                            <td>{item.mobile?.replace(/,/g, "")}</td>
                          )}
                          {fieldds.vtypeField && (
                            <td>{item.vehicle_type_name?.replace(/,/g, "")}</td>
                          )}
                          {fieldds.vnumberField && (
                            <td>{item.vehicle_number?.replace(/,/g, "")}</td>
                          )}
                          {fieldds.ftypeField && (
                            <td>{item.fuel_type_name?.replace(/,/g, "")}</td>
                          )}
                          <td>{item.state_name?.replace(/,/g, "")}</td>
                          <td>{item.division_name?.replace(/,/g, "")}</td>
                          <td>{item.area_name?.replace(/,/g, "")}</td>
                          <td>{item.ro_name?.replace(/,/g, "")}</td>
                          <td>{item.sap_code?.replace(/,/g, "")}</td>
                          {fieldds.bill_amount && (
                            <td>{item.bill_amount?.replace(/,/g, "")}</td>
                          )}
                          {fieldds.customer_attendant_name && (
                            <td>
                              {item.customer_attendant_name?.replace(/,/g, "")}
                            </td>
                          )}
                          {fieldds.customer_attendant_code && (
                            <td>
                              {item.customer_attendant_code?.replace(/,/g, "")}
                            </td>
                          )}
                          {fieldds.field_officer_name && (
                            <td>
                              {item.field_officer_name?.replace(/,/g, "")}
                            </td>
                          )}
                          {fieldds.field_officer_code && (
                            <td>
                              {item.field_officer_code?.replace(/,/g, "")}
                            </td>
                          )}
                          {fieldds.sloganField && (
                            <td>{item.slogan?.replace(/,/g, "")}</td>
                          )}
                          <td>
                            {moment(item.created_at).format("YYYY-MM-DD")}
                          </td>
                          <td>{moment(item.created_at).format("hh:mm a")}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={11}>
                        <div className="d-flex text-center w-100 align-content-center justify-content-center">
                          No matching records found
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="d-none">
            <div className="p-9 pdf_width" ref={lrjiuetgiert}>
              <table className="table table-bordered align-middle table-row-dashed fs-9 gy-5 dataTable no-footer">
                <thead>
                  <tr className="text-start text-muted fw-bolder fs-8 text-uppercase gs-0">
                    <th>Sl.No</th>
                    {fieldds.nameField && <th>Name</th>}
                    <th>Entry Type</th>
                    {fieldds.emailField ||
                      (fieldds.mobileField && <th>Email & Phone</th>)}
                    {fieldds.vtypeField ||
                      (fieldds.vnumberField && <th>Vehicle</th>)}
                    {fieldds.ftypeField && <th>Fuel Type</th>}
                    <th>State</th>
                    <th>
                      {divisions?.find(
                        (item: any) =>
                          item.omc_id?.toString() ==
                          currentCampaign?.omc_id?.toString()
                      )?.type || "Retail"}
                    </th>
                    <th>
                      {areas?.find(
                        (item: any) =>
                          item.omc_id?.toString() ==
                          currentCampaign?.omc_id?.toString()
                      )?.type || "Area"}
                    </th>
                    <th>RO Name</th>
                    {fieldds.bill_amount && <th>Bill Amount</th>}
                    {fieldds.customer_attendant_name && (
                      <th>Customer Attendant Name</th>
                    )}
                    {fieldds.customer_attendant_code && (
                      <th>Customer Attendant Code</th>
                    )}
                    {fieldds.field_officer_name && <th>Field Officer Name</th>}
                    {fieldds.field_officer_code && <th>Field Officer Code</th>}
                    {fieldds.sloganField && <th>Slogan</th>}
                    <th>Created_at</th>
                  </tr>
                </thead>
                <tbody className="text-gray-600 fw-bold">
                  {campaign.length > 0 ? (
                    campaign.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1 + first}</td>
                          {fieldds.nameField && <td>{item.name}</td>}
                          <td>{item.entry_type}</td>
                          {fieldds.mobileField ||
                            (fieldds.emailField && (
                              <td className="py-1">
                                <div className="d-flex align-items-center">
                                  <div className="d-flex flex-column">
                                    <span>{item.email}</span>
                                    <small>{item.mobile}</small>
                                  </div>
                                </div>
                              </td>
                            ))}
                          {fieldds.vtypeField ||
                            (fieldds.vnumberField && (
                              <td className="py-1">
                                <div className="d-flex align-items-center">
                                  <div className="d-flex flex-column">
                                    <small>{item.vehicle_type_name}</small>
                                    <span>{item.vehicle_number}</span>
                                  </div>
                                </div>
                              </td>
                            ))}
                          {fieldds.ftypeField && <td>{item.fuel_type_name}</td>}
                          <td>{item.state_name}</td>
                          <td>{item.division_name}</td>
                          <td>{item.area_name}</td>
                          <td className="py-1">
                            <div className="d-flex align-items-center">
                              <div className="d-flex flex-column">
                                <span>{item.ro_name}</span>
                                <small># {item.sap_code}</small>
                              </div>
                            </div>
                          </td>
                          {fieldds.bill_amount && <td>{item.bill_amount}</td>}
                          {/* <td>{item.bill_image}</td> */}
                          {fieldds.customer_attendant_name && (
                            <td>{item.customer_attendant_name}</td>
                          )}
                          {fieldds.customer_attendant_code && (
                            <td>{item.customer_attendant_code}</td>
                          )}
                          {fieldds.field_officer_name && (
                            <td>{item.field_officer_name}</td>
                          )}
                          {fieldds.field_officer_code && (
                            <td>{item.field_officer_code}</td>
                          )}
                          {fieldds.sloganField && <td>{item.slogan}</td>}
                          <td className="py-1">
                            <div className="d-flex align-items-center">
                              <div className="d-flex flex-column">
                                <span>
                                  {moment(item.created_at).format("DD-MM-YYYY")}
                                </span>
                                <small>
                                  {moment(item.created_at).format("hh:mm a")}
                                </small>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={11}>
                        <div className="d-flex text-center w-100 align-content-center justify-content-center">
                          No matching records found
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RegistrationList;
